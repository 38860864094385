import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { CarrierFlashCourierFtpFpl } from '../../../../models/carrier_flash_courier_ftp_fpl';
import { ApiService } from '../../../../core/services/api.service';
import { NotificationService } from '../../../../core/messages/notification.service';

@Component({
  selector: 'app-flash-courier-ftp',
  templateUrl: './flash_courier_ftp.component.html'
})

export class FlashCourierFtpComponent implements OnInit {

  carrier_flash_courier_ftp_fpl: CarrierFlashCourierFtpFpl = new CarrierFlashCourierFtpFpl();
  edit_mode: boolean = false;
  is_secure: boolean = false;
  options = [
    {
      name:'Sim',
      value: true
    },
    {
      name:'Não',
      value: false
    }
  ]


  constructor(public acRoute: ActivatedRoute, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService) { }

  ngOnInit() {
    this.appComponent.load(true);
    this.apiService.get('backoffice/carrier_flash_courier_ftp_fpl').subscribe((data: any) => {
      if(data){
        this.carrier_flash_courier_ftp_fpl = new CarrierFlashCourierFtpFpl().deserialize(data);
        if (data.is_secure) {
          this.is_secure = data.is_secure;
        }
        if (data.address) {
          this.edit_mode = true
        } else {
          this.edit_mode = false
        }

      }
      this.appComponent.load(false);
    });
  }

  onSubmit () {
    this.appComponent.load(true);
    this.carrier_flash_courier_ftp_fpl.is_secure = this.is_secure
    const params = { carrier_flash_courier_ftp_fpl: this.carrier_flash_courier_ftp_fpl }
    const url = `backoffice/carrier_flash_courier_ftp_fpl`;

    // backoffice/brands/:brand_id/delivery_posts/:manual_post_id:/add_post
    if (this.edit_mode) {
      this.apiService.update(url, params).subscribe((r) => {
        this.success ();
      }, errors => {
        this.carrier_flash_courier_ftp_fpl.errors = errors.error
        this.notificationService.notify(`Configuração não realizada, verifique se todos os campos estão preenchidos.`, 'danger');
        this.appComponent.load(false);
      });
    } else {
      this.apiService.post(url, params).subscribe((r) => {
        this.success ();
      }, errors => {
        this.carrier_flash_courier_ftp_fpl.errors = errors.error
        this.notificationService.notify(`Configuração não realizada, verifique se todos os campos estão preenchidos.`, 'danger');
        this.appComponent.load(false);
      });
    }
  }

  success () {
    this.notificationService.notify('Configuração de importação via Ftp configurada com sucesso.', 'success');
    this.router.navigateByUrl(this.getBackUrl());
    this.appComponent.load(false);
  }

  disable () {
    this.appComponent.load(true);
    this.apiService.delete('this.url').subscribe((r) => {
      this.notificationService.notify('Integração com a Flash Courier desabilitada com sucesso.', 'success');
      this.appComponent.load(false);
    }, errors => {
      this.notificationService.notify('Erro ao desabilitar a Flash Courier.', 'danger');
      this.appComponent.load(false);
    });
  }

  getBackUrl(){
    return '/backoffice/carriers';
  }

  changeType(value){
    this.carrier_flash_courier_ftp_fpl.is_secure = value;
  }
}
