import { Component, Input, OnInit } from "@angular/core";

import { Response } from "../../model/index.model";

@Component({
  selector: "focco-tracking-status-code",
  templateUrl: "./tracking-status-code.component.html",
  styleUrls: ["./tracking-status-code.component.css"],
})
export class TrackingStatusCodeComponent implements OnInit {
  // TODO: criar a interface antes de subir para a prod
  @Input("data") tracking_data: any;

  // Em casos do correios está em greve coloque essa variavel como true
  strike_correios: boolean = false;

  constructor() {}

  ngOnInit() {}
}
