<div class="form-group row pr-3 pl-6" [ngClass]="{ 'has-error': object.errors[attribute] }">
  <label class="col-form-label" [ngClass]="{ 'text-danger': object.errors[attribute] }">Estado</label>
  <select [(ngModel)]="object[attribute]" [ngClass]="{ 'form-control-error is-invalid': object.errors[attribute] }" class="form-control select-states" (change)="changeState($event, object)">
    <option [selected]="object[attribute] === ''" value="">Selecione o estado</option>
    <option [selected]="object[attribute] === 'AC'" value="AC">AC</option>
    <option [selected]="object[attribute] === 'AL'" value="AL">AL</option>
    <option [selected]="object[attribute] === 'AP'" value="AP">AP</option>
    <option [selected]="object[attribute] === 'AM'" value="AM">AM</option>
    <option [selected]="object[attribute] === 'BA'" value="BA">BA</option>
    <option [selected]="object[attribute] === 'CE'" value="CE">CE</option>
    <option [selected]="object[attribute] === 'DF'" value="DF">DF</option>
    <option [selected]="object[attribute] === 'ES'" value="ES">ES</option>
    <option [selected]="object[attribute] === 'GO'" value="GO">GO</option>
    <option [selected]="object[attribute] === 'MA'" value="MA">MA</option>
    <option [selected]="object[attribute] === 'MT'" value="MT">MT</option>
    <option [selected]="object[attribute] === 'MS'" value="MS">MS</option>
    <option [selected]="object[attribute] === 'MG'" value="MG">MG</option>
    <option [selected]="object[attribute] === 'PA'" value="PA">PA</option>
    <option [selected]="object[attribute] === 'PB'" value="PB">PB</option>
    <option [selected]="object[attribute] === 'PR'" value="PR">PR</option>
    <option [selected]="object[attribute] === 'PE'" value="PE">PE</option>
    <option [selected]="object[attribute] === 'PI'" value="PI">PI</option>
    <option [selected]="object[attribute] === 'RJ'" value="RJ">RJ</option>
    <option [selected]="object[attribute] === 'RN'" value="RN">RN</option>
    <option [selected]="object[attribute] === 'RS'" value="RS">RS</option>
    <option [selected]="object[attribute] === 'RO'" value="RO">RO</option>
    <option [selected]="object[attribute] === 'RR'" value="RR">RR</option>
    <option [selected]="object[attribute] === 'SC'" value="SC">SC</option>
    <option [selected]="object[attribute] === 'SP'" value="SP">SP</option>
    <option [selected]="object[attribute] === 'SE'" value="SE">SE</option>
    <option [selected]="object[attribute] === 'TO'" value="TO">TO</option>
  </select>
</div>
