import { color } from "./color.model";

export const DEFAULT_BACKGROUND: string = "assets/img/rosetta-login-bg.png";

export const DAFAULT_LOGO: string = "assets/img/logo.svg";

export const Color: color = "light";

export default {
  DEFAULT_BACKGROUND,
  DAFAULT_LOGO,
  Color,
};
