import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { ApiService } from '../../services/api.service';
import { CoreService } from '../../services/core.service';
import { LoginService } from '../auth/login/login.service';
import { NotificationService } from '../../messages/notification.service';
import { Brand } from '../../../models/brand';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})

export class AuditComponent implements OnInit{
  public adminAudit: boolean;
  public backofficeAudit: boolean;
  public basePath;
  public total = 0;
  public totalPages = 2;
  public page = 1;
  public limit = 10;
  public rows = [];
  public title;
  public model;
  public model_id;
  public brand_title = '';
  brand: Brand = new Brand();

  console = console;

  constructor(
    public loginService: LoginService,
    public notificationService: NotificationService,
    public coreService: CoreService,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public acRoute: ActivatedRoute,
    public router : Router){  }

  ngOnInit(){
    this.acRoute.params.subscribe((data: any) => {
      if (data && data.model && data.id) {
        this.adminAudit    = this.router.url.includes('admin');
        this.backofficeAudit = this.router.url.includes('backoffice');

        if(data.brand_id){
          this.appComponent.load(true);
          this.apiService.get(`backoffice/brands/${data.brand_id}`).subscribe((response: any) => {
            this.brand = response;
            this.brand_title = `${response.name} -`
          });
        }

        this.model = data.model;
        this.model_id = data.id;
        const allowedModels = ['unified_post', 'ftp_account', 'brand', 'manual_post', 'status_tracking_group', 'brand_flash_courier_integrations_product']
        if (this.adminAudit || allowedModels.includes(data.model) || this.coreService.hasRole(`audit_${data.model}`)){
          this.basePath = `audit/object?model=${data.model}&obj_id=${data.id}`;
          this.getRegisters();
        }
      }
    });
  }

  getRegisters() {
    if(this.page <= this.totalPages){
      this.appComponent.load(true);
      this.apiService.get(this.basePath + this.queryParams()).subscribe((data: any) => {
        this.prepareRegisters(data);
        this.appComponent.load(false);
      });
    }
  }

  prepareRegisters(data) {
    this.title = data.description;
    for (let key in data.versions){
      this.rows.push(
        {action: data.versions[key][0], msg: data.versions[key][2], date: data.versions[key][3], hour: data.versions[key][4]}
      );
    }
    this.total = data.total_records;
    this.totalPages = data.total_pages;
  }

  queryParams() {
    return `&per=${this.limit}&page=${this.page++}`;
  }

  back(){
    let url = '';
    if(this.model == 'manual_post'){
      this.appComponent.load(true);
      this.apiService.get(`backoffice/manual_posts/${this.model_id}`).subscribe((response: any) => {
        if(response.unified_post){
          url = `/backoffice/unified_posts/${response.unified_post.id}`;
        }else{
          url = response.origin_type == 'ftp' ? '/backoffice/ftp_manual_posts' : `/backoffice/brands/${response.brand.id}/manual_posts`;
        }
        this.router.navigateByUrl(url);
        this.appComponent.load(false);
      });
    }else{
      this.router.navigateByUrl(this.baseUrl());
    }
  }

  baseUrl(){
    let url;
    switch(this.model){
      case 'customer': {
        url = '/admin/customers';
        break;
      } case 'admin_user': {
        url = '/admin/admin_users';
        break;
      } case 'group': {
        url = '/backoffice/groups';
        break;
      } case 'user': {
        url = '/backoffice/users';
        break;
      } case 'carrier_flash_courier_tracking_status': {
        url = '/admin/carriers/flash_courier';
        break;
      } case 'carrier_correios_tracking_status': {
        url = '/admin/carriers/correios';
        break;
      } case 'status_tracking_group': {
        url = `/backoffice/brands/${this.brand.id}/groupings`;
        break;
      } case 'ftp_account': {
        url = `/backoffice/brands/${this.brand.id}/ftp`;
        break;
      } case 'brand': {
        url = '/backoffice/brands';
        break;
      } case 'unified_post': {
        url = '/backoffice/unified_posts';
        break;
      } case 'brand_flash_courier_integrations_product': {
        url = `/backoffice/brands/${this.brand.id}/flash_products`;
        break;
      }
    }
    return url;
  }
}
