export class Contract {
  public id: number;

  public identifier: string;
  public customer_id: number;

  public created_at: string;
  public updated_at: string;
  public deleted_at: string;
  public errors: any = {};

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
