import { Deserializable } from "./deserializable.model";
import { StatusTrackingGroup } from "./status_tracking_group";
export class CarrierTrackingStatus implements Deserializable{
  public id: number;

  public carrier_id: number;
  public event_identifier: string;
  public name: string;
  public color: string;
  public capture_occurrence: boolean;
  public end_cycle: boolean;
  public is_new: boolean;
  public previous_rule: boolean;
  public delivered: boolean;
  public returned: boolean;
  public status_tracking_group: StatusTrackingGroup = new StatusTrackingGroup();

  public created_at: string;
  public updated_at: string;
  public errors: any = {}

  public carrier_status_ids = [];

  deserialize(input: any): this {
    Object.assign(this, input);
    if(input.status_tracking_group){
      this.status_tracking_group = new StatusTrackingGroup().deserialize(input.status_tracking_group);
    }
    return this;
  }
}
