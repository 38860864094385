import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { sfs_assign_map } from "../ship_from_store_report/ship_from_store_assign_map";
import { Brand } from "../../../../models/brand";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Customer } from "../../../../models/customer";
import { History } from "../../../../models/history";
import { Carrier } from "../../../../models/carrier";

@Component({
  selector: "carriers-report",
  templateUrl: "./carriers-report.component.html",
  styleUrls: ["./carriers-report.component.css"],
})
export class CarriersReportComponent implements OnInit {
  public sfs_assign_map: Array<any> = sfs_assign_map;
  multiple = true;
  titles = {};
  values = {};
  brands = [];
  checkbox_csv = true;
  checkbox_download = null;
  checkbox_list = null;
  url;
  brand = new Brand();
  originalOptions: Array<any>;
  carriers: Array<any> = [];
  carrier = new Carrier();
  history = new History();
  original_history_options = [
    { text: "Importado", id: "Importado" },
    { text: "Digitado", id: "Digitado" },
    { text: "Importado via API", id: "Importado API" },
  ];
  filter_options = [
    { text: "Entregues", id: "delivered" },
    { text: "Falhos", id: "failed" },
  ];

  date_now = new Date();
  default_date = String(this.date_now.getFullYear()) + "-" + String(this.date_now.getMonth() + 1).padStart(2, "0") + "-" + String(this.date_now.getDate()).padStart(2, "0");

  date_ini = this.default_date;
  date_end = this.default_date;
  report_format = "sfs";
  allCarriers: boolean = true; // trocar para false se alterar id="all-brands-checkbox" para unchecked
  checkbox_target: boolean = true;

  url_customers = "admin/customers/list_customers";
  url_brands: string = "admin/customers/list_customers_brands";
  carriers_url = "backoffice/carriers/list_carriers_select";
  customers: Array<Customer> = [];
  customer_ids: Array<number> = [];
  carriers_id: number;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router, private http: HttpClient) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.url = "backoffice/users/assigned_brands";
    this.loadData();
    this.appComponent.load(false);
  }

  public loadData() {
    this.carriers = [];
    this.apiService.get(this.carriers_url).subscribe((data: any) => {
      data && data.carriers.map((carrier) => this.carriers.push({ id: carrier[0], text: carrier[1] }));
      this.appComponent.load(false);
      window["tippy"]("a[data-tippy]");
    });

    if (this.checkbox_target) {
      this.allCarriers = true;
    }
  }

  ngAfterViewInit() {
    // Esse callback desmarca todas as opções selecionadas após fechar a janela de formulário do relatório
    const scope = this;
    window["$"]("#ship_from_store_report").on("hidden.bs.modal", function (e) {
      scope.titles = {};
      scope.values = {};
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
      scope.brand = new Brand();
      let uncheck_check_all = document.getElementById("check_all_sfs") as HTMLInputElement;
      uncheck_check_all.checked = false;
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  onSubmit() {
    const report_params = {
      fields: [""],
      date_ini: this.date_ini,
      date_end: this.date_end,
      sfs: true,
      original_history: this.history.event_names,
      all_brands: this.allCarriers,
      brands_id: this.carrier.carrier_ids,
      report_type: "fornecedor",
      filter: this.history.filter,
    };

    let download_options = {};

    if (this.convertStringToDate(this.date_end) < this.convertStringToDate(this.date_ini)) {
      this.notificationService.notify("Data inicial maior que data final", "danger");
    } else if (this.convertStringToDate(this.date_end) >= this.date_now) {
      this.notificationService.notify("Data final não pode ser maior que dia e horário atual", "danger");
    } else if (this.history.event_names.length === 0) {
      this.notificationService.notify("Selecione pelo menos uma opção de geração de pedidos.", "danger");
    } else if (this.carrier.carrier_ids.length === 0) {
      this.notificationService.notify("Selecione um fornecedor.", "danger");
    } else if (this.checkbox_csv) {
      // this.appComponent.load(true);
      this.apiService.post(`/backoffice/posts/quantity_shipped`, report_params).subscribe(
        (response) => {
          window["$"](".modal").modal("hide");
          this.notificationService.notify("O sistema está gerando a exportação em CSV." + " O relatório será enviado para o seu e-mail", "success");
          // this.appComponent.load(false);
        },
        (errors) => {
          this.notificationService.notify(`Erro ao gerar a exportação.`, "danger");
          this.appComponent.load(false);
        }
      );
    } else if (this.checkbox_list) {
      localStorage.setItem("fields", JSON.stringify([]));
      localStorage.setItem("all_brands", JSON.stringify(this.allCarriers));
      localStorage.setItem("brands_id", JSON.stringify(this.carrier.id));
      localStorage.setItem("date_ini", this.date_ini);
      localStorage.setItem("date_end", this.date_end);
      localStorage.setItem("report_format", this.report_format);
      localStorage.setItem("original_history", this.history.event_names.toString());
      localStorage.setItem("filter", this.history.filter);
      window["$"](".modal").modal("hide");
      this.router.navigateByUrl("/backoffice/manual_posts/posted_volume_list");
    } else if (this.checkbox_download) {
      let strWindowFeatures = "location=yes,height=120,width=520,scrollbars=yes,status=yes";

      download_options = {
        fields: [""],
        date_ini: this.date_ini,
        date_end: this.date_end,
        sfs: true,
        original_history: this.history.event_names,
        all_brands: this.allCarriers,
        brands_id: this.carrier.carrier_ids,
        report_type: "fornecedor",
        filter: this.history.filter,
      };

      window["$"](".modal").modal("hide");
      this.notificationService.notify("O sistema está gerando a exportação em CSV." + " Uma nova janela será aberta para completar o download.", "success");
    } else {
      this.notificationService.notify("Selecione o formato de visualização: Tela, CSV, Download.", "danger");
    }
  }

  check_download(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_download = true;
      this.checkbox_csv = false;
      this.checkbox_list = false;
    }
  }

  check_csv(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_csv = true;
      this.checkbox_list = false;
      this.checkbox_download = false;
    }
  }

  check_list(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_list = true;
      this.checkbox_csv = false;
      this.checkbox_download = false;
    }
  }

  toggleAllCarriers($event) {
    this.loadData();
    this.checkbox_target = $event.target.checked;
    const carriers_select = document.querySelector("#select_carriers_box");
    carriers_select.classList.toggle("d-none");

    if (this.checkbox_target) {
      this.carrier.carrier_ids = [1];
      this.allCarriers = true;
      return;
    }

    this.carrier.carrier_ids = [];
    this.allCarriers = false;
  }
}
