import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { first, catchError } from "rxjs/operators";

import { Response } from "../model/index.model";
import { environment } from "../../../../environments/environment";
import { Config } from "../../../shared/models/index.model";

@Injectable()
export class TrackingService {
  constructor(private httpClient: HttpClient) {}

  show(subdomain: string, brand: string = ""): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.apiUrl}trackings/${subdomain}?brand=${brand}`).pipe(
      first(),
      catchError((data: HttpErrorResponse) => {
        if (data.status === 423) return of<Response>(data.error as Response);
        return of<Response>({ config: Config });
      })
    );
  }

  findtrackingCode(subdomain: string, code: string, brand: string = ""): Observable<Response> {
    return this.httpClient.get<Response>(`${environment.apiUrl}trackings?subdomain=${subdomain}&brand=${brand}&tracking_code=${code}`).pipe(
      first(),
      catchError((data: HttpErrorResponse) => {
        if (data.status === 404) return of<Response>({ config: { is_active: false }, error: "Rastreio não encontrado" });
        return of<Response>({
          config: { is_active: false },
          error: "Erro ao processar as informações, procure o suporte",
        });
      })
    );
  }
}
