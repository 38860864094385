<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item"><a [routerLink]="getBackUrl()">Transportadoras</a></li>
          <li class="breadcrumb-item active">{{ edit_mode ? "Editar rastreio com a " : "Habilitar rastreio com a " }} Flash Courier</li>
        </ol>
      </nav>
      <h2 class="page-head-title">{{ edit_mode ? "Editar rastreio com a " : "Habilitar rastreio com a " }} Flash Courier</h2>
      <h2 class="page-head-button"><button (click)="onSubmit()" class="btn btn-space btn-primary">Habilitar</button></h2>
      <h2 class="page-head-button"><button [routerLink]="getBackUrl()" class="btn btn-space btn-secondary">Cancelar</button></h2>
      <h2 class="page-head-button">
        <button
          *ngIf="edit_mode && carrier_flash_courier_tracking.active"
          class="btn btn-space btn-danger"
          [swal]="{
            title: 'Tem certeza que deseja desabilitar a integração?',
            type: 'warning',
            text: 'Suas postagens atuais serão afetadas por esta operação.',
            confirmButtonText: 'Sim, desabilitar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
          }"
          (confirm)="disable()"
        >
          Desabilitar
        </button>
      </h2>
    </div>
    <!--Fim Conteudo titulo-->
    <!--Body Charts-->
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-border-color card-border-color-primary">
            <div class="card-header card-header-divider">Preencha os dados abaixo<span class="card-subtitle">Campos * são obrigatórios para habilitar o rastreio com a Flash Courier.</span></div>
            <div class="card-body">
              <form (ngSubmit)="onSubmit()" novalidate="">
                <text-input [object]="carrier_flash_courier_tracking" attribute="user" label="Usuário *" placeholder="Usuário" name="carrierTrackingUser"></text-input>
                <text-input [object]="carrier_flash_courier_tracking" attribute="password" label="Senha *" placeholder="Senha" name="carrierTrackingPassword"></text-input>
                <text-input [object]="carrier_flash_courier_tracking" attribute="api_url" label="Endereço da API *" placeholder="Endereço da API" name="carrierTrackingApiUrl"></text-input>
                <text-input [object]="carrier_flash_courier_tracking" attribute="flash_courier_customer_id" label="ID do cliente Flash Courier *" placeholder="ID do cliente Flash Courier" name="carrierTrackingFlashCourierCustomerId"></text-input>
                <text-input [object]="carrier_flash_courier_tracking" type="number" attribute="delay_days" label="Dias de delay para o último status *" name="carrierTrackingFlashCourierDelayDays"></text-input>
                <div class="form-group row text-right">
                  <div class="col col-sm-10 col-lg-9 offset-sm-1 offset-lg-0">
                    <button
                      *ngIf="edit_mode && carrier_flash_courier_tracking.active"
                      class="btn btn-space btn-danger"
                      [swal]="{
                        title: 'Tem certeza que deseja desabilitar a integração?',
                        type: 'warning',
                        text: 'Suas postagens atuais serão afetadas por esta operação.',
                        confirmButtonText: 'Sim, desabilitar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: true
                      }"
                      (confirm)="disable()"
                    >
                      Desabilitar
                    </button>
                    <button [routerLink]="getBackUrl()" class="btn btn-space btn-secondary">Cancelar</button>
                    <button type="submit" class="btn btn-space btn-primary">Habilitar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
