<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item"><a [routerLink]="getBackUrl()">Transportadoras</a></li>
          <li class="breadcrumb-item active">{{ edit_mode ? "Editar integração com a " : "Habilitar integração com a " }} {{ carrier.name }}</li>
        </ol>
      </nav>
      <h2 class="page-head-title">{{ edit_mode ? "Editar integração com a " : "Habilitar integração com a " }} {{ carrier.name }}</h2>
      <h2 class="page-head-button"><button (click)="onSubmit()" class="btn btn-space btn-primary">Habilitar</button></h2>
      <h2 class="page-head-button"><button [routerLink]="getBackUrl()" class="btn btn-space btn-secondary">Cancelar</button></h2>
      <h2 class="page-head-button">
        <button
          *ngIf="edit_mode && carrier_integration"
          class="btn btn-space btn-danger"
          [swal]="{
            title: 'Tem certeza que deseja desabilitar a integração?',
            type: 'warning',
            text: 'Suas postagens em andamento serão afetadas por esta operação.',
            confirmButtonText: 'Sim, desabilitar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
          }"
          (confirm)="disable()"
        >
          Desabilitar
        </button>
      </h2>
    </div>
    <!--Fim Conteudo titulo-->
    <!--Body Charts-->
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-border-color card-border-color-primary">
            <form (ngSubmit)="onSubmit()" novalidate="">
              <div class="card-header card-header-divider">
                Preencha os dados abaixo<span class="card-subtitle">Campos * são obrigatórios para integração com a {{ carrier.name }}</span>
              </div>
              <div class="card-body">
                <text-input [object]="carrier_integration" attribute="carrier_username" label="Usuário da API *" placeholder="Usuário" name="carrier_username"></text-input>
                <text-input [object]="carrier_integration" attribute="carrier_password" label="Senha da API *" placeholder="Senha" name="carrier_password"></text-input>
                <text-input
                  [object]="carrier_integration"
                  attribute="carrier_customer"
                  hint="Exemplo: 123456 ou MinhaTransportadora"
                  label="Identificador na transportadora"
                  placeholder="Identificador na transportadora"
                  name="carrier_customer"
                ></text-input>
                <text-input
                  *ngIf="carrier.user_custom_api_url"
                  [object]="carrier_integration"
                  attribute="posting_url"
                  label="Endereço de postagem da API *"
                  placeholder="Url de endereço da API de postagem"
                  hint="http://179.111.202.236/post"
                  name="posting_url"
                ></text-input>
                <text-input
                  *ngIf="carrier.user_custom_api_url"
                  [object]="carrier_integration"
                  attribute="tracking_url"
                  label="Endereço de rastreio da API *"
                  placeholder="Url de endereço da API de rastreio"
                  hint="http://179.111.202.236/tracking"
                  name="tracking_url"
                ></text-input>
                <input name="carrier_id" type="hidden" value="{{ carrier.id }}" />
                <text-input
                  [object]="carrier_integration"
                  attribute="delay_days"
                  type="number"
                  label="Delay para o último status *"
                  placeholder="30"
                  name="delay_days"
                  hint="Quantos dias continua verificando por atualizações, após finalizado"
                ></text-input>
                <text-input [object]="carrier_integration" attribute="custom_identifier" placeholder="{{ carrier.name }}" label="Identificador customizado" name="custom_identifier"></text-input>
              </div>

              <div class="card-body">
                <div class="form-group row text-right">
                  <div class="col col-sm-10 col-lg-9 offset-sm-1 offset-lg-0">
                    <button
                      *ngIf="edit_mode && carrier_integration"
                      class="btn btn-space btn-danger"
                      [swal]="{
                        title: 'Tem certeza que deseja desabilitar a integração?',
                        type: 'warning',
                        text: 'Suas postagens em andamento serão afetadas por esta operação.',
                        confirmButtonText: 'Sim, desabilitar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: true
                      }"
                      (confirm)="disable()"
                    >
                      Desabilitar
                    </button>
                    <button [routerLink]="getBackUrl()" class="btn btn-space btn-secondary">Cancelar</button>
                    <button type="submit" class="btn btn-space btn-primary">{{ carrier_integration.id ? "Editar" : "Habilitar" }}</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
