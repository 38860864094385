<div id="ship_from_store_charge_report" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title">Geração de arquivos CONEMB</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
      </div>
      <div class="card-header card-header-divider">Selecione o periodo abaixo<span class="card-subtitle">Campos são obrigatórios</span></div>
      <div class="card-body">
        <form (ngSubmit)="onSubmit()" novalidate>
          <div class="d-flex pt-3">
            <label class="col-12 col-sm-3 col-form-label text-sm-right pt-3">Intervalo de datas*</label>
            <div class="col-12 col-sm-10 box-select pb-5 pl-2">
              <div class="row">
                <input id="conemb_date_ini" type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_ini" placeholder="Data Inicial" name="date_ini" class="form-control input-form-date" (change)="setDateIni()" />
                <input id="conemb_date_end" type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_end" placeholder="Data Final" name="date_end" class="form-control input-form-date" (change)="setDateEnd()" />
              </div>
            </div>
          </div>
          <div class="col-6 ml-8">
            <select-input [object]="brand" [options]="cities_to_display" attribute="cities" label="Selecionar cidades*" (onSelect)="selectedCities($event)"></select-input>
          </div>
          <div class="form-group">
            <div class="modal-footer">
              <button *ngIf="conemb_display_button" type="submit" class="btn btn-primary btn-lg md-close mr-7">Gerar Relatório</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
