import { Component, Output, Input, OnInit, EventEmitter, ChangeDetectorRef, NgZone } from "@angular/core";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "column-select-input",
  templateUrl: "./column-select-input.component.html",
  styleUrls: ["./column-select-input.component.css"],
})
export class ColumnSelectInputComponent implements OnInit {
  @Input() object: any;
  @Input() attribute: string;
  @Input() label: string;
  @Input() settings;
  @Input() url;
  @Input() multiple = true;
  @Input() originalOptions;
  @Input() placeholder: string;

  showResetButton: boolean = false;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  selectMultipleSettings = {
    singleSelection: false,
    enableCheckAll: true,
    idField: "id",
    textField: "text",
    selectAllText: "Selecionar tudo",
    unSelectAllText: "Desmarcar tudo",
    itemsShowLimit: 5,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhum dado encontrado",
  };

  selectSimpleSettings = {
    singleSelection: true,
    enableCheckAll: false,
    idField: "id",
    textField: "text",
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "Nenhum dado encontrado",
  };

  @Output() onSelect = new EventEmitter<any>();

  constructor(private ngZone: NgZone, public apiService: ApiService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.dropdownList = this.originalOptions;
    this.dropdownSettings = this.selectSimpleSettings;
  }

  _onSelect(value, by_template = false) {
    if (value[0] && this.selectedItems && this.originalOptions) {
      const activeOption = this.selectedItems
        .map((elem) => {
          if (elem.id == value[0]) return elem;
        })
        .filter(Boolean)[0];
      if (activeOption) {
        window["$"](".column-option-" + this.attribute + " .dropdown .dropdown-toggle").html(activeOption.name);
        this.showResetButton = true;
      }
      this.onSelect.emit({ value: value, attribute: this.attribute, by_template: by_template });
    }
  }

  _onDeSelect(value) {
    this.selectedItems.forEach((carrier, index) => {
      carrier.id === value.id && this.selectedItems.splice(index, 1);
    });
    this.object[`${this.attribute}`] = this.selectedItems;
  }

  _onSelectAll(value) {
    this.selectedItems = value;
    this.object[`${this.attribute}`] = this.selectedItems.map((item) => item.id);
  }

  _onDeSelectAll() {
    this.selectedItems = [];
    this.object[`${this.attribute}`] = this.selectedItems;
  }

  selectTemplate() {
    this.showResetButton = true;
    window["$"](".column-option-" + this.attribute + " ss-multiselect-dropdown .dropdown-toggle span.caret").remove();
    this._onSelect([this.object[this.attribute]], true);
  }

  reset(repeat = true) {
    this.selectedItems = this.originalOptions
      .map((elem) => {
        if (!this.object.filledValues().includes(elem.id) || this.object[this.attribute] == elem.id) return elem;
      })
      .filter(Boolean);
    this.onSelect.emit([null]);
    this.object[this.attribute] = null;
    window["$"](".column-option-" + this.attribute + " .dropdown .dropdown-toggle").html("Selecionar");
    window["$"]("ss-multiselect-dropdown .dropdown-toggle").append(window["$"]("<span>").addClass("caret"));
    this.showResetButton = false;
    this.cdr.detectChanges();
    if (repeat) this.reset(false);
  }
}
