import { Deserializable } from "./deserializable.model";

export class CustomerFailureReason implements Deserializable {
  public id: number;
  public customer_id: number;
  public carrier_tracking_status_id: number;
  public carrier_tracking_status_name: string;
  public has_auto: boolean;

  public customer
  public carrier_tracking_status

  public created_at: string;
  public updated_at: string;
  public errors: any = {}

  public customer_failure_reason_ids = [];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
