<div #modal tabindex="-1" class="modal fade colored-header-primary" role="dialog">
  <div #modalDialog class="modal-dialog modal-dialog-centered modal-lg">
    <div #modalContent class="modal-content">
      <div #modalHeader class="modal-header modal-header-colored text-lighr d-block px-3">
        <header class="d-flex">
          <ng-content select="[header]"></ng-content>
          <button type="button btn-lg" class="close text-light" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <section class="d-flex flex-column col-12 mt-4 mb-0 px-3">
        <ng-content select="[body]"></ng-content>
      </section>
      <div class="modal-footer d-flex justify-content-end px-3">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
