<focco-ui-card>
  <div header>
    <!-- Criado a mensagens de avisos aos usuários da página-->
    <focco-ui-notification *ngIf="tracking_data?.general?.customer_message" color="danger"> <strong>AVISO:</strong> {{ tracking_data.general.customer_message }} </focco-ui-notification>
    <!-- Aviso caso os correios esatr de greve -->
    <focco-ui-notification *ngIf="strike_correios" color="danger"> <strong>AVISO:</strong> Por conta do cenário da greve dos Correios, o prazo de seu cartão pode sofrer alteração. </focco-ui-notification>
    <h2 class="d-flex justify-content-between mb-0">
      <div class="">{{ "Cliente" }}#{{ tracking_data.main.general_data.customer_number }}</div>
      <!-- Link para redirecionar a um site dos correios -->
      <div class="" *ngIf="tracking_data.main.num_enc_ter">
        <span class="icon mdi mdi-truck"></span> Codigo de Rastreio:
        <a target="_blank" href="https://www.linkcorreios.com.br/?id={{ tracking_data.main.delivery.num_enc_ter }}">{{ tracking_data.main.delivery.num_enc_ter }}</a>
      </div>
    </h2>
    <hr class="mt-1 border border-3" />
  </div>
  <div content class="d-flex flex-column">
    <ul role="tablist" class="nav nav-tabs nav-fill">
      <!--Redireciona ao card que contém as informações dos rastreios  -->
      <li class="nav-item">
        <a href="#home" data-toggle="tab" role="tab" class="nav-link active show" aria-selected="true"> <span class="icon mdi mdi-flag"></span>Principal</a>
      </li>
      <!-- Redireciona ao card que contém as informações de informações gerais -->
      <li class="nav-item">
        <a href="#profile" data-toggle="tab" role="tab" class="nav-link" aria-selected="false"> <span class="icon mdi mdi-collection-text"></span>Geral</a>
      </li>
      <!-- Redireciona ao card que contém as informações do remetente -->
      <li class="nav-item">
        <a href="#remetente" data-toggle="tab" role="tab" class="nav-link"> <span class="icon mdi mdi-account-circle"></span>Remetente</a>
      </li>
      <!-- Redireciona ao card que contém as informações do destinatário -->
      <li class="nav-item">
        <a href="#destinaratio" data-toggle="tab" role="tab" class="nav-link"> <span class="icon mdi mdi-truck"></span>Destinatário</a>
      </li>
      <!-- Redireciona ao card que contém as informações de volume das postagens -->
      <li class="nav-item">
        <a href="#volume" data-toggle="tab" role="tab" class="nav-link"> <span class="icon mdi mdi-info"></span>Inf. Volume</a>
      </li>
    </ul>
    <div class="tab-content">
      <!-- Card do rastreio  -->
      <div id="home" role="tabpanel" class="tab-pane active show">
        <div class="card-header card-header-divider">
          <a class="text-primary">
            <strong>Informações de Rastreio</strong>
          </a>
          <span class="card-subtitle">Confira o status do seu rastreio abaixo</span>
        </div>
        <div class="card-body">
          <!-- Criar os card's para mostrar os status dos rastreios -->
          <div class="row pricing-tables" *ngIf="tracking_data.main.tracking_information; else errorCarrier">
            <div class="col-lg-2" *ngFor="let tracking of tracking_data.main.tracking_information">
              <div class="pricing-table pricing-table-success" [ngStyle]="{ 'border-color': tracking.tracking_status_color }">
                <div class="pricing-table-price">
                  <span class="frecuency">{{ tracking.description }}</span>
                  <ul class="pricing-table-features">
                    <li>{{ tracking.date }} {{ tracking.hour }}</li>
                    <li>{{ tracking | trackingLocationDescription }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ng-template #errorCarrier> *Ocorreu um erro na comunicação com a transportadora. Tente novamente mais tarde! </ng-template>
        </div>
        <div class="card-header" *ngIf="tracking_data.main.delivery && tracking_data.main.delivery.downtown_date != '-'">
          <a class="text-primary">
            <strong>Entrega</strong>
          </a>
          <span class="card-subtitle">Confira abaixo o comprovante de entrega</span>
        </div>
        <div class="card-body" *ngIf="tracking_data.main.delivery && tracking_data.main.delivery.downtown_date != '-'">
          <table class="table">
            <tbody>
              <!-- Status da entrega -->
              <tr>
                <td colspan="2">A encomenda foi entregue para {{ tracking_data.main.delivery.receiver }}, RG {{ tracking_data.main.delivery.receiver_document }}, grau de parentesco: {{ tracking_data.main.delivery.degree_kinship }}.</td>
              </tr>
              <!-- Data da entrega -->
              <tr>
                <td>
                  <b>Data da Entrega</b>
                </td>
                <td>{{ tracking_data.main.delivery.downtown_date }}</td>
              </tr>
              <!-- Link para a hawb -->
              <tr>
                <td>
                  <b>Link do comprovante</b>
                </td>
                <td>
                  <!-- <span *ngIf="!tracking_data.main.general_data.hawb">-</span> -->
                  <span>-</span>
                  <!-- <a
                    *ngIf="tracking_data.main.general_data.hawb"
                    target="_blank"
                    href="http://177.154.146.97/cgi-bin/consulta_hawb.cgi?hawb={{
                      tracking_data.main.general_data.hawb
                    }}"
                    >Clique aqui para visualizar</a
                  > -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-header">
          <a class="text-primary">
            <strong>Dados Gerais</strong>
          </a>
          <span class="card-subtitle">Confira informações gerais do pacote</span>
        </div>
        <div class="card-body">
          <table class="table">
            <tbody>
              <!-- Número da HAWB -->
              <tr>
                <td>
                  <b>HAWB</b>
                </td>
                <td>{{ tracking_data.main.general_data.hawb }}</td>
              </tr>
              <!-- Lugar para onde a postagem deve ir -->
              <tr>
                <td>
                  <b>Destino</b>
                </td>
                <td>{{ tracking_data.main.general_data.destiny }}</td>
              </tr>
              <!-- código de rastreio -->
              <tr>
                <td>
                  <b>Número do Cliente</b>
                </td>
                <td>{{ tracking_data.main.general_data.customer_number }}</td>
              </tr>
              <tr>
                <td>
                  <b>Contrato</b>
                </td>
                <td>{{ tracking_data.main.general_data.contract }}</td>
              </tr>
              <tr>
                <td>
                  <b>Volume</b>
                </td>
                <td>{{ tracking_data.main.general_data.volume }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-header card-header-divider">
          <a class="text-primary">
            <strong>Localização</strong>
          </a>
          <span class="card-subtitle">Confira abaixo a localização do rastreio</span>
        </div>
        <!-- <div class="card-body" *ngIf="!tracking_data.coordinates_url_for_map">Sem informações de localização.</div> -->
        <div *ngIf="tracking_data; else erroMaps" class="card-body">
          <iframe [src]="tracking_data | trackingGoogleMapsUrl" style="width: 100%; height: 500px; border: 0px solid"></iframe>
        </div>
        <ng-template #erroMaps>
          <div class="card-body">Sem informações de localização.</div>
        </ng-template>
      </div>
      <!-- Card de informações gerais -->
      <div id="profile" role="tabpanel" class="tab-pane">
        <table class="table">
          <thead>
            <tr>
              <th>Geral</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Centro de Custo</td>
              <td>{{ tracking_data.general.cost_center }}</td>
            </tr>
            <tr>
              <td>Produto</td>
              <td>{{ tracking_data.general.get_product_name }}</td>
            </tr>
            <tr>
              <td>Transportadora</td>
              <td>{{ tracking_data.general.carrier }}</td>
            </tr>
            <tr>
              <td>Número do Cliente</td>
              <td>{{ tracking_data.general.customer_number }}</td>
            </tr>
            <tr>
              <td>Código dos Correios</td>
              <td>{{ tracking_data?.main?.general_data?.num_enc_ter && tracking_data.main.general_data.num_enc_ter }}</td>
            </tr>
            <tr>
              <td>Contrato de Terceiro</td>
              <td>{{ tracking_data.general.third_party_agreement }}</td>
            </tr>
            <tr>
              <td>Data da Postagem</td>
              <td>{{ tracking_data.general.post_date }}</td>
            </tr>
            <tr>
              <td>Data de Coleta</td>
              <td>{{ tracking_data.general.collection_date }}</td>
            </tr>
            <tr>
              <td>Data de Criação</td>
              <td>{{ tracking_data.general.date_of_creation }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Card do remetente da postagem -->
      <div id="remetente" role="tabpanel" class="tab-pane">
        <table class="table">
          <thead>
            <tr>
              <th>Remetente</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cidade</td>
              <td>{{ tracking_data.sender.city }}</td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{{ tracking_data.sender.state }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Card do dados pessoais do destinatário -->
      <div id="destinaratio" role="tabpanel" class="tab-pane">
        <table class="table">
          <thead>
            <tr>
              <th>Destinatário</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td>{{ tracking_data.recipient.name }}</td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>{{ tracking_data.recipient.city }}</td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{{ tracking_data.recipient.state }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Card das Informações sobre o volume da postagem, tamanho, peso, cubagem ... -->
      <div id="volume" role="tabpanel" class="tab-pane">
        <table class="table">
          <thead>
            <tr>
              <th>Informações de Volume</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Altura</td>
              <td>{{ tracking_data.volume_information.height }}</td>
            </tr>
            <tr>
              <td>Largura</td>
              <td>{{ tracking_data.volume_information.width }}</td>
            </tr>
            <tr>
              <td>Profundidade</td>
              <td>{{ tracking_data.volume_information.depth }}</td>
            </tr>
            <tr>
              <td>Peso Aferido</td>
              <td>{{ tracking_data.volume_information.weight_measured }}</td>
            </tr>
            <tr>
              <td>Nota Fiscal</td>
              <td>{{ tracking_data.volume_information.invoice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</focco-ui-card>
