import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from "../../../../app.component";
import { ApiService } from "../../../../core/services/api.service";
import { Customer } from "../../../../models/customer";
import { LoginService } from "../../auth/login/login.service";
import { CarrierService } from "../../../services/carrier.service";
import { CarrierFlashCourierConfig } from "../../../../models/carrier_flash_courier_config";
import { CarrierCorreiosConfig } from "../../../../models/carrier_correios_config";
import { CarrierNobreConfig } from "../../../../models/carrier_nobre_config";
import { Carrier } from "../../../../models/carrier";
import { NotificationService } from "../../../messages/notification.service";

@Component({
  selector: "app-backoffice-carrier-list",
  templateUrl: "./carrier-list.component.html",
  styleUrls: ["./carrier-list.component.css"],
})
export class CarrierListComponent implements OnInit {
  has_correios: boolean = false;
  has_correios_tracking: boolean = false;
  has_flash: boolean = false;
  has_flash_courier_tracking: boolean = false;
  has_flash_courier_ftp_fpl: boolean = false;
  has_nobre: boolean = false;
  namespace;
  url;
  customer_id: number;
  customer: Customer = new Customer();

  carrier_flash_courier_configs = new CarrierFlashCourierConfig();
  carrier_correios_configs = new CarrierCorreiosConfig();
  carrier_nobre_configs = new CarrierNobreConfig();
  carriers = new Carrier();

  constructor(public notificationService: NotificationService, public carrierService: CarrierService, private loginService: LoginService, public acRoute: ActivatedRoute, public router: Router, public appComponent: AppComponent, public apiService: ApiService) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      if (data && data.customer_id) {
        this.url = `admin/customers/${data.customer_id}/show_customer_carriers`;
        this.namespace = "admin";
        this.customer_id = data.customer_id;
        this.apiService.get(`admin/customers/${data.customer_id}`).subscribe((customer: Customer) => {
          this.customer = customer;
          this.getCarriers();
        });
      } else {
        this.url = "backoffice/show_customer_carriers";
        this.namespace = "backoffice";
        this.customer.identifier = this.loginService.getCurrentUser().customer_identifier;
        this.getCarriers();
      }
    });
  }

  getCarriers() {
    const scope = this;
    this.apiService.get(this.url).subscribe((data: any) => {
      this.has_correios = data.carrier_correios_integration;
      this.has_correios_tracking = data.carrier_correios_tracking_integration;
      this.has_flash = data.carrier_flash_courier_integration;
      this.has_flash_courier_tracking = data.carrier_flash_courier_tracking_integration;
      this.has_nobre = data.carrier_nobre_integration;
      this.has_flash_courier_ftp_fpl = data.has_flash_courier_ftp_fpl;
      this.carrierService.getCarrierConfigs(scope);
    });
  }
}
