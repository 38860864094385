export class Permission {
  public name: string;
  public customer_register: boolean;
  public user_register: boolean;
  public reports: boolean;
  public financial_report: boolean;
  public posts_create: boolean;
  public posts_edit: boolean;
  public multi_customer: boolean;
  public permissions_edit: boolean;
  public franchise_register: boolean;
  public errors: any = {};

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
