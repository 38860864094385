<focco-ui-card>
  <div header>
    <focco-ui-notification *ngIf="data?.error" color="danger">
      <!-- Caso a resposta traga a marca ou o customers, mostre a mensagem que está inativo no sistema -->
      <div *ngIf="data?.brand?.slug || data?.customer?.subdomain; else erroMessageDefault">
        <strong>{{ (data?.brand?.slug || data?.customer?.subdomain).toUpperCase() }}</strong> está {{ data?.error }}
      </div>
      <!-- Caso ao contrário mostre o erro na tela -->
      <ng-template #erroMessageDefault>
        <div *ngIf="data?.error"><strong>AVISO:</strong> {{ data?.error }}</div>
      </ng-template>
    </focco-ui-notification>
    <h2 class="text-center">Preencha o código<br />para rastrear</h2>
  </div>
  <div content>
    <!-- Se o cliente ou a marca estiver ativo -->
    <form (ngSubmit)="onSubmit($event)">
      <div class="form-group">
        <input #trackingCode="ngModel" [(ngModel)]="tracking_code" name="tracking_code" type="text" placeholder="Código de Rastreio" autocomplete="off" class="form-control" [class.is-invalid]="!trackingCode.valid && trackingCode.touched" required />
        <div class="invalid-feedback">
          <p class="text-danger">
            <i class="mdi mdi-close search-icon"></i>
            Por favor, digite o código de rastreamento do objeto.
          </p>
        </div>
      </div>
      <div class="form-group login-submit">
        <button [disabled]="!trackingCode.valid" type="submit" class="btn btn-primary btn-xl">Rastrear Objeto</button>
      </div>
    </form>
    <!-- Caso ao contrário o cliente ou a Marca estaram inativados -->
    <!-- <ng-template #disableTracking>
      <input type="text" disabled placeholder="Rastreio Inativo" class="form-control mb-4" />
    </ng-template> -->
  </div>
</focco-ui-card>
