import { Deserializable } from "./deserializable.model";

export class PostStatus {
  public id: number;

  public name: string;
  public color: string;

  public created_at: string;
  public updated_at: string;
  public errors: any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
