import { Component, Input, OnInit } from "@angular/core";

import { color } from "../../models/styles/color.model";

@Component({
  selector: "focco-ui-notification",
  templateUrl: "./notification.component.html",
})
export class NotificationComponent implements OnInit {
  @Input() color: color = "primary";

  constructor() {}

  ngOnInit() {}
}
