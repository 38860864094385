import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.css']
})

export class TextAreaInputComponent implements OnInit{
  @Input() object: any;
  @Input() attribute: string;
  @Input() errors: any;
  @Input() label: string;
  @Input() name: string;
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() maxlength: number = 5000;
  @Input() counter: boolean = false;
  @Input() rows: number;
  @Input() cols: number;

  console = console;

  ngOnInit(){
  }
}
