<div id="rejected-post-change-carrier" tabindex="-1" class="modal fade colored-header-primary" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <header class="d-flex">
          <h3 class="modal-title text-white" id="exampleModalLabel">Trocar a transportadora</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate class="d-flex flex-column align-items-center">
        <section class="form-group d-flex flex-column col-12">
          <label class="col-form-label">Selecione a transportadora *</label>
          <div class="">
            <select class="form-control" (change)="onChange($event)">
              <option [value]=""></option>
              <option *ngFor="let carrier of carriers" [value]="carrier.id" attribute="carrier_id" name="carrier_id">
                {{ carrier.name }}
              </option>
            </select>
          </div>
        </section>
        <section class="form-group d-flex flex-column col-12">
          <label class="col-form-label">Envio imediato?</label>
          <div class="form-check mt-2">
            <label class="custom-control custom-radio custom-control-inline">
              <input type="radio" (change)="ByPassPostingData(true)" [value]="true" name="bypass_posting_date" class="custom-control-input" /><span
                class="custom-control-label"
                >Sim</span
              >
            </label>
            <label class="custom-control custom-radio custom-control-inline">
              <input type="radio" (change)="ByPassPostingData(false)" [value]="false" name="bypass_posting_date" class="custom-control-input" /><span
                class="custom-control-label"
                >Não</span
              >
            </label>
          </div>
        </section>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Enviar</button>
        </div>
      </form>
    </div>
  </div>
</div>
