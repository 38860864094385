export class CarrierIntegration {
  public id:number;

  public customer_id:number;
  public carrier_id:number;
  public posting_url:string;
  public tracking_url:string;
  public carrier_username:string;
  public carrier_password:string;
  public carrier_customer:string;
  public custom_identifier:string;
  public delay_days:number;


  public created_at:string;
  public updated_at:string;
  public errors:any = {}
}
