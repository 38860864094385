import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { Post } from "../../../../models/post";
import { Router } from "@angular/router";

@Component({
  selector: "app-rejected-admin-post-edit",
  templateUrl: "./rejected-admin-post-edit.component.html",
  styleUrls: ["./rejected-admin-post-edit.component.css"],
})
export class RejectedAdminPostEditComponent implements OnInit {
  @Input() tracking: string;

  public post: Post = new Post();

  payment_type: string;
  customer_vehicle: string;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.loadPost();
    this.appComponent.load(false);
  }

  loadPost() {
    this.apiService.get(`admin/posts/${this.tracking}`).subscribe((response: any) => {
      this.post = new Post().deserialize(response);
      this.payment_type = this.post.payment_type;
      this.customer_vehicle = this.post.customer.vehicle;
    });
  }

  public onSubmit() {
    if (this.post.id) {
      this.update();
    }
  }

  private update() {
    this.appComponent.load(true);
    this.apiService.update(`admin/posts/${this.post.id}`, { post: this.post }).subscribe(
      (success) => {
        this.post.errors = "";
        this.post.posting_errors = "";
        this.post.errors_messages = "";
        this.notificationService.notify(`atualizado com sucesso.`, "success");
        this.cancel();
        this.appComponent.load(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (errors) => {
        this.post.errors = errors.error;
        if (this.post.errors.error) {
          this.notificationService.notify(this.post.errors.error, "danger");
        } else {
          this.notificationService.notify(`Erro ao atualizar a postagem ${this.post.client_document_number}`, "danger");
        }
        this.appComponent.load(false);
      }
    );
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  public cancel() {
    this.tracking = null;
  }

  changeType(value) {
    this.post.payment_type = value;
    if (value == "Pago" || value == "Maquina de cartão") {
      this.post.payment_change = 0;
    }
  }

  changeBillingSfs(value) {
    // Seleciona a Origem de venda do pedido (Loja ou Matriz).
    this.post.billing_sfs = value;
  }
}
