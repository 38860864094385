import {EventEmitter} from '@angular/core'

export class NotificationService {
  notifier = new EventEmitter<any>()

  notify(message: string, class_type?: string){
    this.notifier.emit({message: message, class_type: class_type})
  }

}
