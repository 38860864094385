<div id="customer-report-modal" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title" id="exampleModalLabel">Relatório de Customer</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <h4>Selecione os campos do seu relatório</h4>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body pt-0">
          <div class="form-group pt-1 col-12">
            <div class="d-flex">
              <label class="col-form-label text-sm-right label-first">Clientes</label>
              <div class="col-10">
                <select-input [object]="this.customer" [url]="base_path" attribute="customer_ids" [multiple]="true"></select-input>
              </div>
            </div>
          </div>
          <div class="form-group pt-1 col-12">
            <div class="d-flex">
              <label class="col-form-label text-sm-right label-first">Data incial</label>
              <input type="date" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_ini" placeholder="Data Inicial" name="date_ini" class="form-control input-form-date mb-3" />
            </div>
            <div class="d-flex">
              <label class="col-form-label text-sm-right label-first">Data final</label>
              <input type="date" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_end" placeholder="Data Final" name="date_end" class="form-control input-form-date" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
        </div>
      </form>
    </div>
  </div>
</div>
