export class CarrierFlashCourierTrackingIntegration {
  public id:number;
  
  public customer_id:number;
  public user:string;
  public password:string;
  public api_url:string;
  public flash_courier_customer_id:string;
  public delay_days:number = 0;
  active:boolean;

  public created_at:string;
  public updated_at:string;
  public errors:any = {}
}
