import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { CardComponent } from "./components/card/card.component";
import { LoadingEllipsisComponent } from "./components/loading/loading-ellipsis/loading-ellipsis.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { TemplateTrackingComponent } from "./components/template/template-tracking/template-tracking.component";

import { ModalComponent } from "./components/modal/modal.component";

const components = [LoadingEllipsisComponent, CardComponent, TemplateTrackingComponent, NotificationComponent, ModalComponent];

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: components,
  exports: components,
})
export class SharedModule {}
