import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { LoginService } from "../../../core/components/auth/login/login.service";
import { NotificationService } from "../../../core/messages/notification.service";
import { ApiService } from "../../../core/services/api.service";
import { CoreService } from "../../../core/services/core.service";
import { Brand } from "../../../models/brand";
import { BrandFranchise } from "../../../models/brand_franchise";
import { Franchise } from "../../../models/franchise";
import { SubCarrier } from "../../../models/sub_carrier";

@Component({
  selector: "app-brand-integration",
  templateUrl: "./brand-integration.component.html",
})
export class BrandIntegrationComponent implements OnInit {
  public register: BrandFranchise = new BrandFranchise();
  public franchise: Franchise = new Franchise();
  public sub_carrier: SubCarrier = new SubCarrier();
  public franchises: Array<Franchise> = [];
  public sub_carriers: Array<SubCarrier> = [];

  public base_path_select_franchises: string = "admin/select_franchises";
  public base_path_sub_carriers: string = "admin/sub_carriers";
  public base_path: string = "admin/brands";
  public navigate_path: string = "backoffice/brands";

  public title: string = "";
  public entity_name: string = "Marca";
  public carrier_type: string;

  public brand_id: number;
  public sub_carrier_id: number;
  public franchise_ids: Array<any> = [];
  public select_settings: object = [];

  public brand: Brand = new Brand();

  constructor(
    public loginService: LoginService,
    public coreService: CoreService,
    public notificationService: NotificationService,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public acRoute: ActivatedRoute,
    public router: Router
  ) {}

  async ngOnInit() {
    this.acRoute.params.subscribe(async (data: any) => {
      if (data.id) {
        this.brand_id = data.id;
        await this.loadCarrier(data.id);
        this.loadFranchises();
        this.loadSubCarriers();
      }
    });

    this.select_settings = {
      enableSearch: true,
    };
  }

  async loadCarrier(brand_id) {
    await this.loadBrand(brand_id);

    if (this.brand.franchises.length === 0) {
      const result = await new Promise((resolve, reject) => {
        this.apiService.get(`admin/get_sub_carrier?target_id=${brand_id}&target_type=brand`).subscribe((data: any) => {
          this.sub_carrier_id = data && data.sub_carrier_id;

          resolve(this.sub_carrier_id);
        });
      });
      this.carrier_type = "sub_carrier";
      return;
    }

    this.carrier_type = "franchise";
  }

  async loadBrand(brand_id) {
    const result = await new Promise((resolve, reject) => {
      this.apiService.get(`${this.base_path}/${brand_id}`).subscribe((data: Brand) => {
        this.brand = new Brand().deserialize(data);
        resolve(this.brand);
      });
    });

    return result;
  }

  loadFranchises() {
    this.appComponent.load(true);
    this.apiService.get(`${this.base_path_select_franchises}`).subscribe((resp: Array<Franchise>) => {
      this.franchises = resp.map((franchise) => new Franchise().deserialize(franchise));
      this.appComponent.load(false);
    });
  }

  loadSubCarriers() {
    this.appComponent.load(true);
    this.apiService.get(`${this.base_path_sub_carriers}`).subscribe((resp: Array<SubCarrier>) => {
      this.sub_carriers = resp.map((sub_carrier) => new SubCarrier().deserialize(sub_carrier));
      this.appComponent.load(false);
    });
  }

  onSubmit() {
    if (this.franchise.franchise_ids[0] || this.sub_carrier_id) {
      this.carrier_type === "franchise" ? this.createFranchise() : this.createSubCarrier();
    }
  }

  createFranchise() {
    this.appComponent.load(true);
    const body = {
      brand_franchise: {
        franchise_id: this.franchise.franchise_ids[0],
        franchise_priority: this.register.franchise_priority,
      },
    };

    this.apiService.post(`${this.base_path}/${this.brand_id}/brand_franchises`, body).subscribe(
      (_) => {
        this.notificationService.notify(`${this.entity_name} atualizada com sucesso.`, "success");
        this.router.navigateByUrl(`${this.navigate_path}/${this.brand_id}`);
        window["$"]("#brand-integration").modal("hide");
        this.appComponent.load(false);
      },

      (errors) => {
        this.mountRegisterErrors(errors);
        this.notificationService.notify(`Erro ao atualizar ${this.entity_name.toLowerCase()}`, "danger");

        errors.error.brand && errors.error.brand.map((e) => this.notificationService.notify(`Prioridade da franquia ${e}.`, "danger"));
        errors.error.franchise && errors.error.franchise.map((e) => this.notificationService.notify(`Franquia ${e}.`, "danger"));

        this.appComponent.load(false);
      }
    );
  }

  createSubCarrier() {
    this.appComponent.load(true);

    const body = {
      sub_carrier_id: this.sub_carrier_id,
      target_type: "Brand",
      target_id: this.brand_id,
    };

    this.apiService.post(`admin/default_sub_carrier`, body).subscribe(
      (_) => {
        this.notificationService.notify(`${this.entity_name} atualizada com sucesso.`, "success");
        this.router.navigateByUrl(`${this.navigate_path}/${this.brand_id}`);
        window["$"]("#brand-integration").modal("hide");
        this.appComponent.load(false);
      },
      (errors) => {
        this.mountRegisterErrors(errors);
        this.notificationService.notify(`Erro ao atualizar ${this.entity_name.toLocaleLowerCase()}`, "danger");
        this.appComponent.load(false);
      }
    );
  }

  onChange($event) {
    const e = $event.target;
    const value = e.options[e.selectedIndex].value;

    this.sub_carrier_id = value;
  }

  changePriority($event) {
    const e = $event.target;
    const value = e.options[e.selectedIndex].value;

    this.register.franchise_priority = value;
  }

  changeCarrierType(type: string) {
    this.carrier_type = type;

    return this.carrier_type;
  }

  mountRegisterErrors(errors) {
    this.register.errors = errors.error;
  }

  public cancel() {
    window["$"]("#brand-integration").modal("hide");
  }
}
