import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { sfs_assign_map } from "./ship_from_store_assign_map";
import { Brand } from "../../../../models/brand";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Customer } from "../../../../models/customer";
import { History } from "../../../../models/history";

@Component({
  selector: "ship_from_store_report", // esse selector é chamado no main.component.html para aparecer o modal
  templateUrl: "./ship_from_store_report.component.html",
  styleUrls: ["./ship_from_store_report.component.css"],
})
export class ShipFromStoreReportComponent implements OnInit, AfterViewInit {
  public sfs_assign_map: Array<any> = sfs_assign_map;
  multiple = true;
  titles = {};
  values = {};
  brands = {};
  brand_ids;
  checkbox_csv = true;
  checkbox_download = null;
  checkbox_list = null;
  url;
  brand = new Brand();
  originalOptions: Array<any>;
  customer = new Customer();
  customer_id;
  history = new History();
  original_history_options = [
    { text: "Importado", id: "Importado" },
    { text: "Digitado", id: "Digitado" },
    { text: "Importado via API", id: "Importado API" },
  ];

  date_now = new Date();
  default_date = String(this.date_now.getFullYear()) + "-" + String(this.date_now.getMonth() + 1).padStart(2, "0") + "-" + String(this.date_now.getDate()).padStart(2, "0");

  date_ini = this.default_date;
  date_end = this.default_date;
  report_format = "sfs";
  allBrands: boolean = true; // trocar para false se alterar id="all-brands-checkbox" para unchecked

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router, private http: HttpClient) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.customer_id = JSON.parse(localStorage.getItem("backofficeUser")).customer_id;

    this.apiService.get("backoffice/brands?q%5Bis_active_eq%5D=true&q%5Bs%5D=name+asc&per=5000").subscribe((data: any) => {
      this.brands = data.brands;
      this.customer = data.customer;
      if (this.customer.subdomain == "hubcard") {
      }
    });
    this.url = "backoffice/users/assigned_brands";
  }

  ngAfterViewInit() {
    // Esse callback desmarca todas as opções selecionadas após fechar a janela de formulário do relatório
    const scope = this;
    window["$"]("#ship_from_store_report").on("hidden.bs.modal", function (e) {
      scope.titles = {};
      scope.values = {};
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
      scope.brand = new Brand();
      let uncheck_check_all = document.getElementById("check_all_sfs") as HTMLInputElement;
      uncheck_check_all.checked = false;
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  checkValuesTitle(i) {
    // Importante:  Estrutura do sfs_assign_map => sfs_assign_map[array_index]['FIELDS']['field_index']['inside_field']['ATTRIBUTE']

    // Essa função marca apenas os blocos dos titles e os atributes dos fields
    // Ela não usa a propriedade checked dos elementos HTML (getElementeByID)
    // Ao invés disso, duas hash que são inicializadas no OnInit (a value e a titles)

    // a variável "i" é o index do title clicado no HTML
    // Assim como this.titles[i] é atualizado no HMTL com qual title foi marcado => [(ngModel)]="titles[i]"

    let field, column, j, x;
    for (j = 0; j < this.sfs_assign_map[i]["fields"].length; j++) {
      // calcula o tamanho do title clicado
      column = this.sfs_assign_map[i]["fields"][j]; // define column para iterar dentro do title
      for (x = 0; x < column.length; x++) {
        // calcula quantos field o title tem
        field = column[x]; // define qual field sera iterado
        this.values[field["attribute"]] = this.titles[i]; // marca o attribute como true para ser marcado no HMTL
      }
    }
  }

  checkAll() {
    // Essa função vai iterar por toda a estrutura do sfs_assign_map para marcar todos os campos
    // Estrutura do sfs_assign_map => sfs_assign_map[array_index]['FIELDS']['field_index']['inside_field']['ATTRIBUTE']
    let array_index, field_index, inside_field;
    let map_size = Array.from(Object.keys(this.sfs_assign_map));
    for (array_index = 0; array_index < map_size.length; array_index++) {
      // console.log(this.sfs_assign_map[array_index]);
      let check_all = document.getElementById("check_all_sfs") as HTMLInputElement; // Esse if serve para marcar todos os titles como true
      if (check_all.checked == true) {
        // já que não foi capturado individualmente
        this.titles[array_index] = true; // pela funcao checkValuesTitle no HTML
      } else if (check_all.checked == false) {
        this.titles[array_index] = false;
      }
      for (field_index = 0; field_index < this.sfs_assign_map[array_index]["fields"].length; field_index++) {
        // console.log('title ' + this.sfs_assign_map[array_index]['title'] + ' tem : ' + this.sfs_assign_map[array_index]['fields'].length + ' fields');
        for (inside_field = 0; inside_field < this.sfs_assign_map[array_index]["fields"][field_index].length; inside_field++) {
          this.values[this.sfs_assign_map[array_index]["fields"][field_index][inside_field]["attribute"]] = this.titles[array_index];
          // nessa linha ele marca como true ou false o atributo para aparecer (o titles foi definido no if acima)
          // console.log("this.values[this.sfs_assign_map[array_index]['fields'][field_index][inside_field]['attribute']]: " + this.values[this.sfs_assign_map[array_index]['fields'][field_index][inside_field]['attribute']]);
          // console.log("this.titles[array_index]: " + this.titles[array_index]);
          // console.log('nome do attribute: ' + this.sfs_assign_map[array_index]['fields'][field_index][inside_field]['attribute']);
        }
      }
    }
  }

  onSubmit() {
    const scope = this;
    const filled_checks = Object.keys(this.values).filter(function (k) {
      return scope.values[k];
    });
    const report_params = {
      fields: filled_checks,
      brand_ids: this.brand.brand_ids,
      date_ini: this.date_ini,
      date_end: this.date_end,
      sfs: true,
      original_history: this.history.event_names,
      all_brands: this.allBrands,
      customer_id: this.customer_id,
    };
    var download_options = {};

    if (filled_checks.length === 0) {
      this.notificationService.notify("Selecione pelo menos 1 checkbox para exportar em CSV.", "danger");
    } else if (this.brand.brand_ids.length === 0 && this.allBrands === false) {
      this.notificationService.notify("Selecione pelo menos uma marca.", "danger");
    } else if (this.convertStringToDate(this.date_end) < this.convertStringToDate(this.date_ini)) {
      this.notificationService.notify("Data inicial maior que data final", "danger");
    } else if (this.convertStringToDate(this.date_end) >= this.date_now) {
      this.notificationService.notify("Data final não pode ser maior que dia e horário atual", "danger");
    } else if (this.history.event_names.length === 0) {
      this.notificationService.notify("Selecione pelo menos uma opção de geração de pedidos.", "danger");
    } else if (this.checkbox_csv) {
      // this.appComponent.load(true);
      this.apiService.post(`/backoffice/posts/quantity_shipped`, report_params).subscribe(
        (response) => {
          window["$"](".modal").modal("hide");
          this.notificationService.notify("O sistema está gerando a exportação em CSV. O relatório será enviado para o seu e-mail e ficará disponível para download na aba de download de relatório", "success");
          // this.appComponent.load(false);
        },
        (errors) => {
          this.notificationService.notify(`Erro ao gerar a exportação.`, "danger");
          this.appComponent.load(false);
        }
      );
    } else if (this.checkbox_list) {
      localStorage.setItem("fields", JSON.stringify(filled_checks));
      localStorage.setItem("brand_ids", JSON.stringify(this.brand.brand_ids));
      localStorage.setItem("all_brands", JSON.stringify(this.allBrands));
      localStorage.setItem("customer_id", JSON.stringify(this.customer.id));
      localStorage.setItem("date_ini", this.date_ini);
      localStorage.setItem("date_end", this.date_end);
      localStorage.setItem("report_format", this.report_format);
      localStorage.setItem("original_history", this.history.event_names.toString());
      window["$"](".modal").modal("hide");
      this.router.navigateByUrl("/backoffice/manual_posts/posted_volume_list");
    } else if (this.checkbox_download) {
      var strWindowFeatures = "location=yes,height=120,width=520,scrollbars=yes,status=yes";

      download_options = {
        fields: filled_checks,
        brand_ids: this.brand.brand_ids,
        date_ini: this.date_ini,
        date_end: this.date_end,
        sfs: true,
        original_history: this.history.event_names,
        all_brands: this.allBrands,
        customer_id: this.customer.id,
      };

      window["$"](".modal").modal("hide");
      this.notificationService.notify("O sistema está gerando a exportação em CSV." + " Uma nova janela será aberta para completar o download.", "success");
    } else {
      this.notificationService.notify("Selecione o formato de visualização: Tela, CSV, Download.", "danger");
    }
  }

  check_download(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_download = true;
      this.checkbox_csv = false;
      this.checkbox_list = false;
    }
  }

  check_csv(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_csv = true;
      this.checkbox_list = false;
      this.checkbox_download = false;
    }
  }

  check_list(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_list = true;
      this.checkbox_csv = false;
      this.checkbox_download = false;
    }
  }

  toggleAllBrands() {
    this.allBrands = !this.allBrands;
    this.brand.brand_ids = [];
  }

  selectInputChange(event) {
    if (event[0]) {
      let uncheckAll = document.getElementById("all-brands-checkbox-sfs") as HTMLInputElement;
      uncheckAll.checked = false;
      this.allBrands = false;
    }
  }
}
