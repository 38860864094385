import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { NotificationService } from "../../../core/messages/notification.service";
import { CustomerService } from "../shared/customer.service";

@Component({
  selector: "app-customer-modal-state-active",
  templateUrl: "./customer-modal-state-active.component.html",
  styleUrls: ["./customer-modal-state-active.component.css"],
})
export class CustomerModalStateActiveComponent implements OnInit {
  @Input() customer_id: string;
  @Input() customer_fantasy_name: string;
  @Input() state_active: "active" | "desactive";

  constructor(
    public appComponent: AppComponent,
    public customerBrandService: CustomerService,
    public notificationService: NotificationService,
    public router: Router
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.appComponent.load(true);
    const state = this.state_active === "desactive" ? false : true;
    this.customerBrandService.putStateActiveCustomer({ customerId: this.customer_id, stateActive: state }).subscribe(({ message }) => {
      this.notificationService.notify(message, "success");
      this.appComponent.load(false);
      this.router.navigateByUrl("/backoffice/customers");
    });
  }
}
