<div id="index-manual-resend-post-edit" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog p-2">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <header class="d-flex">
          <h3 class="modal-title text-white" id="exampleModalLabel">{{ post?.brand?.name }} - Editar Entrega {{ post?.client_document_number }}</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <div class="mx-5 p-5 bg-light">
        <form (ngSubmit)="onSubmit()" novalidate>
          <div class="">
            <div class="form-group">
              <div *ngIf="post?.payment_type" class="form-group row pt-1 pb-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Forma de pagamento</label>
                <div class="col-12 col-sm-8 col-lg-6 form-check mt-2">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      (change)="changeType('Maquina de cartão')"
                      name="radio-inline"
                      [checked]="payment_type == 'Maquina de cartão'"
                      class="custom-control-input"
                    /><span class="custom-control-label">Maquina de cartão</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      ng-checked="true"
                      (change)="changeType('Dinheiro')"
                      name="radio-inline"
                      [checked]="payment_type == 'Dinheiro'"
                      class="custom-control-input"
                    /><span class="custom-control-label">Dinheiro</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      (change)="changeType('Pago')"
                      name="radio-inline"
                      [checked]="payment_type == 'Pago'"
                      class="custom-control-input"
                    /><span class="custom-control-label">Pago</span>
                  </label>
                </div>
              </div>
              <text-input
                *ngIf="post.complementary == true"
                [object]="post"
                attribute="description"
                placeholder="Descrição do pedido complementar"
                label="Descrição"
                name="description"
              ></text-input>
              <text-input
                id="payment-type"
                *ngIf="post.payment_type == 'Dinheiro'"
                [object]="post"
                attribute="payment_change"
                placeholder="ex: R$ 5.50"
                label="Troco"
                name="payment_change"
              ></text-input>
              <text-input
                [object]="post"
                attribute="invoice_value"
                placeholder="ex: R$ 105,00"
                label="Valor do pedido"
                name="invoice_value"
              ></text-input>
              <div class="form-group row pt-1 pb-1">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Origem da venda</label>
                <div class="col-12 col-sm-8 col-lg-6 form-check mt-2">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      (change)="changeBillingSfs('Loja')"
                      name="radio-inline-billing-sfs"
                      [checked]="this.post.billing_sfs === 'Loja'"
                      class="custom-control-input"
                    /><span class="custom-control-label">Loja</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      (change)="changeBillingSfs('Matriz')"
                      name="radio-inline-billing-sfs"
                      [checked]="this.post.billing_sfs === 'Matriz'"
                      class="custom-control-input"
                    /><span class="custom-control-label">Matriz</span>
                  </label>
                </div>
              </div>
              <!-- Medidas -->
              <text-input
                *ngIf="this.customer_vehicle === 'calculation'"
                [object]="this.post"
                attribute="package_weight_gr"
                placeholder="Peso em gr"
                label="Peso*"
                name="package_weight_gr"
              ></text-input>
              <text-input
                *ngIf="this.customer_vehicle === 'calculation'"
                [object]="this.post"
                attribute="package_width_cm"
                placeholder="Largura em cm"
                label="Largura*"
                name="package_width_cm"
              ></text-input>
              <text-input
                *ngIf="this.customer_vehicle === 'calculation'"
                [object]="this.post"
                attribute="package_length_cm"
                placeholder="Comprimento em cm"
                label="Comprimento*"
                name="package_length_cm"
              ></text-input>
              <text-input
                *ngIf="this.customer_vehicle === 'calculation'"
                [object]="this.post"
                attribute="package_height_cm"
                placeholder="Altura em cm"
                label="Altura*"
                name="package_height_cm"
              ></text-input>
              <!-- Medidas -->
              <text-input
                [object]="post"
                attribute="recipient_name"
                placeholder="Nome do cliente"
                label="Nome do cliente"
                name="postRecipietName"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address"
                placeholder="Endereço do cliente"
                label="Endereço do cliente"
                name="recipient_address"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address_number"
                placeholder="Numero"
                label="Numero"
                name="recipient_address_number"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_phone_1"
                placeholder="ex: (11) 99999-1234"
                label="Telefone*"
                name="recipient_phone_1"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address_neighborhood"
                placeholder="Bairro"
                label="Bairro"
                name="recipient_address_neighborhood"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address_zipcode"
                placeholder="CEP"
                label="CEP"
                name="recipient_address_zipcode"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address_city"
                placeholder="Cidade"
                label="Cidade"
                name="recipient_address_city"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address_state"
                placeholder="Estado"
                label="Estado"
                name="recipient_address_state"
              ></text-input>
              <text-input
                [object]="post"
                attribute="recipient_address_complement"
                placeholder="Apt 12"
                label="Complemento*"
                name="recipient_address_complement"
              ></text-input>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" (click)="loadPost()" data-dismiss="modal" class="btn btn-secondary md-close">Cancelar</button>
            <button type="submit" class="btn btn-primary md-close">Atualizar pedido</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
