<!-- Aparece se precisa ser desativado o cliente -->
<app-modal *ngIf="state_active === 'desactive'" expanded="false" type_modal="bg-danger" target_name="customer-state-active">
  <div header>
    <h3 class="modal-title text-light" id="exampleModalLabel">
      Deseja inativar o cliente <strong>{{ customer_fantasy_name }}</strong
      >?
    </h3>
  </div>
  <div body>
    <div *ngIf="state_active === 'desactive'">
      <p>Ao desativar o cliente os seguintes pontos devem ser levantados:</p>
      <ul>
        <li>Não terá acesso ao sistema focco</li>
        <li>Não terá acesso a api do sistema</li>
        <li>Usuário vinculados a conta não terão acesso</li>
      </ul>
    </div>
  </div>
  <div footer>
    <div class="col-12"></div>
    <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
    <button type="button" data-dismiss="modal" (click)="onSubmit()" class="btn btn-danger btn-lg md-close">Inativar cliente</button>
  </div>
</app-modal>
<!-- Aparece se precisa ser ativado -->
<app-modal *ngIf="state_active === 'active'" expanded="false" type_modal="bg-success" target_name="customer-state-active">
  <div header>
    <h3 class="modal-title text-light" id="exampleModalLabel">
      Deseja ativar o cliente <strong>{{ customer_fantasy_name }}</strong
      >?
    </h3>
  </div>
  <div body>
    <p>Ao Ativar o cliente os seguintes as seguintes ações seram feitas:</p>
    <ul>
      <li>Reativação dos usuários cadastrados</li>
      <li>Ativação da marca padrão</li>
      <li>Acesso a Api do sistema</li>
      <li>Permissão para ativar e desativar marcas</li>
    </ul>
  </div>
  <div footer>
    <div class="col-12"></div>
    <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
    <button type="button" data-dismiss="modal" (click)="onSubmit()" class="btn btn-success btn-lg md-close">Ativar cliente</button>
  </div>
</app-modal>
