import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Brand } from "../../../../models/brand";
@Component({
  selector: "ship_from_store_charge_report",
  templateUrl: "./ship_from_store_charge_report.component.html",
  styleUrls: ["./ship_from_store_charge_report.component.css"],
})
export class ShipFromStoreChargeReportComponent implements OnInit, AfterViewInit {
  public brand = new Brand();
  date_now = new Date();
  default_date = String(this.date_now.getFullYear()) + "-" + String(this.date_now.getMonth() + 1).padStart(2, "0") + "-" + String(this.date_now.getDate()).padStart(2, "0");
  date_ini = this.default_date;
  date_end = this.default_date;
  cities_to_display = [];
  selected_cities = [];
  conemb_display_button = true;
  public hexChange: string = null;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router, private http: HttpClient) {}

  ngOnInit() {
    this.getCitiesForRomaneios();
  }

  ngAfterViewInit() {
    const scope = this;
    window["$"]("#ship_from_store_charge_report").on("hidden.bs.modal", function (e) {
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
    });
  }

  getCitiesForRomaneios() {
    this.selected_cities = [];
    this.appComponent.load(true);
    this.apiService.get(`backoffice/romaneios/cities_for_romaneio?date_ini=${this.date_ini}&date_end=${this.date_end}`).subscribe((response: any) => {
      this.cities_to_display = response;
      this.appComponent.load(false);
    });
    this.changeHex(Math.floor(100000 + Math.random() * 900000).toString());
  }

  changeHex(newHex: string) {
    this.hexChange = newHex;
  }

  selectedCities(event) {
    this.selected_cities = event;
  }

  setDateIni() {
    this.conemb_display_button = true;
    if (new Date(this.date_ini) > this.date_now) {
      this.conemb_display_button = false;
      this.notificationService.notify("A data inicial não pode ser maior que a data atual.", "danger");
    } else if (new Date(this.date_ini) > new Date(this.date_end)) {
      this.conemb_display_button = false;
      this.notificationService.notify("A data inicial não pode ser maior que a data final.", "danger");
    }
    let selected_date_ini = document.getElementById("conemb_date_ini") as HTMLInputElement;
    this.date_ini = selected_date_ini.value;
    this.getCitiesForRomaneios();
  }

  setDateEnd() {
    this.conemb_display_button = true;
    if (new Date(this.date_end) > this.date_now) {
      this.conemb_display_button = false;
      this.notificationService.notify("A data final não pode ser maior que a data atual.", "danger");
    } else if (new Date(this.date_ini) > new Date(this.date_end)) {
      this.conemb_display_button = false;
      this.notificationService.notify("A data inicial não pode ser maior que a data final.", "danger");
    }
    let selected_date_end = document.getElementById("conemb_date_end") as HTMLInputElement;
    this.date_end = selected_date_end.value;
    this.getCitiesForRomaneios();
  }

  onSubmit() {
    this.appComponent.load(true);
    let params = { date_ini: this.date_ini, date_end: this.date_end, selected_cities: this.selected_cities };
    this.apiService.post(`backoffice/romaneios/create_conemb`, params).subscribe((response: any) => {
      this.notificationService.notify("O arquivo está sendo gerado e será enviado para seu e-mail.", "success");
      this.appComponent.load(false);
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }
}
