<div id="brand-integration" class="modal fade colored-header-primary" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <header class="d-flex">
          <h3 class="modal-title text-white" id="exampleModalLabel">Vincular franquia ou transportador</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate>
        <div class="card card-border-color card-border-color-primary">
          <div class="card-body">
            <div class="form-group row pt-1 pb-1 d-flex flex-column">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Selecionar tipo de integração</label>
              <div class="col-12 col-sm-8 col-lg-6 form-check mt-2 d-flex">
                <label class="custom-control custom-radio custom-control-inline">
                  <input type="radio" (change)="changeCarrierType('franchise')" name="CarrierType" [checked]="this.carrier_type === 'franchise'" class="custom-control-input" /><span class="custom-control-label">Franquia</span>
                </label>
                <label class="custom-control custom-radio custom-control-inline">
                  <input type="radio" (change)="changeCarrierType('sub_carrier')" name="CarrierType" [checked]="this.carrier_type === 'sub_carrier'" class="custom-control-input" /><span class="custom-control-label">Transportador Tracken</span>
                </label>
              </div>
            </div>
            <div *ngIf="this.carrier_type === 'sub_carrier'" class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Transportador Tracken</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select class="form-control" [ngClass]="{ 'form-control-error is-invalid': this.sub_carrier.errors.carrier_id }" (change)="onChange($event)">
                  <option [value]="">Selecionar</option>
                  <option *ngFor="let sub_carrier of sub_carriers" [selected]="this.sub_carrier_id && sub_carrier.id === this.sub_carrier_id" [value]="sub_carrier.id" attribute="sub_carrier_id" name="sub_carrier_id">
                    {{ sub_carrier.fantasy_name }}
                  </option>
                </select>
              </div>
            </div>
            <div *ngIf="this.carrier_type === 'franchise'" class="dropdown-full-width d-flex">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Franquias</label>
              <div class="col-6">
                <select-input [object]="franchise" [settings]="this.select_settings" [url]="base_path_select_franchises" attribute="franchise_ids" placeholder="Selecionar" [multiple]="false"></select-input>
              </div>
            </div>
            <div *ngIf="this.carrier_type === 'franchise'" class="form-group row pt-1">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Prioridade</label>
              <br />
              <div class="col-12 col-sm-8 col-lg-6">
                <div class="app-select dropdown-full-width">
                  <select class="form-control" (change)="changePriority($event)">
                    <option value="">Selecione prioridade</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="form-group row text-right">
              <div class="col col-sm-10 col-lg-9 offset-sm-1 offset-lg-0">
                <button (click)="cancel()" class="btn btn-space btn-secondary">Cancelar</button>
                <button type="submit" class="btn btn-space btn-primary">Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
