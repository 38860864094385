<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <h2 class="page-head-title">{{ customer_name || "Visualizar cliente" }}</h2>
      <h2 class="page-head-button">
        <button (click)="back()" class="btn btn-space btn-secondary">Voltar</button>
      </h2>
    </div>
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-md-4 offset-md-4" *ngIf="report">
          <div class="widget be-loading">
            <div class="widget-head">
              <div class="title float-left">Postagens por Transportadora</div>
              <div class="text-secondary float-right mt-1">{{ date }}</div>
            </div>
            <div class="widget-chart-container">
              <div id="top-sales"></div>
              <div class="chart-pie-counter">{{ report_total }}</div>
            </div>
            <div class="chart-legend">
              <table>
                <tr>
                  <td class="chart-legend-color">
                    <span data-color="top-sales-color2"></span>
                  </td>
                  <td>Correios</td>
                  <td class="chart-legend-value">{{ report["correios"] || 0 }}</td>
                </tr>
                <tr>
                  <td class="chart-legend-color">
                    <span data-color="top-sales-color1"></span>
                  </td>
                  <td>Flash Courier</td>
                  <td class="chart-legend-value">{{ report["flash_courier"] || 0 }}</td>
                </tr>
              </table>
            </div>
            <div class="be-spinner">
              <svg width="70px" height="70px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
