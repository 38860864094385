export class AdminUser {
  public id:number;
  public first_name:string;
  public last_name:string;
  public full_name:string;
  public email:string;
  public sign_in_count:string;
  public total_customers:number;
  public last_sign_in_at:string;
  public last_sign_in_ip:string;
  public current_sign_in_ip:string;

  public password:string;
  public is_active:boolean = true;
  public is_active_description:string;
  public multiple_resend:number;

  public created_at:string;
  public updated_at:string;
  public errors:any = {}

  avatar;
  public carrier_changes;
  public customers_last_month;
}
