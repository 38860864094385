<div id="permissions-save-modal" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title" id="exampleModalLabel">Criar nova categoria de permissões de usuário</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
      </div>
      <div class="modal-body pt-0">
        <form (ngSubmit)="onSubmit()" novalidate>
          <div class="modal-body pt-0">
            <div class="m-0 card-header card-header-divider">
              <h4 class="text-dark">Selecionar as permissões de usuário da categoria</h4>
            </div>
            <div class="row customer-card">
              <text-input class="col-6" [object]="this.permission" attribute="name" label="Nome da categoria *" name="name"></text-input>
            </div>
            <label class="mb-0 custom-control custom-checkbox title-checkbox">
              <input id="check_all" type="checkbox" name="check_all" (change)="checkAll($event)" class="custom-control-input" id="check_all" />
              <h4 class="custom-control-label text-dark">Marcar Todos</h4>
            </label>
            <div class="p-0 card-body">
              <div class="row">
                <div *ngFor="let field of fields" class="col-3 col-md-4 mt-2">
                  <label class="mb-0 custom-control custom-checkbox checkbox">
                    <input
                      type="checkbox"
                      name="{{ field.value }}"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="field.checked"
                      class="custom-control-input"
                    />
                    <h4 class="mt-1 custom-control-label">{{ field.name }}</h4>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" (click)="uncheckAll()" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
            <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
