<div id="download-reports" tabindex="-1" class="modal fade colored-header-primary" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <header class="d-flex">
          <h3 class="modal-title text-white" id="exampleModalLabel">Lista de relatórios para download (Sistema delivery e financeiro)</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <div class="card card-table">
        <h2 class="page-head-button pr-3">
          <form class="input-group mb-3 col-12" (ngSubmit)="getRegisters()">
            <div class="col-12 mt-2">
              <label class="custom-control custom-radio custom-control-inline"> <input type="radio" [(ngModel)]="this.queryParam" [value]="true" name="id" class="custom-control-input" /><span class="custom-control-label">ID do arquivo</span> </label>
              <label class="custom-control custom-radio custom-control-inline">
                <input type="radio" [(ngModel)]="this.queryParam" [value]="false" name="name" class="custom-control-input" /><span class="custom-control-label">Nome do arquivo</span>
              </label>
            </div>
            <input type="text" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.search" placeholder="Pesquisar importação (ID ou nome do arquivo)" class="form-control" />
            <div class="input-group-append">
              <span class="input-group-text" (click)="this.search = null; getRegisters()"><i [ngStyle]="{ visibility: this.search ? 'visible' : 'hidden' }" class="mdi mdi-close-circle"></i></span>
              <button type="submit" class="btn btn-primary">Buscar</button>
            </div>
          </form>
        </h2>
        <section class="card-header d-flex justify-content-between col-12"></section>
        <section class="card-body p-4" *ngIf="reports.length === 0">
          <p>Nenhum registro encontrado.</p>
        </section>
        <section class="card-body" *ngIf="reports.length > 0">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>
                  ID
                  <span class="glyphicon sort-icon" *ngIf="this.sort == 'contracts_id' && this.order == 'asc'">↓</span>
                  <span class="glyphicon sort-icon" *ngIf="this.sort == 'contracts_id' && this.order == 'desc'">↑</span>
                </th>
                <th>
                  Nome do arquivo
                  <span class="glyphicon sort-icon" *ngIf="this.sort == 'cep_end' && this.order == 'asc'">↓</span>
                  <span class="glyphicon sort-icon" *ngIf="this.sort == 'cep_end' && this.order == 'desc'">↑</span>
                </th>
                <th>
                  Data de importação
                  <span class="glyphicon sort-icon" *ngIf="this.sort == 'cep_start' && this.order == 'asc'">↓</span>
                  <span class="glyphicon sort-icon" *ngIf="this.sort == 'cep_start' && this.order == 'desc'">↑</span>
                </th>
                <th>Baixar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let report of reports">
                <td>{{ report.id }}</td>
                <td>{{ report.filename }}</td>
                <td>{{ report.created_at }}</td>
                <td>
                  <a data-tippy title="Baixar arquivo" href="{{ report.file.url }}">
                    <i class="fa fa-download mr-2"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <app-pagination *ngIf="reports.length > 0" (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="page" [perPage]="limit" [count]="total"> </app-pagination>
      </div>
    </div>
  </div>
</div>
