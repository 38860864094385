import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiService } from "../../../core/services/api.service";
import { NotificationService } from "../../../core/messages/notification.service";
import { AppComponent } from "../../../app.component";
import { sfs_assign_map } from "../manual-posts/ship_from_store_report/ship_from_store_assign_map";
import { Brand } from "../../../models/brand";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import * as $ from "jquery";
import { Customer } from "../../../models/customer";
import { History } from "../../../models/history";

@Component({
  selector: "financial-report-admin",
  templateUrl: "./financial-report-admin.component.html",
  styleUrls: ["./financial-report-admin.component.css"],
})
export class FinancialReportAdminComponent implements OnInit {
  public sfs_assign_map: Array<any> = sfs_assign_map;
  multiple = true;
  titles = {};
  values = {};
  brands: Array<Brand> = [];
  brand_ids;
  checkbox_csv = true;
  checkbox_download = null;
  checkbox_list = null;
  url_customers = "admin/customers/list_customers";
  url_brands;
  brand = new Brand();
  originalOptions: Array<any>;
  customer = new Customer();
  customer_ids: Array<number> = [];
  customers: Array<Customer> = [];
  history = new History();
  original_history_options = [
    { name: "Importado", id: "Importado" },
    { name: "Digitado", id: "Digitado" },
    { name: "Importado via API", id: "Importado API" },
  ];

  date_now = new Date();
  default_date =
    String(this.date_now.getFullYear()) +
    "-" +
    String(this.date_now.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(this.date_now.getDate()).padStart(2, "0");

  date_ini = this.default_date;
  date_end = this.default_date;
  report_format = "sfs";
  allBrands: boolean = true; // trocar para false se alterar id="all-brands-checkbox" para unchecked

  constructor(
    public appComponent: AppComponent,
    public apiService: ApiService,
    public notificationService: NotificationService,
    public router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loadData();
  }

  public loadData() {
    this.appComponent.load(true);
    this.apiService.get(this.url_customers).subscribe((data: any) => {
      this.customers = data;
      for (let index in data) {
        this.customer_ids.push(data[index].id);
      }
      this.url_brands = `admin/customers/list_customers_brands?customers=[${this.customer_ids}]`;
      this.apiService.get(this.url_brands).subscribe((data: any) => {
        this.brands = data;
      });
    });
  }

  ngAfterViewInit() {
    // Esse callback desmarca todas as opções selecionadas após fechar a janela de formulário do relatório
    const scope = this;
    window["$"]("#ship_from_store_report_admin").on("hidden.bs.modal", function (e) {
      scope.titles = {};
      scope.values = {};
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
      scope.brand = new Brand();
      let uncheck_check_all = document.getElementById("check_all_sfs") as HTMLInputElement;
      uncheck_check_all.checked = false;
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  onSubmit() {
    const scope = this;

    const report_params = {
      fields: [""],
      brand_ids: this.brand.brand_ids,
      date_ini: this.date_ini,
      date_end: this.date_end,
      sfs: true,
      original_history: this.history.event_names,
      all_brands: this.allBrands,
      customer_id: this.customer.id,
      report_type: "financeiro",
    };

    let download_options = {};

    if (this.brand.brand_ids.length === 0 && this.allBrands === false) {
      this.notificationService.notify("Selecione pelo menos uma marca.", "danger");
    } else if (this.convertStringToDate(this.date_end) < this.convertStringToDate(this.date_ini)) {
      this.notificationService.notify("Data inicial maior que data final", "danger");
    } else if (this.convertStringToDate(this.date_end) >= this.date_now) {
      this.notificationService.notify("Data final não pode ser maior que dia e horário atual", "danger");
    } else if (this.history.event_names.length === 0) {
      this.notificationService.notify("Selecione pelo menos uma opção de geração de pedidos.", "danger");
    } else if (this.checkbox_csv) {
      // this.appComponent.load(true);
      this.apiService.post(`/admin/report/generate`, report_params).subscribe(
        (response) => {
          window["$"](".modal").modal("hide");
          this.notificationService.notify("O sistema está gerando a exportação em CSV." + " O relatório será enviado para o seu e-mail", "success");
          // this.appComponent.load(false);
        },
        (errors) => {
          this.notificationService.notify(`Erro ao gerar a exportação.`, "danger");
          this.appComponent.load(false);
        }
      );
    } else if (this.checkbox_list) {
      localStorage.setItem("fields", JSON.stringify([]));
      localStorage.setItem("brand_ids", JSON.stringify(this.brand.brand_ids));
      localStorage.setItem("all_brands", JSON.stringify(this.allBrands));
      localStorage.setItem("customer_id", JSON.stringify(this.customer.id));
      localStorage.setItem("date_ini", this.date_ini);
      localStorage.setItem("date_end", this.date_end);
      localStorage.setItem("report_format", this.report_format);
      localStorage.setItem("original_history", this.history.event_names.toString());
      window["$"](".modal").modal("hide");
      this.router.navigateByUrl("/backoffice/manual_posts/posted_volume_list");
    } else if (this.checkbox_download) {
      let strWindowFeatures = "location=yes,height=120,width=520,scrollbars=yes,status=yes";

      download_options = {
        fields: [""],
        brand_ids: this.brand.brand_ids,
        date_ini: this.date_ini,
        date_end: this.date_end,
        sfs: true,
        original_history: this.history.event_names,
        all_brands: this.allBrands,
        customer_id: this.customer.id,
        report_type: "financeiro",
      };

      let win = window.open(`/download/send-csv?${$.param(download_options)}`, "_blank", strWindowFeatures);
      window["$"](".modal").modal("hide");
      this.notificationService.notify(
        "O sistema está gerando a exportação em CSV." + " Uma nova janela será aberta para completar o download.",
        "success"
      );
    } else {
      this.notificationService.notify("Selecione o formato de visualização: Tela, CSV, Download.", "danger");
    }
  }

  check_download(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_download = true;
      this.checkbox_csv = false;
      this.checkbox_list = false;
    }
  }

  check_csv(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_csv = true;
      this.checkbox_list = false;
      this.checkbox_download = false;
    }
  }

  check_list(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_list = true;
      this.checkbox_csv = false;
      this.checkbox_download = false;
    }
  }

  toggleAllBrands() {
    this.allBrands = !this.allBrands;
    this.brand.brand_ids = [];
  }

  selectInputChange(event) {
    if (event[0]) {
      let uncheckAll = document.getElementById("all-brands-checkbox-sfs") as HTMLInputElement;
      uncheckAll.checked = false;
      this.allBrands = false;
    }
  }

  customerSelect(event) {}
}
