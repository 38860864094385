import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { CarrierNobreIntegration } from '../../../../models/carrier_nobre_integration';
import { ApiService } from '../../../../core/services/api.service';
import { NotificationService } from '../../../../core/messages/notification.service';

@Component({
  selector: 'app-backoffice-nobre-save',
  templateUrl: './nobre-save.component.html'
})

export class NobreSaveComponent implements OnInit, AfterViewInit {

  carrier_nobre: CarrierNobreIntegration = new CarrierNobreIntegration();
  edit_mode: boolean = false;
  url;
  namespace;
  customer_id: number;

  constructor(public acRoute: ActivatedRoute, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService) { }

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      if (data && data.customer_id){
        this.url = `admin/customers/${data.customer_id}/carrier_nobre_integration`;
        this.namespace = 'admin';
        this.customer_id = data.customer_id;
      }else{
        this.url = 'backoffice/carrier_nobre_integration';
        this.namespace = 'backoffice';
      }
      this.apiService.get(this.url).subscribe((data: any) => {
        if(data){
          data.errors = {};
          this.carrier_nobre = data;
          this.edit_mode = true;
        }
        this.appComponent.load(false);
      });
    });
  }

  ngAfterViewInit(){
    this.bindCep();
  }

  onSubmit () {
    this.appComponent.load(true);
    const data = {carrier_nobre_integration: this.carrier_nobre};
    if (this.edit_mode)
      this.apiService.update(this.url, data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
    else
      this.apiService.post(this.url, data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
  }

  success (r) {
    this.notificationService.notify('Nobre configurada com sucesso.', 'success');
    if (this.namespace == 'admin' && this.customer_id)
      this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
    else
      this.router.navigateByUrl('backoffice/carriers');
    this.appComponent.load(false);
  }

  catch (errors) {
    let msg = '';
    if(errors.error.base)
      msg = errors.error.base.join(', ');
    else
      msg = 'Erro ao configurar a Nobre.';
    this.carrier_nobre.errors = errors.error;
    this.notificationService.notify(msg, 'danger');
    this.appComponent.load(false);
  }

  disable () {
    this.appComponent.load(true);
    this.apiService.delete(this.url).subscribe((r) => {
      this.notificationService.notify('Integração com a Nobre desabilitada com sucesso.', 'success');
      if (this.namespace == 'admin' && this.customer_id)
        this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
      else
        this.router.navigateByUrl('backoffice/carriers');
      this.appComponent.load(false);
    }, errors => {
      this.notificationService.notify('Erro ao desabilitar a Nobre.', 'danger');
      this.appComponent.load(false);
    });
  }

  getBackUrl(){
    if (this.namespace == 'admin' && this.customer_id)
      return `/admin/customers/${this.customer_id}/carriers`;
    else
      return '/backoffice/carriers';
  }

  changeState($event){
    const e = $event.target;
    const value = e.options[e.selectedIndex].value;
    this.carrier_nobre.address_state = value;
  }

  changeShippingType($event){
    const e = $event.target;
    const value = e.options[e.selectedIndex].value;
  }

  bindCep(){
    window['$']('input.cep').keyup( (elem) => {
      const cep = elem.target.value.replace(/[\_.-]/g, '');
      if (cep.length === 8) {
        const url = 'https://viacep.com.br/ws/' + cep + '/json/';
        window['$'].getJSON(url, (dadosRetorno) => {
          try {
            this.carrier_nobre.address = dadosRetorno.logradouro;
            this.carrier_nobre.address_neighborhood = dadosRetorno.bairro;
            this.carrier_nobre.address_city = dadosRetorno.localidade;
            this.carrier_nobre.address_state = dadosRetorno.uf;
          } catch (_error) {
            let ex = _error;
          }
        });
      }
    });
  }
}
