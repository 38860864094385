export class CarrierCorreiosConfig {
  public id:number;

  public customer_id:number;
  public identifier:string = '';

  public created_at:string;
  public updated_at:string;
  public errors:any = {}
}
