import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { LoginService } from "../auth/login/login.service";
import { NotificationService } from "../../messages/notification.service";
import { ApiService } from "../../services/api.service";
import { CoreService } from "../../services/core.service";
import { Prices } from "../../../models/prices-table";
import { User } from "../../../models/user";

@Component({
  selector: "import-customer-prices",
  templateUrl: "./import-prices.component.html",
  styleUrls: ["./import-prices.component.css"],
})
export class ImportPricesComponent implements OnInit {
  public register: Prices = new Prices();
  public user: User = new User();
  public url = "backoffice/batch_price_table";
  public navigate_url = "backoffice/customers_prices";
  public entityName = "Preços em lote";
  public roleOptions: Array<any> = [];
  public file;
  public fileDescription = "Ou clique no botão abaixo para selecionar a partir do seu computador.";
  public type = "csv";
  public showLineErrors: boolean = false;
  public showCsvLineErrors: boolean = false;
  public is_carrier: boolean = true;

  constructor(
    public loginService: LoginService,
    public coreService: CoreService,
    public notificationService: NotificationService,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public acRoute: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    if (this.router.url.includes("customers_prices")) {
      this.is_carrier = false;
    }
    this.checkPermission();
    this.acRoute.params.subscribe((data: any) => {
      this.appComponent.load(true);
      this.appComponent.load(false);
    });
    this.user = this.loginService.getCurrentUser();
  }

  checkPermission() {
    const user = JSON.parse(localStorage.getItem("backofficeUser"));

    if (!user.permissions.posts_create) {
      this.router.navigateByUrl("backoffice/dashboard");
      this.notificationService.notify(`Você não tem permissão para acessar essa página`, "warning");
    }
  }

  ngAfterViewInit() {
    let holder = document.getElementById("my-awesome-dropzone");
    holder.ondragover = function () {
      return false;
    };
    const scope = this;
    User;
    holder.ondrop = function ($event) {
      $event.preventDefault();
      const file = $event.dataTransfer.files[0];
      scope.validateFileType(file);
      if (file.name.includes("csv") || file.name.includes("xls")) {
        scope.register.file = file;
        scope.fileDescription = scope.register.file.name;
      }
    };
    window["$"]("#uploadFile").click((e) => {
      window["$"]("#fileUpload").click();
    });
  }

  onSubmit() {
    this.appComponent.load(true);
    const params = this.processParams();
    this.apiService.post(this.url, params).subscribe(
      (r: Prices) => {
        this.register = new Prices();
        if (this.router.url.includes("carriers_prices")) {
          this.navigate_url = "backoffice/carriers_prices";
        }
        this.notificationService.notify(`${this.entityName} criada com sucesso.`, "success");
        this.appComponent.load(false);
        this.router.navigateByUrl(this.navigate_url);
      },
      (errors) => {
        this.notificationService.notify(`${errors}`, "danger");
        this.appComponent.load(false);
      }
    );
  }

  changeFile($event) {
    if ($event.target.files && $event.target.files.length > 0) {
      let file = $event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.name.includes("csv") || file.name.includes("xls")) {
          this.register.file = file;
          this.fileDescription = file.name;
        }
      };
      if (!this.validateFileType(file)) {
        this.cancelUpload();
      }
    }
  }

  validateFileType(file) {
    let validate = false;
    if (file.name.includes("csv") || file.name.includes("xls")) validate = true;

    if (validate) return validate;

    this.notificationService.notify(`Formato de arquivo inválido.`, "danger");
    return validate;
  }

  processParams() {
    const params = new FormData();
    const param_name = "batch_price_table";

    if (this.register.file) params.append(`${param_name}[file]`, this.register.file, this.register.file.name);

    params.append(`${param_name}[type]`, this.type);
    params.append(`is_carrier`, `${this.is_carrier}`);

    return params;
  }

  public cancel() {
    this.router.navigateByUrl(this.navigate_url);
  }

  cancelUpload() {
    this.register.file = null;
    this.fileDescription = "Ou clique no botão abaixo para selecionar a partir do seu computador.";
  }

  back() {
    this.router.navigateByUrl(this.navigate_url);
  }
}
