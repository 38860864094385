<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item"><a routerLink="/backoffice/carriers">Transportadoras</a></li>
          <li class="breadcrumb-item active">Habilitar transportadora</li>
        </ol>
      </nav>
      <h2 class="page-head-title">Habilitar Transportadora</h2>
      <h2 class="page-head-button">
        <button routerLink="/backoffice/customers" class="btn btn-space btn-secondary">Voltar</button>
      </h2>
    </div>
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-border-color card-border-color-primary">
            <br /><br />
            <div class="text-center mt-4 mb-4">
              <h2>Habilitar Transportadora</h2>
              <br />
              <span class="note">Habilite abaixo as transportadoras que você deseja fazer postagens:</span><br /><br /><br />
            </div>
            <div class="card-header card-header-divider active-carriers">
              <div class="be-booking-promo be-booking-promo-primary be-booking-promo-color">
                <div class="be-booking-desc col-sm-6">
                  <h4 class="be-booking-desc-title">
                    Flash Courier
                    <small>
                      (Identificador: {{ carrier_flash_courier_configs.identifier }})
                      <a routerLink="/backoffice/carrier_flash_courier_configs">
                        <img src="assets/img/pencil-edit-button.svg" class="ml-2" />
                      </a>
                    </small>
                  </h4>
                  <span class="be-booking-desc-details"
                    >Habilite a transportadora Flash Courier para realizar postagens e rastreios de maneira simples e ágil.</span
                  >
                </div>
                <div class="be-booking-promo-price col-sm-6 text-right">
                  <div class="be-booking-promo-amount">
                    <span class="currency"> </span><span class="price"> </span><span class="frecuency"> </span>
                  </div>
                  <a routerLink="flash_courier_ftp" class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >{{ has_flash_courier_ftp_fpl ? "Editar" : "Habilitar" }} FTP</a
                  >
                  <a routerLink="flash_courier_tracking" class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >{{ has_flash_courier_tracking ? "Editar" : "Habilitar" }} Rastreios</a
                  >
                  <a routerLink="flash_courier" class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >{{ has_flash ? "Editar" : "Habilitar" }} Postagens</a
                  >
                  <a
                    routerLink="/backoffice/carrier_flash_courier_product_configs"
                    *ngIf="has_flash"
                    class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >Produtos</a
                  >
                </div>
              </div>
              <div class="be-booking-promo be-booking-promo-primary be-booking-promo-color be-booking-promo-correios">
                <div class="be-booking-desc col-sm-6">
                  <h4 class="be-booking-desc-title">
                    Correios
                    <small>
                      (Identificador: {{ carrier_correios_configs.identifier }})
                      <a routerLink="/backoffice/carrier_correios_configs">
                        <img src="assets/img/pencil-edit-button-blue.svg" class="ml-2" />
                      </a>
                    </small>
                  </h4>
                  <span class="be-booking-desc-details"
                    >Habilite a transportadora Correios para realizar postagens e rastreios de maneira simples e ágil.</span
                  >
                </div>
                <div class="be-booking-promo-price col-sm-6 text-right">
                  <div class="be-booking-promo-amount">
                    <span class="currency"> </span><span class="price"> </span><span class="frecuency"> </span>
                  </div>
                  <a routerLink="correios_tracking" class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >{{ has_correios_tracking ? "Editar" : "Habilitar" }} Rastreios</a
                  >
                  <a routerLink="correios" class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >{{ has_correios ? "Editar" : "Habilitar" }} Postagens</a
                  >
                  <a
                    routerLink="/backoffice/carrier_correios_product_configs"
                    *ngIf="has_correios"
                    class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >Produtos</a
                  >
                </div>
              </div>
              <div class="be-booking-promo be-booking-promo-primary be-booking-promo-color be-booking-promo-nobre">
                <div class="be-booking-desc col-sm-6">
                  <h4 class="be-booking-desc-title">
                    Nobre
                    <small>
                      (Identificador: {{ carrier_nobre_configs.identifier }})
                      <a routerLink="/backoffice/carrier_nobre_configs">
                        <img src="assets/img/pencil-edit-button.svg" class="ml-2" />
                      </a>
                    </small>
                  </h4>
                  <span class="be-booking-desc-details"
                    >Habilite a transportadora Nobre para realizar postagens e rastreios de maneira simples e ágil.</span
                  >
                </div>
                <div class="be-booking-promo-price col-sm-6 text-right">
                  <div class="be-booking-promo-amount">
                    <span class="currency"> </span><span class="price"> </span><span class="frecuency"> </span>
                  </div>
                  <a routerLink="nobre" class="btn btn-primary btn-outline be-booking-btn-price mb-1">{{ has_nobre ? "Editar" : "Habilitar" }}</a>
                  <a
                    routerLink="/backoffice/carrier_nobre_product_configs"
                    *ngIf="has_nobre"
                    class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >Produtos</a
                  >
                </div>
              </div>
              <div *ngFor="let carrier of carriers" class="be-booking-promo be-booking-promo-primary be-booking-promo-color">
                <div class="be-booking-desc col-sm-6">
                  <h4 class="be-booking-desc-title">
                    {{ carrier.name }}
                    <small>
                      (Identificador:
                      {{ carrier.current_customer_integration ? carrier.current_customer_integration.custom_identifier : carrier.name }})
                    </small>
                  </h4>
                  <span class="be-booking-desc-details"
                    >Habilite a transportadora {{ carrier.name }} para realizar postagens e rastreios de maneira simples e ágil.</span
                  >
                </div>
                <div class="be-booking-promo-price col-sm-6 text-right">
                  <div class="be-booking-promo-amount">
                    <span class="currency"> </span><span class="price"> </span><span class="frecuency"> </span>
                  </div>
                  <a
                    routerLink="/backoffice/carriers/{{ carrier.id }}/carrier_products"
                    *ngIf="carrier.current_customer_integration"
                    class="btn btn-primary btn-outline be-booking-btn-price mb-1"
                    >Produtos</a
                  >
                  <a routerLink="{{ carrier.id }}/integration" class="btn btn-primary btn-outline be-booking-btn-price mb-1">{{
                    carrier.current_customer_integration ? "Editar" : "Habilitar"
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
