import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../../core/components/auth/login/login.service";
import { NotificationService } from "../../core/messages/notification.service";
import { AppComponent } from "../../app.component";
import { ApiService } from "../../core/services/api.service";
import { CoreService } from "../../core/services/core.service";
import { Brand } from "../../models/brand";
import { Customer } from "../../models/customer";
import { User } from "../../models/user";
import { DashboardService } from "../dashboard/dashboard.service";

@Component({
  selector: "app-backoffice-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class BackofficeMainComponent implements OnInit, AfterViewInit {
  brand: Brand = new Brand();
  customer: Customer = new Customer();
  customers: Array<Customer> = [];
  user: User = new User();
  current_customer: string;

  optionsModel: number[];
  brands: Array<any> = [];

  current_user;

  public basePath: string = "backoffice/brands";
  public customerPath: string = "";
  public usersPath: string = "/backoffice/client_users";
  environment: string;

  register_permissions: boolean;
  financial_report: boolean;
  multi_customer: boolean = false;

  modal: string = "";

  constructor(
    private appComponent: AppComponent,
    private apiService: ApiService,
    public router: Router,
    public loginService: LoginService,
    public notificationService: NotificationService,
    public acRoute: ActivatedRoute,
    public coreService: CoreService,
    private dashboardService: DashboardService
  ) {}
  logout() {
    this.loginService.logout();
  }

  async ngOnInit() {
    this.current_user = JSON.parse(localStorage.getItem("backofficeUser"));

    this.user = this.loginService.getCurrentUser();

    this.getCustomers();

    this.getBrands();

    if (this.user.permissions["multi_customer"]) {
      this.multi_customer = true;
    }

    this.getEnv();
    this.getPermissions();

    document.querySelectorAll("*").forEach((element) => {
      if (element.getAttribute("style")) {
        element.setAttribute("nonce", "bb951eddb839717348703a63de2f78a9092d99556b18f265f578a25d88043aad");
      }
    });

    document.querySelectorAll("script").forEach((element) => {
      element.setAttribute("nonce", "bb951eddb839717348703a63de2f78a9092d99556b18f265f578a25d88043aad");
    });
  }

  ngAfterViewInit() {
    window["$"](".zEWidget-launcher#launcher").remove();
  }

  getEnv() {
    this.coreService.getEnvironment().subscribe(
      (environment) => {
        this.environment = environment.current;
      },
      (error) => {
        this.notificationService.notify(`${error}`, "danger");
      }
    );
  }

  getCustomers() {
    this.apiService.get(`admin/list_customers_select`).subscribe((data: any) => {
      if (data) {
        const customers = data.list.map((customer) => ({ id: customer[0], fantasy_name: customer[1], cnpj: customer[2] }));
        this.customers = customers.map((customer) => new Customer().deserialize(customer));
        this.user.customer = data.user_customer;
        this.current_customer = this.user.customer.fantasy_name;
      }
    });
  }

  getPermissions() {
    this.user.permissions["customer_register"] || this.user.permissions["user_register"] || this.user.permissions["brand_register"] || this.user.permissions["franchise_register"]
      ? (this.register_permissions = true)
      : (this.register_permissions = false);
    this.current_user.permissions["name"] === "Administrador" ? (this.customerPath = `/backoffice/customers/${this.current_user.customer_id}`) : (this.customerPath = `/backoffice/customers`);
  }

  changeCustomer($event) {
    this.appComponent.load(true);
    this.apiService.post(`backoffice/change_customer`, { customer_id: $event.id }).subscribe(
      (user: any) => {
        this.current_user.customer_api_token = user.customer_data.customer_api_token;
        this.current_user.customer_id = user.customer_data.customer_id;
        this.current_user.customer_identifier = user.customer_data.customer_identifier;
        this.current_user.customer_subdomain = user.customer_data.customer_subdomain;
        this.current_user.customer_is_active = user.customer_data.customer_is_active;

        localStorage.setItem("backofficeUser", JSON.stringify(this.current_user));
        this.ngOnInit();

        this.dashboardService.reloadDashboard();

        this.notificationService.notify(`Cliente modificado com sucesso para o usuário ${this.user.first_name} ${this.user.last_name}.`, "success");
        this.router.navigateByUrl("backoffice/");
        this.appComponent.load(false);
      },
      (errors) => {
        this.user.errors = errors.error;
        this.notificationService.notify(`Erro ao trocar Cliente para usuário ${this.user.first_name} ${this.user.last_name}`, "danger");
        setTimeout(function () {
          if (this.router.url == "/backoffice/dashboard") {
            this.ngOnInit();
          } else {
            this.router.navigateByUrl("backoffice/dashboard");
          }
        }, 3000);
      }
    );
  }

  getBrands() {
    this.apiService.get(`backoffice/brands/brands_for_select`).subscribe((data: any) => {
      this.brands = data.map((brand) => ({ id: brand[0], text: brand[1] }));
    });
  }

  onChange($event) {
    this.optionsModel = $event;
    this.router.navigateByUrl(`${this.basePath}/${$event.id}`);
  }

  searchPosts(event: any) {
    let query = event.target.querySelector("input").value;
    this.router.navigateByUrl(`backoffice/posts/${query}`);
  }

  openModal($event) {
    this.modal = $event.target.dataset.target;
  }
}
