import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";

import { TrackingService } from "../shared/services/trackings.service";
import { Response } from "../shared/model/index.model";
import { Tracking } from "../shared/model/tracking.model";

@Component({
  selector: "app-tracking-index",
  templateUrl: "./tracking-index.component.html",
})
export class TrackingIndexComponent implements OnInit, OnDestroy {
  response$: Observable<Response>;

  $queryParams: Observable<Params>;
  queryParamsSubscribe: Subscription;

  $params: Observable<Params>;
  paramsSubscribe: Subscription;

  tracking_code: string;
  subdomain: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    private router: Router
  ) {
    this.$queryParams = this.activatedRoute.queryParams;
    this.$params = this.activatedRoute.params;
  }

  ngOnInit(): void {
    this.paramsSubscribe = this.$params.subscribe((params) => {
      this.subdomain = params.subdomain;

      if (!this.activatedRoute.snapshot.queryParams.brand || !this.activatedRoute.snapshot.queryParams.tracking) {
        this.response$ = this.trackingService.show(this.subdomain);
      }
    });

    this.queryParamsSubscribe = this.$queryParams.subscribe((params) => {
      if (!params.tracking) {
        this.response$ = this.trackingService.show(this.subdomain, params.brand || "");
      } else {
        this.response$ = this.trackingService.findtrackingCode(this.subdomain, params.tracking, params.brand || "");
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscribe.unsubscribe();
    this.paramsSubscribe.unsubscribe();
  }

  onSubmit(event: Tracking): void {
    this.router.navigate([], { queryParams: { tracking: event.tracking_code }, queryParamsHandling: "merge" });
  }
}
