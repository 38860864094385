import { Component, OnInit } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";

import { NotificationService } from "../notification.service";

import { timer } from "rxjs";
import { tap, switchMap } from "rxjs/operators";

@Component({
  selector: "snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.css"],
  animations: [
    trigger("snack-visibility", [
      state(
        "hidden",
        style({
          opacity: 0,
          bottom: "0px",
          display: "none",
        })
      ),
      state(
        "visible",
        style({
          opacity: 1,
          bottom: "30px",
        })
      ),
      transition("hidden => visible", animate("500ms 0s ease-in")),
      transition("visible => hidden", animate("500ms 0s ease-out")),
    ]),
  ],
})
export class SnackbarComponent implements OnInit {
  message: string;
  class_type: string = "default";

  snackVisibility: string = "hidden";

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.notifier
      .pipe(
        tap((nt_params) => {
          this.message = nt_params.message;
          if (nt_params.class_type) {
            this.class_type = nt_params.class_type;
          }
          this.snackVisibility = "visible";
        }),
        switchMap(() => timer(4000)) // switch to a timer Observable
      )
      .subscribe(() => {
        this.snackVisibility = "hidden";
      });
  }
}
