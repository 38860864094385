import { Deserializable } from "./deserializable.model";
import { OriginAddress } from "./origin_address";
import { Franchise } from "./franchise";
export class Brand implements Deserializable {
  public id: number;

  public customer_id: number;
  public customer_identifier: string;
  public identifier: string;
  public name: string;
  public logo_image;
  public background_image;
  public logo_image_url;
  public background_image_url;
  public slug: string;
  // public post_limit_per_route:string;
  public auto_confirm_return: boolean;
  public origin_address: OriginAddress = new OriginAddress();
  public customer;

  public franchises: Array<Franchise>;

  public default_carrier_id: number;

  brand_ids = [];
  public not_send_sms: boolean;

  public dna_hawb: number;
  public tipo_enc_id: number;
  public carrier_flash_courier_product_id = null;
  public frq_rec_id: string;
  public tipo_merc_id: string;
  public id_local_rem: number;
  public flash_courier_ctt_id: string;
  public flash_courier_cost_center_id: string;
  public flash_courier_ctt_ter_id: string;
  public flash_courier_customer_number: string;
  public brand_flash_courier_integration_id: number;

  public is_active: boolean = true;
  public is_active_description: string;
  public is_open_twenty_four_hours: string;
  public open_twenty_four_hours: boolean;
  public is_custom_scheduled_posting_description: string;
  public custom_scheduled_posting: boolean;
  public custom_posting_windows: Array<number>;
  public first_posting_time: number;
  public last_posting_time: number;

  public created_at: string;
  public updated_at: string;
  public errors: any = {};
  public is_main: boolean = false;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.origin_address) {
      this.origin_address = new OriginAddress().deserialize(input.origin_address);
    }
    if (input.franchises) {
      this.franchises = input.franchises.map((franchise) => new Franchise().deserialize(franchise));
    }
    return this;
  }
}
