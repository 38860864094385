import { Component, DoCheck, ElementRef, Input, ViewChild } from "@angular/core";
import { NotificationService } from "../../../core/messages/notification.service";
import { AppComponent } from "../../../app.component";
import { ApiService } from "../../../core/services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreService } from "../../../core/services/core.service";

@Component({
  selector: "app-customer-modal-account",
  templateUrl: "./customer-modal-account.component.html",
  styleUrls: ["./customer-modal-account.component.css"],
})
export class CustomerModalAccountComponent implements DoCheck {
  @Input("email") customerEmail: string;
  @Input("fantasy-name") customerFantasyName: string;
  @Input("c-id") customerId: number;

  @ViewChild("email", { static: true }) inputEmail: ElementRef;
  @ViewChild("password", { static: true }) inputPassword: ElementRef;

  public url = "admin/customers/";

  customer = {
    password: "",
    email: "",
    errors: {},
    entityName: "",
    id: 0,
  };

  constructor(public notificationService: NotificationService, public appComponent: AppComponent, public apiService: ApiService, public acRoute: ActivatedRoute, public router: Router, public coreService: CoreService) {}

  ngDoCheck(): void {
    this.customer.email = this.customerEmail;
    this.customer.id = this.customerId;
  }

  onSubmit(e) {
    if (this.inputEmail.nativeElement.value !== this.customer.email || this.inputPassword.nativeElement.value !== "") {
      this.customer.email = this.inputEmail.nativeElement.value;
      this.customer.password = this.inputPassword.nativeElement.value;

      this.update();
    }
  }

  private update() {
    const { email, password, id } = this.customer;

    this.appComponent.load(true);
    this.apiService.update(`${this.url}/${id}/update_account`, { email, password }).subscribe(
      (success) => {
        this.notificationService.notify(`${this.customerFantasyName || "Cliente"} atualizado com sucesso.`, "success");
        this.router.navigateByUrl(`${this.url}/${this.customer.id}`);
        this.appComponent.load(false);
      },
      (errors) => {
        this.customer.errors = errors.error;
        this.notificationService.notify(`Erro ao atualizar ${this.customerFantasyName.toLowerCase()}.`, "danger");
        this.appComponent.load(false);
      }
    );
  }
}
