import { Component, OnInit } from '@angular/core';
import {AdminUser} from '../../../../models/admin_user'
import {User} from '../../../../models/user'
import {Router, ActivatedRoute} from '@angular/router'
import {ApiService} from '../../../services/api.service';
import {NotificationService} from '../../../messages/notification.service'

@Component({
  selector: 'app-auth-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})

export class RecoverPasswordComponent implements OnInit {
  public user : any;
  public adminRecover: boolean;
  public backofficeRecover: boolean;
  public token: string;

  constructor(public apiService: ApiService, public acRoute: ActivatedRoute, public router : Router, public notificationService : NotificationService){  }

  ngOnInit(){
    this.acRoute.params.subscribe((data : any) => {
      if(data && data.token)
        this.token = data.token;
    })

    this.adminRecover      = this.router.url.includes('admin')
    this.backofficeRecover = this.router.url.includes('backoffice')

    if(this.adminRecover){
      this.user = new AdminUser();
    }else if(this.backofficeRecover){
      this.user = new User();
    }
  }

  onSubmit(){
    if(this.user.password && this.user.password_confirmation){
      let url = `${this.adminRecover ? 'admin/admin_users' : 'backoffice/users'}/password`
      this.apiService.update(url, {user: {password: this.user.password, password_confirmation: this.user.password_confirmation, reset_password_token: this.token}}).subscribe((r) => {
        this.notificationService.notify('Sua senha foi alterada com sucesso.', 'success');
        this.router.navigateByUrl(`${this.adminRecover ? 'admin' : 'backoffice'}/login`);
      }, data => {
        this.notificationService.notify(data.error.error, 'danger');
        this.user.password = this.user.password_confirmation = '';
      });
    }
  }
}
