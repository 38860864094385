<div [ngClass]="{ 'has-danger': object.errors[attribute] }" class="form-group d-flex flex-column row pt-1">
  <label class="col-12 col-sm-3 col-form-label" [ngClass]="{ 'text-danger': object.errors[attribute] }" for="">{{ label }}</label>
  <div class="col-12" id="select-input-report-div">
    <div class="app-select dropdown-full-width mr-2">
      <ng-multiselect-dropdown
        [placeholder]="placeholder ? placeholder : 'Selecionar'"
        [settings]="dropdownSettings"
        [data]="dropdownList"
        [(ngModel)]="selectedItems"
        (onSelect)="_onSelect($event)"
        (onDeSelect)="_onDeSelect($event)"
        (onSelectAll)="_onSelectAll($event)"
        (onDeSelectAll)="_onDeSelectAll()"
      ></ng-multiselect-dropdown>
    </div>
    <p *ngIf="object.errors[attribute]" class="text-danger">{{ object.errors[attribute].join(", ") }}</p>
  </div>
</div>
