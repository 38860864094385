import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trackingLocationDescription",
})
export class TrackingLocationDescriptionPipe implements PipeTransform {
  transform(tracking: any, args?: any): any {
    let desc = tracking.place;

    if (tracking.city !== "-") desc += ` - ${tracking.city}`;
    if (tracking.state !== "-") desc += `/${tracking.state}`;

    return desc;
  }
}
