import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-select-states",
  templateUrl: "./select-states.component.html",
})
export class SelectStatesComponent implements OnInit {
  @Input() object: any;
  @Input() attribute: string;
  @Input() label: string;
  @Input() settings;
  @Input() url;
  @Input() options: Array<any>;
  @Input() reloadOnChange = false;
  @Output() onSelect = new EventEmitter<any>();

  public states: Array<string> = [];

  constructor(public apiService: ApiService, public appComponent: AppComponent) {}

  ngOnInit() {
    this.allStates();
  }

  ngOnChanges() {
    if (this.reloadOnChange) {
      this.ngOnInit();
    }
  }

  allStates() {
    this.states = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
  }

  _onSelect(value) {
    this.onSelect.emit(value);
  }

  changeState($event, object) {
    const e = $event.target;
    const value = e.options[e.selectedIndex].value;
    object.address_state = value;
  }
}
