import { Deserializable } from "./deserializable.model";
import { ManualPost } from './manual_post';
import { PostStatus } from './post_status';
import { Customer } from "./customer";
import { Brand } from "./brand";
import { CarrierApiPostResponse } from "./carrier_api_post_response";
import { TrackingUpdateLog } from "./tracking_update_log";
import { StatusUpdateLog } from "./status_update_log";
import { CarrierTrackingStatus } from "./carrier_tracking_status";
import { Possessor } from "./possessor";

export class Post implements Deserializable {
  public id:number;

  public post_possessor: Possessor = new Possessor();
  public manual_post_possessor: Possessor = new Possessor();

  public customer_id:number;
  public manual_post_id:number;
  public manual_post: ManualPost;
  public post_status: PostStatus;
  public brand: Brand;
  public carrier_tracking_status: CarrierTrackingStatus;
  public brand_name: string;
  public customer: Customer;
  public customer_name: string;
  public carrier_response: CarrierApiPostResponse;
  public tracking_update_logs: Array<TrackingUpdateLog> = [];
  public status_update_logs: Array<StatusUpdateLog> = [];

  public client_document_number:string;
  public focco_identifier:string;
  public my_number:number;
  public num_enc_ter:string;
  public parent_order:number;
  public dispatch_date:number;
  public carrier:number;
  public carrier_contract:number;
  public recipient_name:string;
  public recipient_address:string;
  public recipient_address_number:string;
  public recipient_address_complement:string;
  public recipient_address_neighborhood:string;
  public recipient_address_city:string;
  public recipient_address_state:string;
  public recipient_address_zipcode:string;
  public recipient_address_reference_point:string;
  public recipient_phone_1:string;
  public recipient_phone_2:string;
  public recipient_email:string;
  public cpf_cnpj:string;
  public ie:number;
  public recipient_department:number;
  public package_weight_gr:number;
  public package_width_cm:number;
  public package_length_cm:number;
  public package_height_cm:number;
  public invoice_series:number;
  public invoice:number;
  public invoice_value:number;
  public invoice_emission_date:number;
  public invoice_access_key:number;
  public products_value:number;
  public observation_1:number;
  public observation_2:number;
  public observation_3:number;
  public observation_4:number;
  public cd_code:number;
  public cd_cnpj:number;
  public cd_name:number;
  public cd_phone:number;
  public cd_email:number;
  public cd_address:number;
  public cd_address_number:number;
  public cd_address_complement:number;
  public cd_address_reference: number;
  public cd_address_zipcode:number;
  public cd_address_city:number;
  public cd_address_neighborhood:number;
  public cd_address_state:number;
  public recipient_address_lat:number;
  public recipient_address_lng:number;

  public payment_type:string;
  public payment_change:number;

  public tracking_code;
  public last_tracking_status;
  public tracking_status_color;
  public last_tracking_status_post_status_name:string;
  public franchise_route_identifier:string;

  public delivery_last_status:string;
  public last_tracking_status_found_at:string;
  public due_date:string;
  public delivery_deadline:string;
  public situation:string;
  public delivery_date_formated:string;
  public delivery_date:string;
  public returned_at:string;
  public origin_zipcode:string;

  public carrier_product;
  public cost_center_id;
  public billing_sfs;

  public separated:boolean;
  public ready_for_delivery:boolean;
  public route_separated:boolean;

  public complementary:boolean;
  public complemented_post_id:string;
  public description:string;

  public order_index:number;
  public failure_reason:string;
  public receiver_name:string;
  public receiver_document:string;
  public receiver_role:string;

  public created_at:string;
  public created_at_formated:string;
  public updated_at:string;
  public posting_errors: string;
  public errors_messages:string;
  public attempts_quantity:number;
  public errors:any = {};

  deserialize(input: any): this {
    Object.assign(this, input);
    this.post_status = new PostStatus().deserialize(input.post_status);
    if (input.tracking_update_logs) {
      this.tracking_update_logs = input.tracking_update_logs.map((tracking_update_log) => new TrackingUpdateLog().deserialize(tracking_update_log));
    }
    if (input.status_update_logs) {
      this.status_update_logs = input.status_update_logs.map((status_update_log) => new StatusUpdateLog().deserialize(status_update_log));
    }
    if (input.customer) {
      this.customer = new Customer().deserialize(input.customer);
    }
    if (input.brand) {
      this.brand = new Brand().deserialize(input.brand);
    }
    if (input.carrier_tracking_status) {
      this.carrier_tracking_status = new CarrierTrackingStatus().deserialize(input.carrier_tracking_status);
    }
    return this;
  }
}

