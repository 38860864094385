import { Deserializable } from "./deserializable.model";
import { History } from "./history";
import { User } from "./user";

export class StatusUpdateLog implements Deserializable {
  public user: User;
  public history: History;
  public user_name: string;
  public created_at: string;

  public errors:any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.user) {
      this.user = new User().deserialize(input.user);
    }
    if (input.history) {
      this.history = new History().deserialize(input.history);
    }
    return this;
  }
}
