<div id="carriers-report" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title">Relatório Fornecedores</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <h4>Selecione os campos do seu relatório</h4>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate class="pb-5">
        <div class="modal-body pt-0">
          <div class="form-group row pt-1">
            <div class="mt-3">
              <label class="ml-3 custom-control custom-checkbox title-checkbox">
                <input class="custom-control-input" id="all-carriers-checkbox-sfs" type="checkbox" name="allCarriers" (change)="toggleAllCarriers($event)" checked />
                <h4 class="mt-0 custom-control-label text-dark">Todos os fornecedores</h4>
              </label>
            </div>
          </div>
          <div id="select_carriers_box" class="form-group row pt-1 d-none">
            <div class="col-12 d-flex mt-3">
              <label class="col-form-label text-sm-right label-first">Fornecedores</label>
              <div class="col-6 box-select">
                <p *ngIf="this.carriers.length === 0">Carregando fornecedores...</p>
                <select-input *ngIf="this.carriers.length > 0" (change)="onChange($event)" [options]="this.carriers" [object]="this.carrier" attribute="carrier_ids" [multiple]="true"></select-input>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Datas</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row">
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_ini" placeholder="Data Inicial" name="date_ini" class="form-control input-form-date" />
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_end" placeholder="Data Final" name="date_end" class="form-control input-form-date" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 d-flex mt-3">
              <label class="col-form-label text-sm-right label-first">Gerado via</label>
              <div class="col-12 col-sm-9 box-select">
                <select-input *ngIf="this.original_history_options" [options]="this.original_history_options" [object]="this.history" attribute="event_names" [multiple]="true"></select-input>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12">
              <label class="col-form-label text-sm-right label-first">Filtro</label>
              <div class="col-12 col-sm-9 box-select">
                <select-input *ngIf="this.filter_options" [options]="this.filter_options" [object]="this.history" attribute="filter"></select-input>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
        </div>
      </form>
    </div>
  </div>
</div>
