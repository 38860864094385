import { Customer } from "./customer";
import { Brand } from "./brand";
import { Group } from "./group";

export class User {
  public id: number;
  public customer: Customer;
  public first_name: string;
  public last_name: string;
  public email: string;
  public sign_in_count: string;
  public last_sign_in_at: string;
  public last_sign_in_ip: string;
  public current_sign_in_ip: string;
  public permissions_id: Array<any>;
  public multiple_resend: number;

  public password: string;
  public super_admin: boolean = false;
  public is_active: boolean = true;
  public is_active_description: string;
  public group_ids: Array<any> = [];
  public groups: Array<any>;
  public group_names: string;
  public loginType: string;

  public customer_id: number = 0;
  public customer_identifier: string;

  public brands: Array<any>;
  public brands_id: Array<number> = [];
  public brand_names: string;
  public permissions: Array<any> = [];

  public created_at: string;
  public updated_at: string;
  public errors: any = {};
  public error: any;
  public avatar;

  public roles: any;
  public password_expired: boolean;
  public token: string;

  constructor(params = {}) {
    for (let key in params) this[key] = params[key];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.customer) {
      this.customer = new Customer().deserialize(input.customer);
    }
    if (input.brands) {
      this.brands = input.brands.map((brand) => new Brand().deserialize(brand));
    }
    if (input.groups) {
      this.groups = input.groups.map((group) => new Group().deserialize(group));
    }
    return this;
  }
}
