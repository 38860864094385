<div id="possessor_details" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title" id="exampleModalLabel">Detalhes de possuintes do pedido</h3>
        </div>
      </div>
      <div class="modal-body pt-0">
        <div class="form-group row pt-1">
          <div class="col-12">
            <h4 class="task_label">Possuinte da Origem</h4>
            <div class="col-12 col-sm-9 box-select">
            </div>
          </div>
          <ul *ngIf="manual_post.manual_post_possessor;else undeclared" class="style_list_none">
            <li>
              Nome: {{ manual_post?.manual_post_possessor?.possessor_name }}
            </li>
            <li>
              Documento: {{ manual_post?.manual_post_possessor?.possessor_document }}
            </li>
            <li>Assinatura</li>
            <li>
              <img src="{{ manual_post?.manual_post_possessor?.possessor_signature }}" class="possessor-images" alt="Assinatura">
            </li>
            <li>Foto da Entrega</li>
            <li>
              <img src="{{ manual_post?.manual_post_possessor?.package_photo  }}" class="possessor-images" alt="Entrega">
            </li>
          </ul>
        </div>
        <div class="form-group row pt-1">
          <div class="col-12 col-sm-12">
            <h4 class="task_label">Possuinte da Entrega</h4>
            <div class="col-12 col-sm-10 box-select">
            </div>
          </div>
          <ul *ngIf="set_post.post_possessor;else undeclared" class="style_list_none">
            <li>
              Nome: {{ set_post?.post_possessor?.possessor_name }}
            </li>
            <li>
              Documento: {{ set_post?.post_possessor?.possessor_document }}
            </li>
            <li>
              Tipo do possuinte: {{ set_post?.post_possessor?.possessor_role }}
            </li>
            <li >Assinatura</li>
            <li>
              <img src="{{ set_post?.post_possessor?.possessor_signature }}" class="possessor-images" alt="Assinatura" >
            </li>
            <li >Foto da Entrega</li>
            <li>
              <img src="{{ set_post?.post_possessor?.package_photo }}" class="possessor-images" alt="Entrega">
            </li>
          </ul>
        </div>
      </div>
        <ng-template #undeclared>
          <ul>
            <li>
              Não Declarado
            </li>
          </ul>
        </ng-template>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Fechar</button>
        </div>
    </div>
  </div>
</div>

