import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { NotificationService } from "../../../core/messages/notification.service";
import { ApiService } from "../../../core/services/api.service";

@Component({
  selector: "app-brand-modal-desactive",
  templateUrl: "./brand-modal-desactive.component.html",
})
export class BrandModalDesactive implements OnInit {
  @Input() brand_id: any;
  @Input() brand_name: any;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router) {}

  ngOnInit() {}

  onSubmit() {
    const url = `admin/brands/${this.brand_id}/desactive_brand`;
    this.appComponent.load(true);
    this.apiService.delete(url).subscribe(
      (success) => {
        this.notifyPostResend({
          message: "Marca desativada com sucesso!",
          type: "success",
        });
        this.router.navigateByUrl("/admin/brands");
        this.appComponent.load(false);
      },
      (errors) => {
        this.notifyPostResend({
          message: errors.error || "Errp ao atualizar os dados",
          type: "danger",
        });
        this.appComponent.load(false);
      }
    );
  }

  private notifyPostResend({ type, message }) {
    this.notificationService.notify(message, type);
    window["$"]("#brand-modal-desactive").modal("hide");
  }
}
