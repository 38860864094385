import { Deserializable } from "./deserializable.model";
import { Brand } from "./brand";
import { Group } from "./group";
import { CustomerFailureReason } from "./customer_failure_reason";

export class Customer implements Deserializable {
  public id: number;
  public fantasy_name: string = "";
  public social_name: string;
  public cnpj: string;
  public ie: string;
  public email: string;
  public password: string;
  public commercial_phone: string;
  public cell_phone: string;
  public contact_name: string;
  public address: string;
  public address_number: string;
  public address_complement: string;
  public address_neighborhood: string;
  public address_zipcode: string;
  public address_state: string;
  public address_city: string;
  public anniversary: string;
  public vehicle: string;
  public billing_company_social_name: string;
  public billing_company_cnpj: string;
  public selling_company_social_name: string;
  public selling_company_cnpj: string;

  public description: string;
  public subdomain: string;
  public logo_image;
  public logo_image_url;
  public background_image;
  public background_image_url;
  public is_active: boolean = true;
  public is_active_description: string;
  public identifier: string;
  public customer_ids: Array<any> = [];
  public sftp_account_ids: Array<any> = [];
  public sfs_client: boolean;

  public intelipost_tracking_webhook: boolean;
  public intelipost_tracking_webhook_description: string;
  public auto_resend_post_description: string;
  public auto_confirm_return_description: string;
  public intelipost_scheduled_posting: boolean;
  public is_intelipost_scheduled_posting_description: string;
  public intelipost_posting_windows: Array<number>;

  public frete_rapido_token: string;
  public frete_rapido_tracking_webhook: boolean;
  public frete_rapido_tracking_webhook_description: string;

  public linx_token: string;
  public linx_tracking_webhook: boolean;
  public linx_tracking_webhook_description: string;

  public intelipost_token: string;

  public api_token: string;

  public brands: Array<Brand>;
  public active_brands: Array<Brand>;
  public groups: Array<Group>;

  public customer_failure_reasons: Array<CustomerFailureReason>;
  public has_auto_list: Array<any> = [];

  public post_limit_per_route: string;
  public auto_confirm_return: boolean;
  public is_multicollect_description: string;
  public auto_resend_post: boolean;

  public carrier_list: Array<any> = [];

  levoo_posts_api;
  users_active;
  users_inactive;
  post_by_months;
  last_sign_in;

  total_logs;
  success_logs;
  error_logs;
  not_found_logs;
  public send_sms: boolean;
  public not_sms_brands: Array<any> = [];

  public created_at: string;
  public updated_at: string;
  public errors: any = {};

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.active_brands) {
      this.active_brands = input.active_brands.map((brand) => new Brand().deserialize(brand));
    }
    if (input.brands) {
      this.brands = input.brands.map((brand) => new Brand().deserialize(brand));
    }
    if (input.groups) {
      this.groups = input.groups.map((group) => new Group().deserialize(group));
    }
    if (input.customer_failure_reasons) {
      this.customer_failure_reasons = input.customer_failure_reasons.map((customer_failure_reason) => new CustomerFailureReason().deserialize(customer_failure_reason));
    }
    return this;
  }
}
