import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private reloadDashboardSource = new Subject<void>();

  reloadDashboard$ = this.reloadDashboardSource.asObservable();

  reloadDashboard() {
    this.reloadDashboardSource.next();
  }
}
