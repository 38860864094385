import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { Post } from "../../../../models/post";
import { Router } from "@angular/router";
import { LoginService } from "../../../../core/components/auth/login/login.service";
import { AdminUser } from "../../../../models/admin_user";

@Component({
  selector: "app-index-manual-resend-post-edit",
  templateUrl: "./index-manual-resend-post-edit.component.html",
})
export class IndexManualResendPostEditComponent implements OnInit {
  @Input() tracking: string;

  public post: Post = new Post();

  public base_path: string = "admin/admin_users";
  public navigate_path: string = "backoffice/admin_users";

  payment_type: string;
  customer_vehicle: string;
  current_user_multiple_resend;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public loginService: LoginService, public router: Router) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.loadPost();
    this.loadCurrentUserMultipleResend();
    this.appComponent.load(false);
  }

  onSubmit() {
    this.post.id && this.update();
  }

  update() {
    this.appComponent.load(true);
    this.apiService.update(`admin/posts/${this.post.id}`, { post: this.post, resend: true, multiple_resend: this.current_user_multiple_resend }).subscribe(
      (data: any) => {
        this.post.errors = "";
        this.post.posting_errors = "";
        this.post.errors_messages = "";
        this.notificationService.notify(`Atualizado com sucesso`, "success");
        this.cancel();
        this.appComponent.load(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (errors) => {
        this.post.errors = errors.error;
        this.post.errors.error ? this.notificationService.notify(this.post.errors.error, "danger") : this.notificationService.notify(`Erro ao atualizar a postagem ${this.post.client_document_number}`, "danger");

        this.appComponent.load(false);
      }
    );
  }

  loadPost() {
    this.apiService.get(`admin/posts/${this.tracking}`).subscribe((data: any) => {
      this.post = new Post().deserialize(data);
      this.payment_type = this.post.payment_type;
      this.customer_vehicle = this.post.customer.vehicle;
    });
  }

  loadCurrentUserMultipleResend() {
    // A qtde do multiple_resend representa se o current_user, que é admin_user, pode burlar o limite de 3 tentativas de envio para a Levoo.
    // É enviado para o backend no update().

    let current_user_incomplete = this.loginService.getCurrentUser();
    this.appComponent.load(true);
    this.apiService.get(`${this.base_path}/${current_user_incomplete.id}`).subscribe((data: AdminUser) => {
      this.current_user_multiple_resend = data.multiple_resend;
      this.appComponent.load(false);
    });
  }

  changeBillingSfs(value) {
    // Seleciona a Origem de venda do pedido (Loja ou Matriz).
    this.post.billing_sfs = value;
  }

  changeType(value) {
    this.post.payment_type = value;
    if (value === "Pago" || value === "Maquina de cartão") {
      this.post.payment_change = 0;
    }
  }

  convertToString(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  cancel() {
    this.tracking = null;
  }
}
