import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { Brand } from "../../../../models/brand";
import { Router } from "@angular/router";
import { Post } from "../../../../models/post";

@Component({
  selector: "app-posts-manual-posts-posted-volume-list",
  templateUrl: "./posted-volume-list.component.html",
  styleUrls: ["./posted-volume-list.component.css"],
})
export class PostedVolumeListComponent implements OnInit, AfterViewInit {
  posts: Array<Post> = [];
  columns: Array<any> = [];
  fields = localStorage.getItem("fields");
  brand_ids = localStorage.getItem("brand_ids");
  allBrands = localStorage.getItem("all_brands");
  customer_id = localStorage.getItem("customer_id");
  date_ini = localStorage.getItem("date_ini");
  date_end = localStorage.getItem("date_end");
  original_history = localStorage.getItem("original_history");
  date_ini_translated = new Date();
  date_end_translated = new Date();
  brand_names = "";
  search = "";
  total = 0;
  page = 1;
  limit = 25;
  report_format = "posted_volume_report";

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router) {
    this.apiService.post(`backoffice/posts/quantity_shipped_list_translate`, { fields: localStorage.getItem("fields"), report_format: localStorage.getItem("report_format") || this.report_format }).subscribe(
      (response: any) => {
        for (let field of response) {
          if (field.attribute != "tracking_code") {
            this.columns.push({ attribute: field.attribute, label: field.label });
          }
        }
        this.report_format = localStorage.getItem("report_format") || this.report_format;
        this.getRegisters();

        localStorage.removeItem("fields");
        localStorage.removeItem("brand_ids");
        localStorage.removeItem("all_brands");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("date_ini");
        localStorage.removeItem("date_end");
        localStorage.removeItem("report_format");
        localStorage.removeItem("original_history");
        localStorage.removeItem("filter");
      },
      (errors) => {
        this.notificationService.notify(`Erro ao gerar listagem! Tente novamente`, "danger");
        this.appComponent.load(false);
        this.router.navigateByUrl("/backoffice");
      }
    );
    if (this.date_ini && this.date_end) {
      this.date_ini_translated = new Date(this.date_ini.replace("-", "/").replace("-", "/"));
      this.date_end_translated = new Date(this.date_end.replace("-", "/").replace("-", "/"));
    }
  }

  getRegisters(callback = null) {
    this.appComponent.load(true);
    this.apiService
      .post(
        `/backoffice/posts/quantity_shipped_list${this.queryParams()}`,
        // tslint:disable-next-line:max-line-length
        { fields: this.fields, brand_ids: this.brand_ids, all_brands: this.allBrands, customer_id: this.customer_id, date_ini: this.date_ini, date_end: this.date_end, original_history: this.original_history }
      )
      .subscribe(
        (posts_data: any) => {
          this.posts = posts_data.posts.map((post) => new Post().deserialize(post));
          this.total = posts_data.total_records;
          this.brand_ids = posts_data.brand_ids;
          this.getBrandsName();
          this.appComponent.load(false);
        },
        (errors) => {
          this.notificationService.notify(`Erro ao gerar listagem! Tente novamente`, "danger");
          this.appComponent.load(false);
          this.router.navigateByUrl("/backoffice");
        }
      );
  }

  getBrandsName(callback = null) {
    this.apiService.get(`backoffice/brands/get_name_by_ids?brand_ids=[${this.brand_ids}]`).subscribe((brands: any) => {
      const last_item = brands[brands.length - 1];
      this.brand_names = brands.join(", ").replace(", " + last_item, " e " + last_item);
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  goToPage(data): void {
    this.page = data.page;
    this.limit = data.limit;
    this.getRegisters();
  }

  onNext(): void {
    this.page++;
    this.getRegisters();
  }

  onPrev(): void {
    this.page--;
    this.getRegisters();
  }

  queryParams() {
    const pagination = `?per=${this.limit}&page=${this.page}&report_format=${this.report_format}`;
    let full_params = pagination;
    if (this.search && this.search != "") {
      full_params += `&q%5Btracking_code_or_client_document_number_cont%5D=${encodeURIComponent(this.search)}`; // custom line
    }
    return full_params;
  }
}
