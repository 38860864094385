import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { CarrierFlashCourierIntegration } from '../../../../models/carrier_flash_courier_integration';
import { ApiService } from '../../../../core/services/api.service';
import { NotificationService } from '../../../../core/messages/notification.service';

@Component({
  selector: 'app-backoffice-flash_courier-save',
  templateUrl: './flash_courier-save.component.html'
})

export class FlashCourierSaveComponent implements OnInit {

  carrier_flash_courier: CarrierFlashCourierIntegration = new CarrierFlashCourierIntegration();
  edit_mode: boolean = false;
  url;
  namespace;
  customer_id: number;
  flash_products_url;

  constructor(public acRoute: ActivatedRoute, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService) { }

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      if (data && data.customer_id){
        this.url = `admin/customers/${data.customer_id}/carrier_flash_courier_integration`;
        this.namespace = 'admin';
        this.customer_id = data.customer_id;
        this.flash_products_url = `admin/customers/${data.customer_id}/carrier_flash_courier_products`;
      }else{
        this.url = 'backoffice/carrier_flash_courier_integration';
        this.namespace = 'backoffice';
        this.flash_products_url = 'backoffice/carrier_flash_courier_products';
      }
      this.apiService.get(this.url).subscribe((data: any) => {
        if(data){
          data.errors = {};
          this.carrier_flash_courier = data;
          this.edit_mode = true;
        }
        this.appComponent.load(false);
      });
    });
  }

  onSubmit () {
    this.appComponent.load(true);
    const data = {carrier_flash_courier_integration: this.carrier_flash_courier};
    if(data['carrier_flash_courier_integration'].carrier_flash_courier_product_id && data['carrier_flash_courier_integration'].carrier_flash_courier_product_id.length > 0)
      data['carrier_flash_courier_integration'].carrier_flash_courier_product_id = data['carrier_flash_courier_integration'].carrier_flash_courier_product_id[0]
    if (this.edit_mode)
      this.apiService.update(this.url, data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
    else
      this.apiService.post(this.url, data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
  }

  success (r) {
    this.notificationService.notify('Flash courier configurada com sucesso.', 'success');
    if (this.namespace == 'admin' && this.customer_id)
      this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
    else
      this.router.navigateByUrl('backoffice/carriers');
    this.appComponent.load(false);
  }

  catch (errors) {
    let msg = '';
    if(errors.error.base)
      msg = errors.error.base.join(', ');
    else
      msg = 'Erro ao configurar a Flash Courier.';
    this.carrier_flash_courier.errors = errors.error;
    this.notificationService.notify(msg, 'danger');
    this.appComponent.load(false);
  }

  disable () { 
    this.appComponent.load(true);
    this.apiService.delete(this.url).subscribe((r) => {
      this.notificationService.notify('Integração com a Flash Courier desabilitada com sucesso.', 'success');
      if (this.namespace == 'admin' && this.customer_id)
      this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
      else
        this.router.navigateByUrl('backoffice/carriers');
      this.appComponent.load(false);
    }, errors => {
      this.notificationService.notify('Erro ao desabilitar a Flash Courier.', 'danger');
      this.appComponent.load(false);
    });
  }

  getBackUrl(){
    if (this.namespace == 'admin' && this.customer_id)
      return `/admin/customers/${this.customer_id}/carriers`;
    return '/backoffice/carriers';
  }
}
