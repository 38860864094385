import { CarrierSelect } from "./carrier_select";
import { CepRange } from "./cep_range";
import { Contract } from "./contract";
import { Deserializable } from "./deserializable.model";

export class Prices implements Deserializable {
  public id: number;
  public index: number;

  public contracts_id: Array<Contract>;
  public carriers: Array<CarrierSelect>;
  public contract_type: string;

  public value_flat: number;
  public gris: number;
  public km_aditional: number;

  public weight_max: number;
  public weight_additional: number;
  public vehicle_type: string;

  public distance: number;
  public cep_start: string;
  public cep_end: string;
  public cep_ranges_id: Array<CepRange>;
  public fixed_point: boolean;

  public created_at: string;
  public updated_at: string;
  public deleted_at: string;
  public errors: any = {};

  public file: any;
  public file_name: string;

  public is_carrier: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class ImportedPrices implements Deserializable {
  public id: number;
  public index: number;

  public file: object;
  public response: string;
  public is_carrier: boolean;
  public processed: boolean;
  public created_at: string;
  public updtated_at: string;
  public users_id: number;
  public file_name: string;

  public status: object;
  public errors: Array<any>;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
