export const hub_assign_map = [
  // CHECKLIST: Arquivos para verificar sempre que houver alteração em algum campo do relatório:
  // 1) send_report_to_ftp_job.rb ===> adicionar/remover os attributes daqui, lá.
	// 2) post.rb ===> adicionar/remover os attributes daqui, lá. Como métodos ou atributos (migração).
	// 3) post_serializer.rb ===> adicionar/remover os attributes daqui, lá.
	// 4) _posts_shipped_list.json.jbuilder ===> adicionar/remover lá apenas os MÉTODOS de post (os atributos já estarão no @fields dessa partial).
	// 5) BR.models.yml ==> é de lá que saem os label do relatório e não daqui. Portanto, se o relatório da Hub, SFS ou Focco tiver um label diferente do que tá lá, tem que criar um método no post e adicionar em todos os arquivos desse checklist.
	// 6) hub-posted-volume-report-assign.ts ==> daqui saem o cabeçalho do relatório, mas sem os labels!
  { // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
    'title': 'Dados Básicos do Cliente',
    'fields': [[{ 'attribute': 'client_document_number_hub', 'label': 'Meu número', 'required': true }]]
  },
  {
    'title': 'Dados Opcionais',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'my_number_hub', 'label': 'Meu pedido', 'required': false },
        { 'attribute': 'parent_order', 'label': 'Pedido pai', 'required': false }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'client_document_number_or_num_enc_ter', 'label': 'Código de rastreio', 'required': false },
        { 'attribute': 'created_at_formated', 'label': 'Data de criação', 'required': false }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'num_enc_ter', 'label': 'Remessa', 'required': false }
      ]
    ]
  },
  {
    'title': 'Dados de Transporte',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'dispatch_date_formated', 'label': 'Data de despacho', 'required': false },
        { 'attribute': 'carrier', 'label': 'Transportadora *', 'required': true }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'get_product_name', 'label': 'Produto *', 'required': true },
        { 'attribute': 'cost_center_id', 'label': 'Centro de custo', 'required': false }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'carrier_contract', 'label': 'Contrato na transportadora', 'required': false },
        { 'attribute': 'origin_zipcode', 'label': 'CEP de Origem', 'required': false }
      ]
    ]
  },
  {
    'title': 'Dados de Endereço',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'recipient_name', 'label': 'Nome do destinatário *', 'required': true },
        { 'attribute': 'recipient_address', 'label': 'Nome do logradouro do destinatário *', 'required': true },
        { 'attribute': 'recipient_address_number', 'label': 'Número do destinatário', 'required': false },
        { 'attribute': 'recipient_address_complement', 'label': 'Complemento do destinatário', 'required': false },
        { 'attribute': 'recipient_address_neighborhood', 'label': 'Bairro do destinatário *', 'required': true }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'recipient_address_city', 'label': 'Cidade do destinatário *', 'required': true },
        { 'attribute': 'recipient_address_state', 'label': 'UF do destinatário *', 'required': true },
        { 'attribute': 'recipient_address_zipcode', 'label': 'CEP do destinatário *', 'required': true },
        { 'attribute': 'recipient_address_reference_point', 'label': 'Ponto de referência', 'required': false },
        { 'attribute': 'recipient_phone_1', 'label': 'Telefone 1 do destinatário', 'required': false }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'recipient_phone_2', 'label': 'Telefone 2 do destinatário', 'required': false },
        { 'attribute': 'recipient_email', 'label': 'E-mail do destinatário', 'required': false },
        { 'attribute': 'cpf_cnpj', 'label': 'CPF/CNPJ', 'required': false },
        { 'attribute': 'ie', 'label': 'IE', 'required': false },
        { 'attribute': 'recipient_department', 'label': 'Departamento do destinatário', 'required': false }
      ]
    ]
  },
  {
    'title': 'Dados de Embalagem',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'package_weight_gr', 'label': 'Peso da encomenda (em gr) *', 'required': true },
        { 'attribute': 'package_width_cm', 'label': 'Largura da embalagem (em cm)', 'required': false },
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'package_length_cm', 'label': 'Comprimento da embalagem (em cm)', 'required': false },
        { 'attribute': 'package_height_cm', 'label': 'Altura da embalagem (em cm)', 'required': false }
      ]
    ]
  },
  {
    'title': 'Dados Fiscais',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'invoice_series', 'label': 'Série da nota fiscal', 'required': false },
        { 'attribute': 'invoice', 'label': 'Nota', 'required': false },
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'invoice_value', 'label': 'Valor da nota fiscal', 'required': false },
        { 'attribute': 'invoice_emission_date_formated', 'label': 'Data de emissão da nota fiscal', 'required': false },
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'invoice_access_key', 'label': 'Chave de acesso da nota fiscal', 'required': false },
        { 'attribute': 'products_value', 'label': 'Valor dos produtos', 'required': false }
      ]
    ]
  },
  {
    'title': 'Dados de Observação',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'observation_1', 'label': 'Obs 1', 'required': false },
        { 'attribute': 'observation_2', 'label': 'Obs 2', 'required': false },
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'observation_3', 'label': 'Obs 3', 'required': false },
        { 'attribute': 'observation_4', 'label': 'Obs 4', 'required': false }
      ]
    ]
  },
  {
    'title': 'Dados de Entrega',
    'fields': [
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'delivery_deadline', 'label': 'Prazo de Entrega', 'required': false },
        { 'attribute': 'last_tracking_status_hub', 'label': 'Último Status Recebido', 'required': false },
        { 'attribute': 'delivery_date_formated', 'label': 'Data de Entrega', 'required': false },
        { 'attribute': 'last_tracking_status_found_at_formated', 'label': 'Data do último status recebido', 'required': false },
        { 'attribute': 'delivery_return_date_formated', 'label': 'Data de Devolução', 'required': false },
        { 'attribute': 'occurrence', 'label': 'Ocorrência', 'required': false },
        { 'attribute': 'is_failure_reason', 'label': 'Existe ocorrência?', 'required': false }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'status_groups_report', 'label': 'Status Agrupado', 'required': false },
        { 'attribute': 'cep_head_5', 'label': 'Cabeça de CEP', 'required': true },
        { 'attribute': 'is_capital_city', 'label': 'Praça', 'required': false },
        { 'attribute': 'due_date_formated', 'label': 'Previsão de Entrega', 'required': false },
        { 'attribute': 'tracking_page', 'label': 'Página de Rastreamento', 'required': false },
        { 'attribute': 'brand_name', 'label': 'Marca', 'required': false },
        { 'attribute': 'is_delivered', 'label': 'Situação', 'required': false }
      ],
      [ // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { 'attribute': 'spreadsheet_quantity', 'label': 'Quantidade de itens', 'required': false },
        { 'attribute': 'attempt_delivery_1', 'label': 'Tentativa de entrega 1', 'required': true },
        { 'attribute': 'attempt_delivery_2', 'label': 'Tentativa de entrega 2', 'required': true },
        { 'attribute': 'attempt_delivery_3', 'label': 'Tentativa de entrega 3', 'required': true },
        { 'attribute': 'attempts_quantity', 'label': 'Quantidade de tentativas', 'required': true },
        { 'attribute': 'last_tracking_status_due_date_formated', 'label': 'Previsão de entrega da transportadora', 'required': true }
      ]
    ]
  }
];










