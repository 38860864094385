import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { LoginService } from "../../../core/components/auth/login/login.service";
import { NotificationService } from "../../../core/messages/notification.service";
import { ApiService } from "../../../core/services/api.service";
import { CoreService } from "../../../core/services/core.service";
import { DownloadReport } from "../../../models/download_report";

@Component({
  selector: "download-reports",
  templateUrl: "./download-reports.component.html",
  styleUrls: ["./download-reports.component.css"],
})
export class DownloadReportsComponent implements OnInit {
  public reports: Array<DownloadReport> = [];
  public url = "backoffice/reports";
  public file;
  public user;

  public total = 0;
  public page = 1;
  public limit = 10;
  public sort = "created_at";
  public order = "desc";
  public search = "";
  public queryParam: boolean;

  constructor(
    public loginService: LoginService,
    public coreService: CoreService,
    public notificationService: NotificationService,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public acRoute: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.getRegisters();
  }

  public getRegisters(callback = null) {
    this.appComponent.load(true);
    this.apiService.get(this.url + this.queryParams()).subscribe((data: any) => {
      window["App"].init();
      this.reports = data.data.map((up) => new DownloadReport().deserialize(up));

      this.total = data.total_records;

      if (callback) {
        callback();
      }
      this.appComponent.load(false);
      window["tippy"]("a[data-tippy]");
    });
  }

  queryParams() {
    this.user = JSON.parse(localStorage.getItem("backofficeUser"));
    const pagination = `?per=${this.limit}&page=${this.page}&q%5Bs%5D=${this.sort}+${this.order}`;

    let full_params = pagination;
    full_params += `&q[users_id_eq]=${this.user.id}`;

    if (this.sort && this.order) {
      full_params += `${pagination}&q%5Bs%5D=${this.sort}+${this.order}`;
    } else {
      full_params += `${pagination}&q%5Bs%5D=send_date+desc`;
    }
    if (this.search && this.search !== "" && this.queryParam) {
      full_params += `&q%5Bid_eq%5D=${this.search}`;
    }
    if (this.search && this.search !== "" && !this.queryParam) {
      full_params += `&q%5Bfile_name_cont%5D=${this.search}`;
    }

    return full_params;
  }

  searchFor(param: string) {}

  goToPage(data): void {
    this.page = data.page;
    this.limit = data.limit;
    this.getRegisters();
  }

  onNext(): void {
    this.page++;
    this.getRegisters();
  }

  onPrev(): void {
    this.page--;
    this.getRegisters();
  }

  goSort(sortParams): void {
    this.sort = sortParams.sort;
    this.order = sortParams.order === "asc" ? "desc" : "asc";
    this.getRegisters();
  }
}
