<div class="be-wrapper be-login" [ngClass]="[adminRecover ? 'admin' : 'backoffice']">
  <div class="be-content">
    <div class="main-content container-fluid">
      <div class="splash-container forgot-password">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header"><img src="assets/img/logo_focco_large_dark@2x.png" alt="logo" width="139" class="logo-img"><span class="splash-description">Preencha os dados para alterar sua senha.</span></div>
          <div class="card-body">
            <form (ngSubmit)="onSubmit()" novalidate>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Sua nova senha" [(ngModel)]="this.user.password" name="userPassword">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Confirme sua nova senha" [(ngModel)]="this.user.password_confirmation" name="userConfirmationPassword">
              </div>
              <div class="form-group pt-1">
                <button type="submit" class="btn btn-block btn-primary btn-xl">Alterar senha</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
