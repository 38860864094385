import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "../../../../app.component";
import { LoginService } from "../../auth/login/login.service";
import { NotificationService } from "../../../messages/notification.service";
import { ApiService } from "../../../services/api.service";
import { CoreService } from "../../../services/core.service";
import { ImportedPrices } from "../../../../models/prices-table";
import { User } from "../../../../models/user";

@Component({
  selector: "app-import-prices-index",
  templateUrl: "./import-prices-index.component.html",
  styleUrls: ["./import-prices-index.component.css"],
})
export class ImportPricesIndexComponent implements OnInit {
  public imported_prices: Array<ImportedPrices> = [];
  public user: User = new User();
  public url = "backoffice/batch_price_table";
  public navigate_url = "backoffice/price_tables";
  public entityName = "Preços em lote";
  public file;
  public fileDescription = "Ou clique no botão abaixo para selecionar a partir do seu computador.";
  public type = "csv";
  public showLineErrors: boolean = false;
  public showCsvLineErrors: boolean = false;
  public is_carrier: boolean = true;

  public total = 0;
  public page = 1;
  public limit = 10;
  public sort = "created_at";
  public order = "desc";
  public search = "";
  public queryParam: boolean;

  constructor(
    public loginService: LoginService,
    public coreService: CoreService,
    public notificationService: NotificationService,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public acRoute: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.getRegisters();
  }

  public getRegisters(callback = null) {
    this.appComponent.load(true);
    this.apiService.get(this.url + this.queryParams()).subscribe((data: any) => {
      window["App"].init();
      const all_prices = data.data.map((up) => new ImportedPrices().deserialize(up));

      if (this.router.url.includes("carriers_prices")) {
        this.imported_prices = all_prices.filter((price) => price.is_carrier);
      } else {
        this.imported_prices = all_prices.filter((price) => !price.is_carrier);
      }
      this.total = data.total_records;

      this.imported_prices.map((price) => {
        if (price.response) {
          const response = JSON.parse(price.response);
          price.errors = response.errors.file.length > 0 ? response.errors.file[0] : "";
        }
        if (price.response && price.errors) {
          price.status = { name: "Erro", color: "danger" };
        } else {
          price.status = { name: price.processed ? "Processado" : "Pendente", color: price.processed ? "success" : "warning" };
        }
      });
      if (callback) {
        callback();
      }
      this.appComponent.load(false);
      window["tippy"]("a[data-tippy]");
    });
  }

  queryParams() {
    const pagination = `?per=${this.limit}&page=${this.page}&q%5Bs%5D=${this.sort}+${this.order}`;
    let full_params = pagination;
    if (this.sort && this.order) {
      full_params += `${pagination}&q%5Bs%5D=${this.sort}+${this.order}`;
    } else {
      full_params += `${pagination}&q%5Bs%5D=send_date+desc`;
    }
    if (this.search && this.search !== "" && this.queryParam) {
      full_params += `&q%5Bid_eq%5D=${this.search}`;
    }
    if (this.search && this.search !== "" && !this.queryParam) {
      full_params += `&q%5Bfile_name_cont%5D=${this.search}`;
    }

    return full_params;
  }

  searchFor(param: string) {}

  goToPage(data): void {
    this.page = data.page;
    this.limit = data.limit;
    this.getRegisters();
  }

  onNext(): void {
    this.page++;
    this.getRegisters();
  }

  onPrev(): void {
    this.page--;
    this.getRegisters();
  }

  goSort(sortParams): void {
    this.sort = sortParams.sort;
    this.order = sortParams.order === "asc" ? "desc" : "asc";
    this.getRegisters();
  }
}
