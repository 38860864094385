export class CarrierCorreiosIntegration {
    public id:number;
    
    public customer_id:number;
    public user:string;
    public password:string;
    public administrative_code:string;
    public contract:string;
    public card:string;
    public cnpj:string;
    active:boolean;

    public created_at:string;
    public updated_at:string;
    public errors:any = {}
  }
  