<form (submit)="onSubmit($event)">
  <app-modal target_name="customer-modal-account" type_modal="bg-warning" (on-modal-hide)="onSubmit($event)">
    <div header>
      <h3 class="modal-title text-light" id="exampleModalLabel">Conta - {{ customerFantasyName }}</h3>
    </div>
    <div body>
      <div class="form-group">
        <label for="customer-account-email">Email</label>
        <input #email name="email" [value]="customer.email" type="email" class="form-control" placeholder="Email de acesso ao sistema focco" />
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Senha</label>
        <input #password type="password" class="form-control" placeholder="Digite uma senha segura" />
      </div>
      <!-- TODO: fazer a implementação quando for refatorar o código -->
      <!-- <div class="form-group">
        <label for="exampleInputPassword1">Confirmar a senha</label>
        <input #passwordConfirmation type="password" class="form-control" placeholder="Confirme a senha" />
      </div> -->
    </div>
    <div footer>
      <div class="col-12"></div>
      <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Voltar</button>
      <!-- TODO: Refatorar essa lógica quando for refatorar o formulário -->
      <button [disabled]="inputEmail.nativeElement.value === customer.email && inputPassword.nativeElement.value === ''" type="submit" data-dismiss="modal" class="btn btn-primary btn-lg md-close">Salvar</button>
    </div>
  </app-modal>
</form>
