import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../../models/user';
import { LoginService } from '../auth/login/login.service';
import { AppComponent } from '../../../app.component';
import { ApiService } from '../../services/api.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-admin-customer-user-show',
  templateUrl: './customer-show-user.component.html',
  // styleUrls: ['./customer-show-user.component.css']
})

export class CustomerShowUserComponent implements OnInit {
  public attributes = [
    ['mdi mdi-pin-account', 'Primeiro Nome', 'first_name'], ['mdi mdi-pin-account', 'Último Nome', 'last_name'], ['mdi mdi-email', 'E-mail', 'email'], ['mdi mdi-check-square', 'Ativo', 'is_active_description'],
    ['mdi mdi-accounts-alt', 'Grupos', 'group_names'], ['mdi mdi-comment-more', 'Qtd. logins', 'sign_in_count'], ['mdi mdi-calendar-check', 'Login atual', 'current_sign_in_at'], ['mdi mdi-calendar-check', 'Último login', 'last_sign_in_at'],
    ['mdi mdi-comment-more', 'IP do login', 'current_sign_in_ip'], ['mdi mdi-comment-more', 'IP do último login', 'last_sign_in_ip'],
    ['mdi mdi-calendar-check', 'Data de criação', 'created_at'], ['mdi mdi-calendar-check', 'Data de atualização', 'updated_at']
  ];

  public user: User;
  customer_id;
  type;

  constructor(
              public loginService: LoginService,
              public appComponent: AppComponent,
              public apiService: ApiService,
              public router: Router,
              public acRoute: ActivatedRoute,
              public coreService: CoreService) { }

  ngOnInit() {
    this.acRoute.params.subscribe((data : any) => {
      if(data && data.id)
        this.loadUser(data);
      else
        this.user = new User();
    });
  }

  private loadUser(data){
    this.appComponent.load(true);
    this.type = this.loginService.getCurrentUser().loginType;
    let url;
    if(this.type == 'admin'){
      this.customer_id = data.customer_id;
      url = `admin/customers/${this.customer_id}/users/${data.id}`;
    }else{
      this.customer_id = this.loginService.getCurrentUser().customer_id;
      url = `backoffice/users/${data.id}`;
    }
    
    this.apiService.get(url).subscribe((data : User) => {
      this.user = data;
      this.coreService.setDateTimeString(this.user, 'last_sign_in_at');
      this.coreService.setDateTimeString(this.user, 'current_sign_in_at');
      this.coreService.setDateTimeString(this.user, 'created_at');
      this.coreService.setDateTimeString(this.user, 'updated_at');
      if(this.user.groups)
        this.user.group_names = this.user.groups.map((group) => group.name).join(', ');
      else
        this.user.group_names = '';
      this.user.is_active_description = this.user.is_active === true ? 'Sim' : 'Não'
      this.appComponent.load(false);
      window['tippy']('a[data-tippy]');
    });
  }
}
