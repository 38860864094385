import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { User } from "../../../../models/user";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { NotificationService } from "../../../messages/notification.service";
import { AppComponent } from "../../../../app.component";

@Component({
  selector: "app-auth-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  public user: any;
  public backofficeLogin: boolean;
  @ViewChild("emailInput", { static: true }) emailInput: ElementRef;

  constructor(private appComponent: AppComponent, public apiService: ApiService, public activatedRoute: ActivatedRoute, public router: Router, public notificationService: NotificationService) {}

  ngOnInit() {
    this.backofficeLogin = this.router.url.includes("backoffice");

    if (this.backofficeLogin) {
      this.user = new User();
    }

    setTimeout(() => this.emailInput.nativeElement.focus(), 0);
  }

  onSubmit() {
    if (this.user.email) {
      const url = "backoffice/users/password";
      this.appComponent.load(true);
      this.apiService.post(url, { email: this.user.email }).subscribe(
        (data: any) => {
          this.notificationService.notify(data.success, "success");
          this.appComponent.load(false);
          this.router.navigateByUrl("backoffice/login");
        },
        (data) => {
          this.user.email = "";
          this.notificationService.notify(data.error.error, "danger");
          this.appComponent.load(false);
        }
      );
    }
  }

  toLogin() {
    this.router.navigateByUrl("backoffice/login");
  }
}
