<app-modal expanded="true" target_name="customer-modal-brands" (on-modal-show)="onGetBrands($event)" (on-modal-hide)="onCleanBrands($event)">
  <div header>
    <h3 class="modal-title text-light" id="exampleModalLabel">Marcas - {{ customer_fantasy_name }}</h3>
  </div>
  <!-- <div body>Eu sou um corpo</div> -->
  <div body>
    <div class="card-header pt-0">
      <div *ngIf="viewAction" class="dropdown page-head-title">
        <button class="btn btn-primary btn-xl dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a *ngIf="selectBrandIds.desactive.length > 0" class="dropdown-item" (click)="onMultipleStateActiveBrands('desactive')">Inativar marcas selecionadas</a>
          <a *ngIf="selectBrandIds.active.length > 0" class="dropdown-item" (click)="onMultipleStateActiveBrands('active')">Ativar marcas selecionadas</a>
        </div>
      </div>
      <h2 class="page-head-button">
        <div class="form-search">
          <div class="input-group mb-3">
            <input [value]="searchBrand" (change)="searchBrand = $event.target.value" type="text" placeholder="id ou nome da marca" class="form-control" />
            <div class="input-group-append">
              <button type="button" class="btn btn-primary" (click)="onSearch($event)">Buscar</button>
            </div>
          </div>
          <span class="card-subtitle">Para pesquisar mais de um valor separe por ,</span>
        </div>
      </h2>
    </div>
    <!-- Bora colocar a tabela aqui -->
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="">
            <input #inputSelectAll (change)="onToggleBrandsSelects($event)" type="checkbox" name="check-brand-ids" class="brands" />
          </th>
          <th class="sort" (click)="this.onSortColumn('id')">
            ID
            <span class="glyphicon sort-icon" *ngIf="order.column === 'id' && order.type_order === 'asc'">↓</span>
            <span class="glyphicon sort-icon" *ngIf="order.column === 'id' && order.type_order === 'desc'">↑</span>
          </th>
          <th class="sort" (click)="this.onSortColumn('name')">
            nome
            <span class="glyphicon sort-icon" *ngIf="order.column === 'name' && order.type_order == 'asc'">↓</span>
            <span class="glyphicon sort-icon" *ngIf="order.column === 'name' && order.type_order == 'desc'">↑</span>
          </th>
          <th class="sort" (click)="this.onSortColumn('identifier')">
            Identificador
            <span class="glyphicon sort-icon" *ngIf="order.column === 'identifier' && order.type_order == 'asc'">↓</span>
            <span class="glyphicon sort-icon" *ngIf="order.column === 'identifier' && order.type_order == 'desc'">↑</span>
          </th>
          <th class="sort" (click)="this.onSortColumn('is_main')">
            Padrão?
            <span class="glyphicon sort-icon" *ngIf="order.column === 'is_main' && order.type_order == 'asc'">↓</span>
            <span class="glyphicon sort-icon" *ngIf="order.column === 'is_main' && order.type_order == 'desc'">↑</span>
          </th>
          <th class="sort" (click)="this.onSortColumn('is_active')">
            Situação
            <span class="glyphicon sort-icon" *ngIf="order.column === 'is_active' && order.type_order == 'asc'">↓</span>
            <span class="glyphicon sort-icon" *ngIf="order.column === 'is_active' && order.type_order == 'desc'">↑</span>
          </th>
          <th class="actions"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let brand of brands">
          <td>
            <input *ngIf="!brand.is_main" (change)="onToggleSelecrBrand($event, brand.is_active ? 'desactive' : 'active')" [checked]="selectBrandAll" type="checkbox" name="input-brands" class="brands" [value]="brand?.id" />
          </td>
          <td>{{ brand.id }}</td>
          <td>{{ brand.name }}</td>
          <td>{{ brand.slug }}</td>
          <td>{{ brand.is_main ? "Sim" : "Não" }}</td>
          <td>{{ brand.is_active ? "Ativo" : "Desativado" }}</td>
          <td class="actions">
            <a data-tippy (click)="onShowBrands(brand.id)" data-dismiss="modal" title="Visualizar" class="icon"><i class="mdi mdi-eye text-info"></i></a>
            <a data-tippy (click)="onEditBrands(brand.id)" data-dismiss="modal" title="Editar" class="icon"><i class="mdi mdi-edit text-info"></i></a>
            <a *ngIf="brand.is_active && !brand.is_main" data-tippy (click)="onStateOperationBrand(brand.id, brand.is_active ? 'desactive' : 'active')" title="Inativar" class="icon"><i class="fas fa-window-close text-danger"></i></a>
            <a *ngIf="!brand.is_active && !brand.is_main" data-tippy (click)="onStateOperationBrand(brand.id, brand.is_active ? 'desactive' : 'active')" title="Ativar" class="icon"><i class="fas fa-check-square text-success"></i></a>
            <a *ngIf="brand.is_main" id="brand-default" data-tippy title="" class="icon"><i class="fas fa-check-square"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination *ngIf="page.total_records > 0" (goPage)="onGoPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="3" [page]="page.current_page" [perPage]="page.limit_value" [count]="page.total_records ? page.total_records : 0">
    </app-pagination>
  </div>
  <div footer>
    <div class="col-12"></div>
    <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Voltar</button>
  </div>
</app-modal>
