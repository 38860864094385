import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../../../../app.component";
import { NotificationService } from "../../../../core/messages/notification.service";
import { ApiService } from "../../../../core/services/api.service";
import { Carrier } from "../../../../models/carrier";
import { Post } from "../../../../models/post";

@Component({
  selector: "focco-index-manual-resend-post-change-carrier",
  templateUrl: "./index-manual-resend-post-change-carrier.component.html",
})
export class IndexManualResendPostChangeCarrierComponent implements OnInit {
  @Input() post_id: string;
  public post: Post = new Post();
  public carriers: Array<Carrier> = [];

  public carriers_path = "admin/carriers";

  public integration_carriers: Array<any> = [];
  public target_carrier_id: number;
  public bypass_posting_date: boolean;

  constructor(
    public appComponent: AppComponent,
    public apiService: ApiService,
    public notificationService: NotificationService,
    public router: Router
  ) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.loadPost();
    this.loadCarriers();
    this.appComponent.load(false);
  }

  loadPost() {
    this.apiService.get(`admin/posts/${this.post_id}`).subscribe(async (data: any) => {
      await this.integrationsCarriers(data.brand_id);
      this.post = new Post().deserialize(data);
    });
  }

  private async integrationsCarriers(brand_id) {
    this.integration_carriers = [];
    let carriers = [];
    const result = await new Promise((resolve, reject) => {
      this.apiService.get(`admin/list_carriers_by_brand?id=${brand_id}`).subscribe(async (data: any) => {
        this.integration_carriers = data;
        this.appComponent.load(false);
        window["tippy"]("a[data-tippy]");
        resolve(this.integration_carriers);
      });
    });

    this.carriers.forEach((carrier) => {
      this.integration_carriers.forEach((integration_carrier) => {
        carrier.slug === integration_carrier.custom_identifier.toLowerCase() && carriers.push(carrier);
      });
    });
    this.carriers = carriers;
  }

  private async loadCarriers() {
    this.carriers = [];
    const result = await new Promise((resolve, reject) => {
      this.apiService.get(this.carriers_path).subscribe((data: any) => {
        this.carriers = data.carriers;
        this.appComponent.load(false);
        window["tippy"]("a[data-tippy]");
        resolve(this.carriers);
      });
    });
  }

  onChange($event) {
    const e = $event.target;
    const value = e.options[e.selectedIndex].value;

    this.target_carrier_id = value;
  }

  ByPassPostingData(value) {
    this.bypass_posting_date = value;
  }

  onSubmit() {
    const url = `admin/posts/${this.post_id}/change_carrier?target_carrier_id=${this.target_carrier_id}&bypass_posting_date=${this.bypass_posting_date}`;
    this.appComponent.load(true);
    this.apiService.get(url).subscribe(
      (success) => {
        this.notificationService.notify(`Nova transportadora selecionada com sucesso.`, "success");
        this.appComponent.load(false);
        window["$"]("#rejected-post-change-carrier").modal("hide");
      },
      (errors) => {
        this.notificationService.notify(`Erro ao selecionar nova transportadora.`, "danger");
        this.appComponent.load(false);
      }
    );
  }
}
