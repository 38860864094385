<div id="refused_posts_report" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title">Relatório de Pedidos Recusados</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <h4>Selecione o periodo do relatório</h4>
      </div>
      <form (ngSubmit)="onSubmit($event)" novalidate>
        <div class="modal-body pt-0">
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Datas</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row">
                  <input type="date" width="170" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.date_ini"
                    placeholder="Data Inicial" name="date_ini" class="form-control input-form-date">
                  <input type="date" width="170" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.date_end"
                    placeholder="Data Final" name="date_end" class="form-control input-form-date">
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Formato de<br>Visualização</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row box-radios">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="radio_info_format" (change)="check_csv($event)" checked="true"
                      class="custom-control-input" value="1"><span class="custom-control-label">E-mail</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="radio_info_format" (change)="check_download($event)"
                      class="custom-control-input" value="0"><span class="custom-control-label">Download</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
        </div>
      </form>
    </div>
  </div>
</div>
