export class ManualPostStatus {
  public id:number;

  public name:string;
  public color:string;

  public created_at:string;
  public updated_at:string;
  public errors:any = {}
}
