<div id="financial-report-admin" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title">Relatório de Sistema Delivery</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <h4>Selecione os campos do seu relatório</h4>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body pt-0">
          <div class="form-group row pt-1">
            <div class="col-12 d-flex mt-3">
              <label class="col-form-label text-sm-right label-first">Clientes</label>
              <div class="col-6 box-select">
                <!-- deve ser um multiselect -->
                <select id="customers_select" class="form-control" (change)="customerSelect($event)">
                  <option [value]=""></option>
                  <option *ngFor="let c of customers" [value]="c.id" attribute="customer_ids" name="customer_ids">
                    {{ c.fantasy_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex col-12">
              <input class="mt-2 mr-2" id="all-brands-checkbox-sfs" type="checkbox" name="allBrands" (change)="toggleAllBrands()" checked />
              <label class="col-form-label text-sm-right label-first">Todas as Marcas</label>
            </div>
            <div class="col-12 d-flex mt-3">
              <label class="col-form-label text-sm-right label-first">Marca</label>
              <div class="col-6 box-select">
                <!-- deve ser um multiselect -->
                <select id="brands_select" class="form-control" (change)="customerSelect($event)">
                  <option [value]=""></option>
                  <option *ngFor="let b of brands" [value]="b.id" attribute="brand_ids" name="brand_ids">
                    {{ b.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Datas</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row">
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_ini" placeholder="Data Inicial" name="date_ini" class="form-control input-form-date" />
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_end" placeholder="Data Final" name="date_end" class="form-control input-form-date" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row pt-1">
            <div class="col-12">
              <label class="col-form-label text-sm-right label-first">Gerado via</label>
              <div class="col-12 col-sm-9 box-select">
                <select-input *ngIf="this.original_history_options" [options]="this.original_history_options" [object]="this.history" attribute="event_names" [selectAll]="true"></select-input>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
        </div>
      </form>
    </div>
  </div>
</div>
