import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { ApiService } from '../../services/api.service';
import { CoreService } from '../../services/core.service';
import { LoginService } from '../auth/login/login.service';

@Component({
  selector: 'app-admin-customer-show-customer-users',
  templateUrl: './customer-users.component.html',
  // styleUrls: ['./customer-users.component.css']
})

export class CustomerUsersComponent implements OnInit {
  @Input() type: any;
  @Input() customer_id: any;
  rows = [];
  public total = 0;
  public page = 1;
  public limit = 5;
  public sort;
  public order;
  public search = '';
  loginType;

  constructor(private loginService: LoginService, private appComponent: AppComponent, private apiService: ApiService, private coreService: CoreService){}

  ngOnInit(){
    this.appComponent.load(true);
    this.getRegisters();
  }

  getRegisters(){
    this.loginType = this.loginService.getCurrentUser().loginType;
    let url;
    if(this.loginType == 'admin'){
      url = `admin/customers/${this.customer_id}/users/${this.type}_users${this.queryParams()}`;
    }else{
      this.customer_id = this.loginService.getCurrentUser().customer_id;
      url = `backoffice/dashboard/${this.type}_users${this.queryParams()}`;
    }
    
    this.apiService.get(url).subscribe((data) => {
      this.rows = data[`user_${this.type}`];
      this.total = data['total_records'];
      for(let i = 0; i < this.rows.length; i++)
        this.coreService.setDateTimeString(this.rows[i], 'last_sign_in_at');
      this.appComponent.load(false);
      window['tippy']('a[data-tippy]');
    });
  }

  queryParams() {
    return `?per=${this.limit}&page=${this.page}`;
  }

  goToPage(data): void {
    this.page = data.page;
    this.limit = data.limit;
    this.getRegisters();
  }

  onNext(): void {
    this.page++;
    this.getRegisters();
  }

  onPrev(): void {
    this.page--;
    this.getRegisters();
  }

  user_url(row){
    if(this.loginType == 'admin')
      return `/admin/customers/${this.customer_id}/users/${row.id}`;
    else
      return `/backoffice/users/${row.id}`;
  }
}