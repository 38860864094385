import { NgModule, CSP_NONCE } from "@angular/core";
import { DatePipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, PreloadAllModules } from "@angular/router";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CountdownModule } from "ngx-countdown";

import { ROUTES } from "./app.routes";
import { AppComponent } from "./app.component";
import { NgxLoadingModule } from "ngx-loading";
import { ApiService } from "./core/services/api.service";
import { CoreModule } from "./core/core.module";
import { TrackingsModule } from "./trackings/trackings.module";
import { NgxEnvModule, NgxEnvService } from "@ngx-env/core";

const nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules }),
    NgxLoadingModule,
    NgMultiSelectDropDownModule.forRoot(),
    CountdownModule,
    CoreModule.forRoot(),
    TrackingsModule,
    NgxEnvModule,
  ],
  providers: [
    // DatePipe adicionado para coletar informação em Data e Horário
    DatePipe,
    NgxLoadingModule,
    ApiService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: CSP_NONCE, useValue: nonce },
    NgxEnvService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
