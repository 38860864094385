export class DownloadReport {
  public id: number;
  public file: string;
  public filename: string;
  public created_at: string;
  public updated_at: string;

  public users_id: number;

  public errors: any = {};
  public error: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
