import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { AppComponent } from "../../../app.component";
import { CustomerService } from "../shared/customer.service";
import { Brand } from "../../../models/brand";
import { NotificationService } from "../../../core/messages/notification.service";
import { Page } from "../../../admin/shared/models/page/page";
import { IOrder } from "../../../admin/shared/models/order/IOrder";

@Component({
  selector: "app-customer-modal-brands",
  templateUrl: "./customer-modal-brands.component.html",
  styleUrls: ["./customer-modal-brands.component.css"],
})
export class CustomerModalBrandsComponent implements OnInit {
  @Input() customer_id: string;
  @Input() customer_fantasy_name: string;

  @ViewChild("selectBrands") selectBrands: ElementRef;
  @ViewChild("inputSelectAll", { static: true }) inputSelectAll: ElementRef;
  searchBrand: string = "";

  brands: Array<Brand> = [];
  page = new Page();
  order: IOrder = { column: "id", type_order: "asc" };

  selectBrandAll: boolean = false;
  selectBrandIds = {
    active: [],
    desactive: [],
  };

  viewAction: boolean = false;

  constructor(
    public appComponent: AppComponent,
    public customerBrandService: CustomerService,
    public notificationService: NotificationService,
    public router: Router
  ) {}

  ngOnInit() {}

  ngDoCheck() {
    // Necessário para mostrar o botão de ação em série
    this.viewAction = this.selectBrandAll || this.selectBrandIds.active.length > 0 || this.selectBrandIds.desactive.length > 0;
  }

  // Obter a lista das marcas
  onGetBrands(event = null) {
    this.appComponent.load(true);

    let params: any = { customerId: this.customer_id, page: this.page, order: this.toOrderParams() };

    if (!!event && event.brandParams) params = { brandParams: event.brandParams, ...params };

    this.customerBrandService.getBrands(params).subscribe((success: any) => {
      this.onCleanBrands(null, false);
      success.brands.forEach((brand) => this.brands.push(new Brand().deserialize(brand)));
      this.page = success.pagination;
      this.appComponent.load(false);
    });
  }

  // Limpar a lista das marcas
  onCleanBrands(event = null, statePage = true) {
    if (statePage) this.resetPagination();
    if (this.selectBrandAll || this.selectBrandIds.active.length > 0 || this.selectBrandIds.desactive.length > 0) this.resetCheckbox();

    this.brands = [];
  }

  // Selecionar todos os checkbox
  onToggleBrandsSelects({ target }) {
    this.selectBrandAll = target.checked;

    this.selectBrandIds.active = this.selectBrandAll
      ? this.brands.filter((brand) => !brand.is_active && !brand.is_main).map((brand) => brand.id.toString())
      : [];
    this.selectBrandIds.desactive = this.selectBrandAll
      ? this.brands.filter((brand) => brand.is_active && !brand.is_main).map((brand) => brand.id.toString())
      : [];
  }

  // Adiciona o id da brand na liosta de seleção de brands
  onToggleSelecrBrand({ target }, state) {
    if (target.checked) this.selectBrandIds[state].push(target.value);
    else this.selectBrandIds[state] = this.selectBrandIds[state].filter((brandId) => brandId !== target.value);
  }

  // Define a paginação das brands
  onGoPage({ page, limit }): void {
    this.page.current_page = page;
    if (limit !== this.page.limit_value) this.page.limit_value = parseInt(limit);
    this.onGetBrands();
  }

  // Avança para a próxima página
  onNext(): void {
    this.page.current_page += 1;
    this.onGetBrands();
  }

  // Retrocede para a pŕoxima página
  onPrev(): void {
    this.page.current_page -= 1;
    this.onGetBrands();
  }

  // realiza a bisca de id e nome das marcas
  onSearch({ target }) {
    if (this.searchBrand.length === 0) {
      this.onGetBrands();
      return;
    }

    const brandParams = this.searchBrand.indexOf(",")
      ? this.searchBrand
          .split(",")
          .map((s) => `q[id_or_name_in][]=${s.trim()}`)
          .join("&")
      : `q[id_or_name_in][]=${this.searchBrand.trim()}`;

    this.onGetBrands({ brandParams });
  }

  // Rediciona para visualização de uma marca
  onShowBrands(brandId: number | string) {
    this.router.navigateByUrl(`admin/brands/${brandId}`);
  }

  // Redireciona para a edição de uma marca
  onEditBrands(brandId: number | string) {
    this.router.navigateByUrl(`admin/brands/${brandId}/edit`);
  }

  // Ativa ou desativa uma marca
  onStateOperationBrand(brandId: string, state: string) {
    this.putStateActive([brandId], state);
  }

  onMultipleStateActiveBrands(state) {
    if (this.selectBrandIds[state].length > 0) this.putStateActive(this.selectBrandIds[state], state);
  }

  // Realiza a ordenação dos dados por coluna
  onSortColumn(columnName) {
    if (this.order.column !== columnName) {
      this.order = { column: columnName, type_order: "asc" };
    } else {
      this.order.type_order = this.order.type_order === "asc" ? "desc" : "asc";
    }
    this.onGetBrands();
  }

  // Retorna os dados para a ordenação
  private toOrderParams() {
    return `${this.order.column} ${this.order.type_order}`;
  }

  private putStateActive(brandIds, stateActive) {
    stateActive = stateActive === "active" ? true : false;
    this.appComponent.load(true);
    this.customerBrandService.putStateActiveBrands({ customerId: this.customer_id, brandIds, stateActive }).subscribe(({ message }) => {
      this.notificationService.notify(message, "success");
      this.appComponent.load(false);
      this.onGetBrands();
    });
  }

  private resetCheckbox() {
    this.selectBrandAll = false;
    this.inputSelectAll.nativeElement.checked = false;
    this.selectBrandIds.active = [];
    this.selectBrandIds.desactive = [];
  }

  private resetPagination() {
    this.page = new Page();
    this.order = { column: "id", type_order: "asc" };
  }
}
