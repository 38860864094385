import { Customer } from './customer'

export class CarrierFlashCourierFtpFpl {
    public id:number;

    address:string;
    username:string;
    password:string;
    import_folder:string;
    is_secure:boolean;

    public customer:Customer;
    public import_posts_config:boolean;

    public created_at:string;
    public updated_at:string;
    public errors:any = {}


  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.customer) {
      this.customer = new Customer().deserialize(input.customer);
    }
    return this;
  }
}
