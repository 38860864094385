import { Deserializable } from "./deserializable.model";

export class StatusTrackingGroup {
  public id: number;
  public color: string;
  public name: string;
  public customer_id: number;

  count_carrier_correios;
  count_carrier_flash_courier;
  carrier_correios;
  carrier_flash_courier;
  count_carrier_nobre;
  status_groups_report;
  brand_id;

  carriers_count;

  public created_at: string;
  public updated_at: string;
  public errors: any = {}

  grouping_ids = [];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
