import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../../../../app.component";
import { NotificationService } from "../../../../core/messages/notification.service";
import { ApiService } from "../../../../core/services/api.service";
import { CoreService } from "../../../../core/services/core.service";
import { Permission } from "../../../../models/permissions.";

@Component({
  selector: "permission-save",
  templateUrl: "./permission-save.component.html",
  styleUrls: ["./permission-save.component.css"],
})
export class PermissionSaveComponent implements OnInit {
  public permission: Permission = new Permission();
  public fields: Array<any> = [];
  public permissions: Array<any> = [];

  public category_name: string = "";
  public base_path: string = "admin/permissions";
  public navigate_path: string = "backoffice/users";

  constructor(
    public notificationService: NotificationService,
    public router: Router,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public coreService: CoreService
  ) {}

  ngOnInit() {
    this.allFields();
  }

  onSubmit() {
    this.appComponent.load(true);
    this.apiService.post(this.base_path, this.processParams()).subscribe(
      () => {
        this.permission = new Permission();
        this.notificationService.notify(`Categoria ${this.category_name} criada com sucesso.`, "success");
        this.router.navigateByUrl(this.navigate_path);
        this.appComponent.load(false);
        this.router.navigateByUrl("backoffice/users/permissions");
        this.uncheckAll();
        window["$"]("#permissions-save-modal").modal("hide");
      },
      (errors) => {
        this.permission.errors = errors.error;
        this.notificationService.notify(`Erro ao criar ${this.category_name.toLowerCase()}.`, "danger");
        this.appComponent.load(false);
      }
    );
  }

  processParams() {
    const params = { permission: {} };

    this.fields.forEach((field) => {
      params.permission[`${field.value}`] = field.checked;
    });

    params.permission["name"] = this.permission.name;
    return params;
  }

  public allFields() {
    this.fields = [
      { name: "Registro de cliente", value: "customer_register", checked: false },
      { name: "Registro de usuário", value: "user_register", checked: false },
      { name: "Registro de marcas", value: "brand_register", checked: false },
      { name: "Registro de franquia", value: "franchise_register", checked: false },
      { name: "Registro financeiro", value: "financial_register", checked: false },
      { name: "Relatórios", value: "reports", checked: false },
      { name: "Relatório financeiro", value: "financial_report", checked: false },
      { name: "Criação de postagem", value: "posts_create", checked: false },
      { name: "Edição de postagem", value: "posts_edit", checked: false },
      { name: "Múltiplos clientes", value: "multi_customer", checked: false },
      { name: "Edição das permissões", value: "permissions_edit", checked: false },
    ];
  }

  public checkAll($event) {
    const target = $event.target;
    this.fields.forEach((field) => {
      target.checked ? (field.checked = true) : (field.checked = false);
    });
  }

  public uncheckAll() {
    this.fields.forEach((field) => {
      field.checked = false;
    });

    this.permission.name = "";
  }
}
