import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { SharedModule } from "../shared/shared.module";
import { TrackingCardFindCodeComponent } from "./shared/components/tracking-card-find-code/tracking-card-find-code.component";
import { TrackingCardLoadingComponent } from "./shared/components/tracking-card-loading/tracking-card-loading.component";
import { TrackingIndexComponent } from "./tracking-index/tracking-index.component";
import { TrackingIsPresentPipe } from "./shared/pipes/tracking-is-present.pipe";
import { TrackingGoogleMapsUrlPipe } from "./shared/pipes/tracking-google-maps-url.pipe";
import { TrackingLocationDescriptionPipe } from "./shared/pipes/tracking-location-description.pipe";
import { TrackingService } from "./shared/services/trackings.service";
import { TrackingStatusCodeComponent } from "./shared/components/tracking-status-code/tracking-status-code.component";

const TRACKING_ROUTES: Routes = [{ path: "public/:subdomain", component: TrackingIndexComponent }];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(TRACKING_ROUTES), FormsModule, SharedModule],
  declarations: [
    TrackingIndexComponent,
    TrackingCardFindCodeComponent,
    TrackingCardLoadingComponent,
    TrackingStatusCodeComponent,
    TrackingLocationDescriptionPipe,
    TrackingGoogleMapsUrlPipe,
    TrackingIsPresentPipe,
  ],
  providers: [TrackingService],
})
export class TrackingsModule {}
