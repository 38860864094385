export class Possessor {

    id: number;
    possessor_name: string;
    possessor_document: string;
    possessor_signature: string;
    possessor_role: string;
    package_photo: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

