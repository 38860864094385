import { Franchise } from "./franchise";
import { Deserializable } from "./deserializable.model";

export class BrandFranchise implements Deserializable {
  public id: number;
  public brand_id: number;
  public franchise_id: number;
  public franchise_priority: number;

  public franchise: Franchise = new Franchise();

  public errors:any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.franchise) {
      this.franchise = new Franchise().deserialize(input.franchise);
    }
    return this;
  }
}
