export class PostError {
    public id:number;

    public customer_id:number;
    public manual_post_id:number;

    public client_document_number:number;
    public my_number:number;
    public parent_order:number;
    public dispatch_date:number;
    public carrier:number;
    public carrier_contract:number;
    public recipient_name:number;
    public recipient_address:number;
    public recipient_address_number:number;
    public recipient_address_complement:number;
    public recipient_address_neighborhood:number;
    public recipient_address_city:number;
    public recipient_address_state:number;
    public recipient_address_zipcode:number;
    public recipient_address_reference_point:number;
    public recipient_phone_1:number;
    public recipient_phone_2:number;
    public recipient_email:number;
    public cpf_cnpj:number;
    public ie:number;
    public line_errors:string;
    public recipient_department:number;
    public package_weight_gr:number;
    public package_width_cm:number;
    public package_length_cm:number;
    public package_height_cm:number;
    public invoice_series:number;
    public invoice:number;
    public invoice_value:number;
    public invoice_emission_date:number;
    public invoice_access_key:number;
    public products_value:number;
    public observation_1:number;
    public observation_2:number;
    public observation_3:number;
    public observation_4:number;
    public cd_code:number;
    public cd_cnpj:number;
    public cd_name:number;
    public cd_phone:number;
    public cd_email:number;
    public cd_address:number;
    public cd_address_number:number;
    public cd_address_complement:number;
    public cd_address_reference: number;
    public cd_address_zipcode:number;
    public cd_address_city:number;
    public cd_address_neighborhood:number;
    public cd_address_state:number;

    public payment_type:number;
    public payment_change:number;

    public carrier_product;
    public cost_center_id;

    public created_at:string;
    public updated_at:string;
    public errors:any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
  }
