import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Customer } from "../../../../models/customer";

@Component({
  selector: "refused_posts_report", // esse selector é chamado no main.component.html para aparecer o modal
  templateUrl: "./refused_posts_report.component.html",
  styleUrls: ["./refused_posts_report.component.css"],
})
export class RefusedPostReportComponent implements OnInit, AfterViewInit {
  multiple = true;
  titles = {};
  values = {};
  checkbox_csv = true;
  checkbox_download = null;
  url;
  originalOptions: Array<any>;
  customer = new Customer();

  date_now = new Date();
  default_date = String(this.date_now.getFullYear()) + "-" + String(this.date_now.getMonth() + 1).padStart(2, "0") + "-" + String(this.date_now.getDate()).padStart(2, "0");

  date_ini = this.default_date;
  date_end = this.default_date;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router, private http: HttpClient) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.apiService.get("backoffice/brands?q%5Bis_active_eq%5D=true&q%5Bs%5D=name+asc&per=5000").subscribe((data: any) => {
      this.customer = data.customer;
      if (this.customer.subdomain == "hubcard") {
      }
    });
    this.url = "backoffice/users/assigned_brands";
  }

  ngAfterViewInit() {
    // Esse callback desmarca todas as opções selecionadas após fechar a janela de formulário do relatório
    const scope = this;
    window["$"]("#ship_from_store_report").on("hidden.bs.modal", function (e) {
      scope.titles = {};
      scope.values = {};
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
      let uncheck_check_all = document.getElementById("check_all_sfs") as HTMLInputElement;
      uncheck_check_all.checked = false;
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  onSubmit(event) {
    event.preventDefault();
    const scope = this;
    const report_params = {
      date_ini: this.date_ini,
      date_end: this.date_end,
      customer_id: this.customer.id,
    };
    var download_options = {};

    if (this.convertStringToDate(this.date_end) < this.convertStringToDate(this.date_ini)) {
      this.notificationService.notify("Data inicial maior que data final", "danger");
    } else if (this.convertStringToDate(this.date_end) >= this.date_now) {
      this.notificationService.notify("Data final não pode ser maior que dia e horário atual", "danger");
    } else if (this.checkbox_csv) {
      // this.appComponent.load(true);
      this.apiService.post(`/backoffice/posts/refused_posts_report`, report_params).subscribe(
        (response) => {
          window["$"](".modal").modal("hide");
          this.notificationService.notify("O sistema está gerando a exportação em CSV." + " O relatório será enviado para o seu e-mail", "success");
          // this.appComponent.load(false);
        },
        (errors) => {
          this.notificationService.notify(`Erro ao gerar a exportação.`, "danger");
          this.appComponent.load(false);
        }
      );
    } else if (this.checkbox_download) {
      var strWindowFeatures = "location=yes,height=120,width=520,scrollbars=yes,status=yes";

      download_options = { date_ini: this.date_ini, date_end: this.date_end, customer_id: this.customer.id };

      window["$"](".modal").modal("hide");
      this.notificationService.notify("O sistema está gerando a exportação em CSV." + " Uma nova janela será aberta para completar o download.", "success");
    } else {
      this.notificationService.notify("Selecione o formato de visualização: Tela, CSV, Download.", "danger");
    }
  }

  check_download(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_download = true;
      this.checkbox_csv = false;
    }
  }

  check_csv(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_csv = true;
      this.checkbox_download = false;
    }
  }
}
