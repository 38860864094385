import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { NotificationService } from '../../../../core/messages/notification.service';
import { ApiService } from '../../../../core/services/api.service';
import { ManualPost } from '../../../../models/manual_post';
import { Possessor } from '../../../../models/possessor';
import { Post } from '../../../../models/post';

@Component({
  selector: 'app-possessor_details',
  templateUrl: './possessor_details.component.html',
  styleUrls: ['./possessor_details.component.css']
})
export class PossessorDetailsComponent implements OnInit {
  public manual_post: ManualPost = new ManualPost();
  @Input()  set_post
  manual_post_possessor: Possessor;
  post_possessor: Possessor;

  constructor(
    public acRoute: ActivatedRoute,
    public appComponent: AppComponent,
    public apiService: ApiService,
    public notificationService: NotificationService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      this.manual_post.id = data.manual_post_id;
      this.apiService.get('backoffice/delivery_posts/' + data.manual_post_id).subscribe((data: any) => {
        this.manual_post = data;
      });
    this.appComponent.load(false);
    });
   }

   ngAfterViewInit() {
    const scope = this;
    window['$']('#possessor-details').on('hidden.bs.modal', function () {
      scope.manual_post
      scope.set_post
    });
  }

}

