import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { TextInputComponent } from "./components/text-input/text-input.component";
import { TextInputFormGroupComponent } from "./components/text-input-form-group/text-input-form-group.component";
import { TextAreaInputComponent } from "./components/text-area-input/text-area-input.component";
import { CheckboxInputComponent } from "./components/checkbox-input/checkbox-input.component";
import { SelectInputComponent } from "./components/select-input/select-input.component";
import { ColumnSelectInputComponent } from "./components/column-select-input/column-select-input.component";
import { PaginationComponent } from "./components/pagination/pagination.component";

import { SnackbarComponent } from "./messages/snackbar/snackbar.component";
import { NotificationService } from "./messages/notification.service";

import { CoreService } from "./services/core.service";
import { CarrierService } from "./services/carrier.service";

import { LoginComponent } from "./components/auth/login/login.component";
import { ForgotPasswordComponent } from "./components/auth/forgot-password/forgot-password.component";
import { RecoverPasswordComponent } from "./components/auth/recover-password/recover-password.component";

import { AuditComponent } from "./components/audit/audit.component";
import { BackofficeMainComponent } from "../backoffice/main/main.component";

import { LoginService } from "./components/auth/login/login.service";
import { LoginGuard } from "./components/auth/login/login.guard";
import { LoginInterceptor } from "./components/auth/login/login.interceptor";

import { CarrierListComponent } from "./components/carriers/carrier-list/carrier-list.component";
import { CorreiosSaveComponent } from "./components/carriers/correios-save/correios-save.component";
import { FlashCourierSaveComponent } from "./components/carriers/flash_courier-save/flash_courier-save.component";
import { CorreiosTrackingSaveComponent } from "./components/carriers/correios-tracking-save/correios-tracking-save.component";
import { FlashCourierTrackingSaveComponent } from "./components/carriers/flash_courier-tracking-save/flash_courier-tracking-save.component";
import { FlashCourierFtpComponent } from "./components/carriers/flash_courier_ftp/flash_courier_ftp.component";
import { CustomerPostReportComponent } from "./components/customer-post-report/customer-post-report.component";

import { CustomerUsersComponent } from "../core/components/customer-users/customer-users.component";
import { PostedVolumeReportComponent } from "../backoffice/posts/manual-posts/posted-volume-report/posted-volume-report.component";
import { ShipFromStoreReportComponent } from "../backoffice/posts/manual-posts/ship_from_store_report/ship_from_store_report.component";
import { FinancialReportComponent } from "../backoffice/posts/manual-posts/financial-report/financial-report.component";
import { RefusedPostReportComponent } from "../backoffice/posts/manual-posts/refused_posts_report/refused_posts_report.component";
import { ShipFromStoreChargeReportComponent } from "../backoffice/posts/manual-posts/ship_from_store_charge_report/ship_from_store_charge_report.component";
import { PostedVolumeListComponent } from "../backoffice/posts/manual-posts/posted-volume-list/posted-volume-list.component";
import { NobreSaveComponent } from "./components/carriers/nobre-save/nobre-save.component";
import { IntegrationSaveComponent } from "./components/carriers/integration-save/integration-save.component";
import { FinancialReportAdminComponent } from "../backoffice/posts/financial-report/financial-report-admin.component";

import { CountCharactersComponent } from "./components/characters_counter/count_characters.component";
import { PossessorDetailsComponent } from "../backoffice/delivery_posts/show/possessor_details/possessor_details.component";

import { IndexManualResendPostChangeCarrierComponent } from "../backoffice/admin_posts/index-manual-resend-post/index-manual-resend-post-change-carrier/index-manual-resend-post-change-carrier.component";
import { IndexManualResendPostEditComponent } from "../backoffice/admin_posts/index-manual-resend-post/index-manual-resend-post-edit/index-manual-resend-post-edit.component";
import { RejectedAdminPostEditComponent } from "../backoffice/admin_posts/rejected-posts/rejected-admin-post-edit/rejected-admin-post-edit.component";

import { CustomerShowUserComponent } from "./components/customer-show-user/customer-show-user.component";
import { CustomerReportComponent } from "../backoffice/customers/customer-report/customer-report.component";
import { SelectStatesComponent } from "./components/select-states/select-states.component";

import { ContractComponent } from "../backoffice/customers_prices/contract/contract.component";
import { CarrierContractComponent } from "../backoffice/carriers_prices/contract/carrier-contract.component";

import { BrandIntegrationComponent } from "../backoffice/brands/brand-integration/brand-integration.component";
import { CustomerModalAccountComponent } from "../backoffice/customers/customer-modal-account/customer-modal-account.component";
import { ModalComponent } from "../backoffice/shared/components/modal/modal.component";
import { CustomerModalBrandsComponent } from "../backoffice/customers/customer-modal-brands/customer-modal-brands.component";
import { CustomerModalStateActiveComponent } from "../backoffice/customers/customer-modal-state-active/customer-modal-state-active.component";
import { BrandModalDesactive } from "../backoffice/brands/brand-modal-desactive/brand-modal-desactive.component";
import { PermissionSaveComponent } from "../backoffice/users/permissions/permission-save/permission-save.component";
import { CarriersReportComponent } from "../backoffice/posts/manual-posts/carriers-report/carriers-report.component";

import { ImportPricesComponent } from "./components/import-prices/import-prices.component";
import { ImportPricesIndexComponent } from "./components/import-prices/import-prices-index/import-prices-index.component";

import { DownloadReportsComponent } from "../backoffice/posts/download-reports/download-reports.component";
@NgModule({
  imports: [CommonModule, FormsModule, NgMultiSelectDropDownModule, SweetAlert2Module.forRoot(), RouterModule],
  declarations: [
    TextInputComponent,
    TextInputFormGroupComponent,
    TextAreaInputComponent,
    CheckboxInputComponent,
    SelectInputComponent,
    ColumnSelectInputComponent,
    PaginationComponent,
    SnackbarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    AuditComponent,
    BackofficeMainComponent,
    CarrierListComponent,
    CorreiosSaveComponent,
    CorreiosTrackingSaveComponent,
    NobreSaveComponent,
    IntegrationSaveComponent,
    FlashCourierTrackingSaveComponent,
    FlashCourierFtpComponent,
    FlashCourierSaveComponent,
    CustomerPostReportComponent,
    CustomerUsersComponent,
    PostedVolumeReportComponent,
    PossessorDetailsComponent,
    PostedVolumeListComponent,
    ShipFromStoreReportComponent,
    FinancialReportComponent,
    FinancialReportAdminComponent,
    RefusedPostReportComponent,
    ShipFromStoreChargeReportComponent,
    CountCharactersComponent,
    IndexManualResendPostEditComponent,
    RejectedAdminPostEditComponent,
    CustomerReportComponent,
    SelectStatesComponent,
    ContractComponent,
    CarrierContractComponent,
    BrandIntegrationComponent,
    CustomerShowUserComponent,
    CustomerModalAccountComponent,
    ModalComponent,
    CustomerModalBrandsComponent,
    CustomerModalStateActiveComponent,
    BrandModalDesactive,
    PermissionSaveComponent,
    IndexManualResendPostChangeCarrierComponent,
    CarriersReportComponent,
    ImportPricesComponent,
    ImportPricesIndexComponent,
    DownloadReportsComponent,
  ],
  exports: [
    TextInputComponent,
    TextInputFormGroupComponent,
    TextAreaInputComponent,
    CheckboxInputComponent,
    SelectInputComponent,
    ColumnSelectInputComponent,
    PaginationComponent,
    SnackbarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    SweetAlert2Module,
    NgMultiSelectDropDownModule,
    AuditComponent,
    BackofficeMainComponent,
    CarrierListComponent,
    CorreiosSaveComponent,
    IntegrationSaveComponent,
    CorreiosTrackingSaveComponent,
    FlashCourierTrackingSaveComponent,
    FlashCourierFtpComponent,
    FlashCourierSaveComponent,
    CustomerPostReportComponent,
    CustomerUsersComponent,
    PostedVolumeReportComponent,
    PossessorDetailsComponent,
    PostedVolumeListComponent,
    ShipFromStoreReportComponent,
    FinancialReportComponent,
    FinancialReportAdminComponent,
    RefusedPostReportComponent,
    CountCharactersComponent,
    IndexManualResendPostEditComponent,
    RejectedAdminPostEditComponent,
    CustomerReportComponent,
    SelectStatesComponent,
    ContractComponent,
    CarrierContractComponent,
    BrandIntegrationComponent,
    CustomerShowUserComponent,
    CustomerModalAccountComponent,
    ModalComponent,
    CustomerModalBrandsComponent,
    CustomerModalStateActiveComponent,
    BrandModalDesactive,
    PermissionSaveComponent,
    IndexManualResendPostChangeCarrierComponent,
    CarriersReportComponent,
    ImportPricesComponent,
    ImportPricesIndexComponent,
    DownloadReportsComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [NotificationService, LoginService, LoginGuard, CoreService, CarrierService, { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true }],
    };
  }
}
