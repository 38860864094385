<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item"><a routerLink="/backoffice">Relatórios</a></li>
          <li *ngIf="report_format == 'posted_volume_report'" class="breadcrumb-item active">Volume Postado</li>
          <li *ngIf="report_format == 'sfs'" class="breadcrumb-item active">Sistema Delivery</li>
        </ol>
      </nav>
      <h2 *ngIf="report_format == 'posted_volume_report'" class="page-head-title">Relatório de Volume Postado</h2>
      <h2 *ngIf="report_format == 'sfs'" class="page-head-title">Relatório de Sistema Delivery</h2>
      <h2 class="page-head-button">
        <button routerLink="/backoffice" class="btn btn-secondary">Voltar</button>
      </h2>
    </div>

    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-table">
            <div class="card-header">
              Marca(s):
              <span> {{ brand_names }} </span>
              - Período
              <span> {{ date_ini_translated | date : "dd/MM/y" }} </span> à
              <span> {{ date_end_translated | date : "dd/MM/y" }} </span>
              <h2 class="page-head-button">
                <form class="input-group mb-3" (ngSubmit)="getRegisters()">
                  <input type="text" [ngModelOptions]="{ standalone: true }" [(ngModel)]="search" placeholder="Buscar item" class="form-control" />
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="search = null; getRegisters()"><i [ngStyle]="{ visibility: search ? 'visible' : 'hidden' }" class="mdi mdi-close-circle"></i></span>
                    <button type="submit" class="btn btn-primary">Buscar</button>
                  </div>
                </form>
              </h2>
            </div>
            <div class="card-body p-4">
              <p *ngIf="posts.length == 0">Nenhum registro encontrado.</p>
              <div class="table-responsive" *ngIf="posts.length > 0">
                <table class="table table-sm table-hover table-bordered table-striped posts-table">
                  <thead>
                    <tr>
                      <th *ngIf="report_format === 'sfs'">Status do Pedido</th>
                      <th *ngIf="report_format === 'posted_volume_report'">Status de Rastreio</th>
                      <th>Código de Rastreio</th>
                      <th *ngFor="let column of columns">{{ column.label }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let post of posts">
                      <td *ngIf="report_format == 'sfs'" class="sended-posts-line">
                        <span class="badge {{ post.last_tracking_status ? 'badge-primary' : 'badge-new-post' }}" [style.background]="post.tracking_status_color">{{ post.last_tracking_status_post_status_name }}</span>
                      </td>
                      <td *ngIf="report_format == 'posted_volume_report'" class="sended-posts-line">
                        <span class="badge {{ post.last_tracking_status ? 'badge-primary' : 'badge-new-post' }}" [style.background]="post.tracking_status_color">{{ post.last_tracking_status || "Novo" }}</span>
                      </td>
                      <td>
                        <a routerLink="/backoffice/posts/{{ post.tracking_code }}">{{ post.tracking_code }}</a>
                      </td>
                      <td *ngFor="let column of columns">{{ post[column.attribute] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-pagination *ngIf="posts.length > 0" (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="6" [page]="page" [perPage]="limit" [count]="total"> </app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
