import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { CarrierFlashCourierConfig } from "../../models/carrier_flash_courier_config";
import { CarrierCorreiosConfig } from "../../models/carrier_correios_config";
import { AppComponent } from "../../app.component";
import { CarrierNobreConfig } from "../../models/carrier_nobre_config";
import { CarrierIntegration } from "../../models/carrier_integration";
import { Carrier } from "../../models/carrier";

@Injectable()
export class CarrierService {
  carrier_flash_courier_configs = new CarrierFlashCourierConfig();
  carrier_correios_configs = new CarrierCorreiosConfig();
  carrier_nobre_configs = new CarrierNobreConfig();
  carrier_integration = new CarrierIntegration();
  carriers = new Carrier();

  constructor(public apiService: ApiService) {}

  getCarrierConfigs(scope) {
    this.apiService.get("backoffice/carrier_correios_configs").subscribe((carrier_correios_config: CarrierCorreiosConfig) => {
      scope.carrier_correios_configs = carrier_correios_config;
      this.apiService.get("backoffice/carrier_flash_courier_configs").subscribe((carrier_flash_courier_config: CarrierFlashCourierConfig) => {
        scope.carrier_flash_courier_configs = carrier_flash_courier_config;
        this.apiService.get("backoffice/carrier_nobre_configs").subscribe((carrier_nobre_config: CarrierNobreConfig) => {
          scope.carrier_nobre_configs = carrier_nobre_config;
          this.apiService.get("backoffice/carriers").subscribe((data: any) => {
            scope.carriers = data.carriers;
            scope.appComponent.load(false);
          });
        });
      });
    });
  }

  getCarrier(scope) {
    this.apiService.get(`backoffice/carriers/${scope.carrier_id}`).subscribe((data: any) => {
      Object.assign(scope.carrier, data);
      Object.assign(scope.carrier_integration, data.current_customer_integration);
      scope.appComponent.load(false);
    });
  }

  getAdminCarriers(scope) {
    this.apiService.get("admin/carriers").subscribe((data: any) => {
      scope.carriers = data.carriers;
      scope.appComponent.load(false);
    });
  }
}
