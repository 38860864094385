export class CarrierCorreiosTrackingIntegration {
  public id:number;
  
  public customer_id:number;
  public user:string;
  public password:string;
  public delay_days:number = 0;
  active:boolean;

  public created_at:string;
  public updated_at:string;
  public errors:any = {}
}
