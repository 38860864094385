import { Deserializable } from "./deserializable.model";
import { User } from "./user";

export class TrackingUpdateLog implements Deserializable {
  public id: number;
  public user: User;
  public user_name: string;
  public old_client_document_number: string;
  public old_tracking_code: string;
  public old_my_number: string;
  public old_num_enc_ter: string;
  public new_client_document_number: string;
  public new_tracking_code: string;
  public new_my_number: string;
  public new_num_enc_ter: string;
  public created_at: string;
  public updated_at: string;
  public errors:any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.user) {
      this.user = new User().deserialize(input.user);
    }
    return this;
  }
}
