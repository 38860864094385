<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <h2 class="page-head-title">Visualizar Usuário</h2>
      <h2 class="page-head-button">
        <a data-tippy title="Voltar" routerLink="/backoffice/customers/{{ customer_id }}" class="btn btn-space btn-secondary">Voltar</a>
      </h2>
    </div>
    <nav class="container-fab">
      <a class="fab back" data-tippy title="Voltar" routerLink="/backoffice/customers/{{ customer_id }}"></a>
    </nav>
    <!--Fim Conteudo titulo-->
    <div class="main-content container-fluid">
      <div class="user-profile">
        <div class="row">
          <div class="col-lg-12">
            <div class="user-info-list card">
              <div class="card-header card-header-divider" *ngIf="this.user">{{ this.user.first_name + " " + this.user.last_name }}</div>
              <div class="card-body">
                <table class="no-border no-strip skills" *ngIf="this.user">
                  <tbody class="no-border-x no-border-y">
                    <tr *ngFor="let attribute of attributes">
                      <td class="icon"><span [ngClass]="attribute[0]"></span></td>
                      <td class="item">{{ attribute[1] }}<span class="icon s7-portfolio"></span></td>
                      <td>{{ this.user[attribute[2]] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Body Conteudo-->
</section>
