import { CepRange } from "./cep_range";
import { Deserializable } from "./deserializable.model";

export class SubCarrier implements Deserializable {
  public id: number;
  public fantasy_name: string = "";
  public social_name: string;
  public cnpj: string;
  public ie: string;
  public email: string;
  public telephone: string;
  public cell_phone: string;
  public contact_name: string;
  public adress: string;
  public adress_number: string;
  public adress_complement: string;
  public adress_neighborhood: string;
  public adress_zipcode: string;
  public adress_state: string;
  public adress_city: string;
  public operation_since: string;
  public description: string;
  public is_active: boolean = true;
  public is_active_description: string;
  public identifier: string;
  public brands_total: number;
  public carrier_id;

  public cep_ranges: Array<CepRange>;
  has_flash_reverse: string;
  has_ship_reverse: string;

  users_active;
  users_inactive;
  post_by_months;
  last_sign_in;

  total_logs;
  success_logs;
  error_logs;
  not_found_logs;

  public created_at: string;
  public updated_at: string;
  public errors: any = {};

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.cep_ranges) {
      this.cep_ranges = input.cep_ranges.map((cep_range) => new CepRange().deserialize(cep_range));
    }
    return this;
  }
}
