import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"],
})
export class ModalComponent implements OnInit {
  @Input() type_modal: string = "bg-primary"; // use a class bg-color do bootstrap
  @Input() target_name: string; // define o id do modal
  @Input() expanded: boolean = false; // Define se o component será mais largo

  @Output("on-modal-show") onModalShow = new EventEmitter(); // Quando for exibido o modal
  @Output("on-modal-hide") onModalHide = new EventEmitter(); // Quando for fechado o modal

  @ViewChild("modal", { static: true }) modal: ElementRef;
  @ViewChild("modalDialog", { static: true }) modalDialog: ElementRef;
  @ViewChild("modalContent", { static: true }) modalContent: ElementRef;
  @ViewChild("modalHeader", { static: true }) modalHeader: ElementRef;

  constructor() {}

  ngOnInit() {
    this.modal.nativeElement.id = this.target_name;

    if (this.expanded) {
      this.modalDialog.nativeElement.classList.add("modal-expanded-dialog");
      this.modalContent.nativeElement.classList.add("modal-expanded-content");
    }

    this.modalHeader.nativeElement.classList.add(this.type_modal);

    window["$"](`#${this.target_name}`).on("show.bs.modal", () => {
      this.onModalShow.emit();
    });

    window["$"](`#${this.target_name}`).on("hidden.bs.modal", () => {
      this.onModalHide.emit();
    });
  }
}
