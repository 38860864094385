<nav id="sidebar" class="navbar navbar-expand be-top-header">
  <div class="container-fluid d-flex flex-column">
    <div class="be-navbar-header">
      <ul class="col-12 nav navbar-nav be-user-nav d-flex justify-space-between">
        <a routerLink="/backoffice" class="navbar-brand"></a>
        <li class="col-lg-1 p-2 nav-item dropdown d-flex avatar-pos user-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle mt-1">
            <img src="assets/img/avatar.png" alt="Avatar" class="nav-avatar mb-2" />
            <span class="user-name">{{ this.loginService.getCurrentUser().first_name + " " + this.loginService.getCurrentUser().last_name }}</span>
          </a>
          <div role="menu" class="dropdown-menu">
            <div class="user-info">
              <div class="user-name">
                {{ this.loginService.getCurrentUser().first_name + " " + this.loginService.getCurrentUser().last_name }}
              </div>
            </div>
            <a routerLink="/backoffice/account" class="dropdown-item nav-link"><span class="icon mdi mdi-face"></span> Minha Conta</a>
            <a *ngIf="loginService.isLogged()" (click)="logout()" class="dropdown-item nav-link"><span class="icon mdi mdi-power"></span>Sair</a>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-12 search-box pr-5">
      <ul class="col-12 nav navbar-nav be-user-nav d-flex flex-column">
        <li class="p-2">
          <form class="d-flex search search-pos mt-2 mt-lg-0" (ngSubmit)="searchPosts($event)">
            <input type="text" placeholder="Pesquisar postagem" class="col-11 form-control input-search-nav-bar" />
            <button type="submit"><i class="fas fa-search"></i></button>
          </form>
        </li>
      </ul>
    </div>
    <div class="col-12 mt-lg-5">
      <div *ngIf="this.multi_customer" class="ml-5">
        <p class="text-white">
          <b> Cliente atual: </b>
          <span>{{ this.current_customer }}</span>
        </p>
      </div>
      <ul class="col-12 nav navbar-nav be-user-nav d-flex flex-column mt-lg-5 selects-box">
        <li *ngIf="this.multi_customer && customers && customers.length > 0" class="">
          <select-input [object]="customer" [url]="'admin/customers/customers_for_select'" [placeholder]="this.current_customer ? this.current_customer : 'Selecionar cliente'" (onSelect)="changeCustomer($event)" [multiple]="false"></select-input>
        </li>
        <li class="">
          <select-input [object]="brand" [options]="this.brands" [placeholder]="'Selecionar loja'" (onSelect)="onChange($event)" [multiple]="false" [reloadOnChange]="true"></select-input>
        </li>
      </ul>
    </div>
    <a href="#" id="sub-navigation" data-toggle="collapse" aria-expanded="false" data-target="#be-navbar-collapse" class="be-toggle-top-header-menu collapsed col-sm-12">Menu</a>
    <div id="be-navbar-collapse" class="navbar-collapse collapse col-12 mt-lg-5">
      <ul class="navbar-nav d-flex flex-column col-12 navbar-body">
        <li class="nav-item dropdown nav-item-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
            <i class="fa fa-edit fa-pencil-square mr-2"></i>
            <span> Ações </span>
          </a>
          <ul class="dropdown-menu">
            <li *ngIf="this.user.permissions['reports']" class="dropdown-submenu">
              <a href="" data-toggle="dropdown" class="dropdown-item dropdown-toggle dropdown-submenu-link" role="button">
                <span> Relatórios </span>
                <span class="mdi mdi-caret-right pull-right"></span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <!-- o comando para aparecer o modal está nas últimas linhas -->
                  <a data-tippy href="" title="Relatório" data-toggle="modal" data-target="#posted-volume-report-form" class="dropdown-item" (click)="openModal($event)"> Volume postado </a>
                </li>
                <li>
                  <a routerLink="/backoffice/multi_search" class="dropdown-item"> Pesquisa em lote </a>
                </li>
                <li>
                  <!-- o comando para aparecer o modal está nas últimas linhas -->
                  <a data-tippy href="" title="Sistema delivery" data-toggle="modal" data-target="#ship_from_store_report" class="dropdown-item" (click)="openModal($event)"> Sistema delivery </a>
                </li>

                <li *ngIf="this.user.permissions['financial_report']">
                  <!-- o comando para aparecer o modal está nas últimas linhas -->
                  <a data-tippy href="" title="Financeiro" data-toggle="modal" data-target="#financial-report" class="dropdown-item" (click)="openModal($event)"> Financeiro </a>
                </li>
                <li>
                  <!-- o comando para aparecer o modal está nas últimas linhas -->
                  <a data-tippy href="" title="Postagens Recusadas" data-toggle="modal" data-target="#refused_posts_report" class="dropdown-item" (click)="openModal($event)"> Postagens Recusadas </a>
                </li>
                <!-- Conemb é só para Alpargatas -->
                <li *ngIf="user?.customer?.cnpj == '10892604000143'">
                  <a data-tippy href="" title="Arquivos" data-toggle="modal" data-target="#ship_from_store_charge_report" class="dropdown-item" (click)="openModal($event)"> Arquivo CONEMB </a>
                </li>

                <li>
                  <a data-tippy href="" title="Fornecedores" data-toggle="modal" data-target="#carriers-report" class="dropdown-item" (click)="openModal($event)"> Fornecedores </a>
                </li>
                <li>
                  <a data-tippy href="" title="Download de relatório" data-toggle="modal" data-target="#download-reports" class="dropdown-item" (click)="openModal($event)"> Download de relatório </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a routerLink="/backoffice/unified_posts" class="dropdown-item"> Postagem Unificada </a>
            </li>
            <li class="nav-item">
              <a routerLink="/backoffice/posts_rejected_posts" [queryParams]="{ filter: 'customer' }" class="dropdown-item">Postagem Rejeitada do Cliente</a>
            </li>

            <li *ngIf="this.loginService.hasRole('update_status') || this.loginService.hasRole('update_tracking')" class="dropdown-submenu">
              <a class="dropdown-item dropdown-toggle dropdown-submenu-link" href="#">
                <span> Atualização manual de postagem </span>
                <span class="mdi mdi-caret-right"></span>
              </a>
              <ul class="dropdown-menu">
                <li *ngIf="this.loginService.hasRole('update_status')">
                  <!-- o comando para aparecer o modal está nas últimas linhas -->
                  <a routerLink="/backoffice/post_status_change" class="dropdown-item"> Atualizar status de postagem </a>
                </li>
                <li *ngIf="this.loginService.hasRole('update_tracking')">
                  <a routerLink="/backoffice/post_tracking_change" class="dropdown-item"> Atualizar código de rastreio </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown nav-item-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
            <i class="fa fa-tags mr-2" title="Postagens"></i>
            <span> Postagens </span>
          </a>
          <div role="menu" class="dropdown-menu">
            <a routerLink="/backoffice/posts" class="dropdown-item">Postagens</a>
            <a routerLink="/backoffice/unified_posts" class="dropdown-item">Postagens unificadas</a>
            <a routerLink="/backoffice/batch_post" class="dropdown-item">Postagens em lote</a>
            <a routerLink="/backoffice/rejected_posts" class="dropdown-item">Postagens Rejeitadas</a>
            <a routerLink="/backoffice/delivery_routes" class="dropdown-item">Rotas de Entrega</a>
            <a routerLink="/backoffice/index_manual_resend_post" class="dropdown-item">Fluxo de Pedido</a>
          </div>
        </li>
        <li *ngIf="this.register_permissions" class="nav-item dropdown nav-item-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
            <i class="fa fa-paperclip mr-2" title="Cadastros"></i>
            <span> Cadastros </span>
          </a>
          <div role="menu" class="dropdown-menu">
            <a *ngIf="this.user.permissions['customer_register']" routerLink="{{ this.customerPath }}" class="nav-link">Clientes</a>
            <a *ngIf="this.user.permissions['user_register'] && this.loginService.hasRole('list_users')" routerLink="{{ this.usersPath }}" class="dropdown-item"> Usuários </a>
            <a *ngIf="this.user.permissions['brand_register']" routerLink="/backoffice/brands" class="dropdown-item">Marcas</a>
            <a *ngIf="this.user.permissions['franchise_register']" routerLink="/backoffice/franchises" class="dropdown-item">Franquias</a>
            <a *ngIf="this.user.permissions['franchise_register']" routerLink="/backoffice/sub_carriers" class="dropdown-item"> Transportadores Tracken </a>
          </div>
        </li>
        <li class="nav-item dropdown nav-item-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
            <i class="fa fa-cog fa-gear mr-2" title="Configurações"></i>
            <span> Configurações </span>
          </a>
          <div role="menu" class="dropdown-menu">
            <a routerLink="/backoffice/groups" *ngIf="this.loginService.hasRole('list_groups')" class="dropdown-item">Grupos</a>
            <a routerLink="/backoffice/carriers" class="dropdown-item">Transportadoras</a>
            <a routerLink="/backoffice/webhook_tracking_config" class="dropdown-item">Webhook Rastreio</a>
            <a routerLink="/backoffice/sftp" class="dropdown-item">Importação SFTP</a>
            <a routerLink="/backoffice/return_files" class="dropdown-item">Arquivos de retorno</a>
            <a routerLink="/backoffice/status" class="dropdown-item">Status</a>
            <a routerLink="/backoffice/sms_account_config" class="dropdown-item">Configuração SMS</a>
            <a routerLink="/backoffice/brand_sms" class="dropdown-item">Envio SMS</a>
          </div>
        </li>
        <li class="nav-item dropdown nav-item-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
            <i class="fa fa-files fa-copy mr-2" title="Logs"></i>
            <span> Logs </span>
          </a>
          <div role="menu" class="dropdown-menu">
            <a routerLink="/backoffice/api_logs" class="dropdown-item">Logs da API</a>
            <a routerLink="/backoffice/webhook_tracking_logs" class="dropdown-item">Logs API Integração</a>
          </div>
        </li>
        <li *ngIf="this.user.permissions['financial_register']" class="nav-item dropdown nav-item-box">
          <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
            <i class="mdi mdi-money mr-3"></i>
            <span> Faturamento </span>
          </a>
          <div role="menu" class="dropdown-menu">
            <a routerLink="/backoffice/customers_prices" class="dropdown-item">Clientes</a>
            <a routerLink="/backoffice/carriers_prices" class="dropdown-item">Fornecedores</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<app-posts-manual-posts-posted-volume-report *ngIf="this.modal === '#posted-volume-report-form'"></app-posts-manual-posts-posted-volume-report>
<ship_from_store_report *ngIf="this.modal === '#ship_from_store_report'"></ship_from_store_report>

<ship_from_store_charge_report *ngIf="this.modal === '#ship_from_store_charge_report'"></ship_from_store_charge_report>
<refused_posts_report *ngIf="this.modal === '#refused_posts_report'"></refused_posts_report>
<financial-report *ngIf="this.modal === '#financial-report'"></financial-report>
<carriers-report *ngIf="this.modal === '#carriers-report'"></carriers-report>
<download-reports *ngIf="this.modal === '#download-reports'"></download-reports>
