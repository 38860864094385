import { Brand } from './brand'
import { Customer } from './customer'
import { ManualPostStatus } from './manual_post_status'
import { Possessor } from './possessor'
import { Post } from './post'
import { PostError } from './post_error'
import { Productable } from './productable'
import { User } from './user'

export class ManualPost {
  public id:number;

  public manual_post_possessor: Possessor = new Possessor();

  public customer:Customer;
  public user:User;
  public status:ManualPostStatus = new ManualPostStatus();
  unified_post;
  public unified_post_status:ManualPostStatus = new ManualPostStatus();
  public manual_post_columns: Array<any>;
  public brand: Brand = new Brand();
  carrier_api_response_errors;

  public file:any;
  public file_name:string;
  public send_date:string;
  public processing_date:string;
  public download_link:string;

  public header_line:number;
  public content_line:number;
  public unified_post_id:number;

  public file_type: string;
  public column_delimiter: string = ';';
  public grouping: string;
  public break_line: string;
  public parent_post_node: string[] = [];

  public process_consistent_lines: boolean = true;
  public can_add_more_posts: boolean = true;

  public importeds: number;
  public not_importeds: number;

  public payment_type: string;
  public payment_change: number;

  public carrier_parameterized;
  public carrier;
  public carrier_correios_internal_id;
  public brand_flash_courier_integration_product_id;
  public id_plp_correios;
  public plp_xml_correios;
  public carrier_product;
  public id_plp;
  public origin_type;
  brand_flash_courier_integrations_product_id;
  carrier_nobre_products_id;

  public expected_posting_date;
  public productable_id;
  public productable: Productable = new Productable();

  public formatted_posting_date:string;
  public posts: Array<Post> = [];
  public post_errors: Array<PostError> = [];

  public created_at:string;
  public updated_at:string;
  public errors:any = {};

  vehicle_type: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.posts) {
      this.posts = input.posts.map((post) => new Post().deserialize(post));
    }
    if (input.post_errors) {
      this.post_errors = input.post_errors.map((post_error) => new PostError().deserialize(post_error));
    }
    return this;
  }
}

