import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DAFAULT_LOGO, DEFAULT_BACKGROUND } from "../../../models/styles/index.model";
import { Tracking } from "../../../../trackings/shared/model/tracking.model";
import { Template } from "../../../models/template.model";

@Component({
  selector: "focco-ui-template-tracking",
  templateUrl: "./template-tracking.component.html",
  styleUrls: ["./template-tracking.component.css"],
})
export class TemplateTrackingComponent {
  @Input() config: Template;

  @Output("submit-tracking-code") onSubmitTrackingCode = new EventEmitter<Tracking>();

  tracking_code: string;

  readonly DAFAULT_LOGO = DAFAULT_LOGO;
  readonly DEFAULT_BACKGROUND = DEFAULT_BACKGROUND;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  onSubmit(_event) {
    this.onSubmitTrackingCode.emit({ tracking_code: this.tracking_code });
  }

  // Redireciona para que o usuário pesquise um novo tracking
  onPublicPath(_event: PointerEvent): void {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {} });
  }
}
