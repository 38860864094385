import { Deserializable } from "./deserializable.model";

export class OriginAddress implements Deserializable {
  public id:number;

  public phone:string;
  public contact_name:string;
  public cnpj:string;
  public address:string;
  public address_number:string;
  public address_complement:string;
  public address_neighborhood:string;
  public address_zipcode:string;
  public address_city:string;
  public address_state:string;

  public created_at:string;
  public updated_at:string;
  public errors:any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
