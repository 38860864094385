import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { NotificationService } from "../../messages/notification.service";

@Component({
  selector: "text-input-form-group",
  templateUrl: "./text-input-form-group.component.html",
  styleUrls: ["./text-input-form-group.component.css"],
})
export class TextInputFormGroupComponent implements OnInit, AfterViewInit {
  @Input() object: any;
  @Input() attribute: string;
  @Input() errors: any;
  @Input() label: string;
  @Input() name: string;
  @Input() focus: boolean = false;
  @Input() maxlength: number = 5000;
  @Input() custom_class: string = "";
  @Input() placeholder: string = "";
  @Input() type: string = "text";
  @Input() hint: string = null;
  @Input() counter: boolean = false;
  @Input() enable_form_group: boolean = false;
  @ViewChild("input1") inputEl: ElementRef;
  protocol;
  domain;
  console = console;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    if (this.type == "subdomain") {
      this.protocol = window.location.protocol + "//";
      this.domain = "." + this.getDomain();
    }
  }

  getDomain() {
    if (document.domain.length) {
      var parts = document.domain.replace(/^(www\.)/, "").split(".");
      while (parts.length > 2) {
        var subdomain = parts.shift();
      }
      var domain = parts.join(".");
      return domain.replace(/(^\.*)|(\.*$)/g, "");
    }
    return "";
  }

  ngAfterViewInit() {
    if (this.type == "tel") {
      var SPMaskBehavior, spOptions;
      SPMaskBehavior = function (val) {
        if (val.replace(/\D/g, "").length === 11) {
          return "(00) 00000-0000";
        } else {
          return "(00) 0000-00009";
        }
      };

      spOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        },
      };

      window["$"]("input[type=tel]").mask(SPMaskBehavior, spOptions);
    } else if (this.type === "cnpj") {
      window["$"]("input.cnpj").mask("00.000.000/0000-00", { reverse: true });
    } else if (this.type === "cpf") {
      window["$"]("input.cpf").mask("000.000.000-00", { reverse: true });
    } else if (this.type === "cep") {
      window["$"]("input.cep").mask("00000-000");
    }

    if (this.focus) this.inputEl.nativeElement.focus();
  }

  getType() {
    if (this.type === "cnpj" || this.type === "cpf" || this.type === "cep" || this.type === "color") return "text";
    return this.type;
  }

  getClasses() {
    let _class = this.custom_class;
    if (this.type === "cnpj" || this.type === "cpf" || this.type === "cep" || this.type === "color") _class += " " + this.type;
    return _class;
  }

  copyTextToClipboard() {
    var txtArea = document.createElement("textarea");
    txtArea.id = "txt";
    txtArea.style.position = "fixed";
    txtArea.style.top = "0";
    txtArea.style.left = "0";
    txtArea.style.opacity = "0";
    txtArea.value = window["$"](`[name="${this.name}"] input`).val();
    document.body.appendChild(txtArea);
    txtArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      this.notificationService.notify("O código da cor foi copiado para a sua área de transferência", "success");
      if (successful) {
        return true;
      }
    } catch (err) {
      this.notificationService.notify("Não foi possível copiar.", "danger");
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }
}
