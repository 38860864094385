import { CepRange } from "./cep_range";
import { Deserializable } from "./deserializable.model";
import { FranchiseUser } from "./franchise_user";

export class Franchise implements Deserializable {
  public id: number;
  public fantasy_name: string = '';
  public social_name: string;
  public cnpj: string;
  public ie: string;
  public email: string;
  public commercial_phone: string;
  public cell_phone: string;
  public contact_name: string;
  public address: string;
  public address_number: string;
  public address_complement: string;
  public address_neighborhood: string;
  public address_zipcode: string;
  public address_state: string;
  public address_city: string;
  public anniversary: string;
  public description: string;
  public is_active: boolean = true;
  public is_active_description: string;
  public identifier: string;
  public brands_total: number;

  public franchise_users: Array<FranchiseUser>;
  public cep_ranges: Array<CepRange>;
  has_flash_reverse: string;
  has_ship_reverse: string;

  users_active;
  users_inactive;
  post_by_months;
  last_sign_in;

  total_logs;
  success_logs;
  error_logs;
  not_found_logs;

  franchise_ids = [];

  public created_at: string;
  public updated_at: string;
  public errors: any = {}

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.franchise_users) {
      this.franchise_users = input.franchise_users.map((user) => new FranchiseUser().deserialize(user));
    }
    if (input.cep_ranges) {
      this.cep_ranges = input.cep_ranges.map((cep_range) => new CepRange().deserialize(cep_range));
    }
    return this;
  }
}
