import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { NotificationService } from "../../../../core/messages/notification.service";
import { AppComponent } from "../../../../app.component";
import { assign_map } from "./posted-volume-report-assign";
import { Brand } from "../../../../models/brand";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Customer } from "../../../../models/customer";
import { hub_assign_map } from "./hub-posted-volume-report-assign";

@Component({
  selector: "app-posts-manual-posts-posted-volume-report",
  templateUrl: "./posted-volume-report.component.html",
  styleUrls: ["./posted-volume-report.component.css"],
})
export class PostedVolumeReportComponent implements OnInit, AfterViewInit {
  public assign_map: Array<any> = assign_map;
  public hub_assign_map: Array<any> = hub_assign_map;
  multiple = true;
  titles = {};
  values = {};
  brands = {};
  brand_ids;
  checkbox_csv = true;
  checkbox_download = null;
  checkbox_list = null;
  url;
  brand = new Brand();
  originalOptions: Array<any>;
  customer = new Customer();
  allBrands: boolean = true; // trocar para false se alterar id="all-brands-checkbox" para unchecked

  date_now = new Date();
  default_date = String(this.date_now.getFullYear()) + "-" + String(this.date_now.getMonth() + 1).padStart(2, "0") + "-" + String(this.date_now.getDate()).padStart(2, "0");

  date_ini = this.default_date;
  date_end = this.default_date;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router, private http: HttpClient) {}

  ngOnInit() {
    this.appComponent.load(true);
    const element = document.querySelector("#posted-volume-report-form");
    console.log(element);
    this.apiService.get("backoffice/brands?q%5Bis_active_eq%5D=true&q%5Bs%5D=name+asc&per=5000").subscribe((data: any) => {
      this.brands = data.brands;
      this.customer = data.customer;
      if (this.customer.subdomain == "hubcard") {
        this.checkCustomer();
      }
    });
    this.url = "backoffice/brands/brands_for_select";
  }

  checkCustomer() {
    this.assign_map = hub_assign_map;
  }

  ngAfterViewInit() {
    // Esse callback desmarca todas as opções selecionadas após fechar a janela de formulário do relatório
    const scope = this;
    window["$"]("#posted-volume-report-form").on("hidden.bs.modal", function (e) {
      scope.titles = {};
      scope.values = {};
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
      scope.brand = new Brand();
      let uncheck_check_all = document.getElementById("check_all") as HTMLInputElement;
      uncheck_check_all.checked = false;
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  checkValuesTitle(i) {
    // Importante:  Estrutura do assign_map => assign_map[array_index]['FIELDS']['field_index']['inside_field']['ATTRIBUTE']

    // Essa função marca apenas os blocos dos titles e os atributes dos fields
    // Ela não usa a propriedade checked dos elementos HTML (getElementeByID)
    // Ao invés disso, duas hash que são inicializadas no OnInit (a value e a titles)

    // a variável "i" é o index do title clicado no HTML
    // Assim como this.titles[i] é atualizado no HMTL com qual title foi marcado => [(ngModel)]="titles[i]"

    let field, column, j, x;
    for (j = 0; j < this.assign_map[i]["fields"].length; j++) {
      // calcula o tamanho do title clicado
      column = this.assign_map[i]["fields"][j]; // define column para iterar dentro do title
      for (x = 0; x < column.length; x++) {
        // calcula quantos field o title tem
        field = column[x]; // define qual field sera iterado
        this.values[field["attribute"]] = this.titles[i]; // marca o attribute como true para ser marcado no HMTL
      }
    }
  }

  checkAll() {
    // Essa função vai iterar por toda a estrutura do assign_map para marcar todos os campos
    // Estrutura do assign_map => assign_map[array_index]['FIELDS']['field_index']['inside_field']['ATTRIBUTE']
    let array_index, field_index, inside_field;
    let map_size = Array.from(Object.keys(this.assign_map));
    for (array_index = 0; array_index < map_size.length; array_index++) {
      // console.log(this.assign_map[array_index]);
      let check_all = document.getElementById("check_all") as HTMLInputElement; // Esse if serve para marcar todos os titles como true
      if (check_all.checked == true) {
        // já que não foi capturado individualmente
        this.titles[array_index] = true; // pela funcao checkValuesTitle no HTML
      } else if (check_all.checked == false) {
        this.titles[array_index] = false;
      }
      for (field_index = 0; field_index < this.assign_map[array_index]["fields"].length; field_index++) {
        for (inside_field = 0; inside_field < this.assign_map[array_index]["fields"][field_index].length; inside_field++) {
          this.values[this.assign_map[array_index]["fields"][field_index][inside_field]["attribute"]] = this.titles[array_index];
          // nessa linha ele marca como true ou false o atributo para aparecer (o titles foi definido no if acima)
        }
      }
    }
  }

  onSubmit() {
    const scope = this;
    const filled_checks = Object.keys(this.values).filter(function (k) {
      return scope.values[k];
    });
    if (filled_checks.length === 0) {
      this.notificationService.notify("Selecione pelo menos 1 checkbox para exportar em CSV.", "danger");
    } else if (this.brand.brand_ids.length === 0 && this.allBrands === false) {
      this.notificationService.notify("Selecione pelo menos uma marca.", "danger");
    } else if (this.convertStringToDate(this.date_end) < this.convertStringToDate(this.date_ini)) {
      this.notificationService.notify("Data inicial maior que data final", "danger");
    } else if (this.convertStringToDate(this.date_end) >= this.date_now) {
      this.notificationService.notify("Data final não pode ser maior que dia e horário atual", "danger");
    } else if (this.checkbox_csv) {
      // this.appComponent.load(true);
      this.apiService.post(`/backoffice/posts/quantity_shipped`, { fields: filled_checks, brand_ids: this.brand.brand_ids, date_ini: this.date_ini, date_end: this.date_end, all_brands: this.allBrands, customer_id: this.customer.id }).subscribe(
        (response) => {
          window["$"](".modal").modal("hide");
          this.notificationService.notify("O sistema está gerando a exportação em CSV." + " O relatório será enviado para o seu e-mail.", "success");
          // this.appComponent.load(false);
        },
        (errors) => {
          this.notificationService.notify(`Erro ao gerar a exportação.`, "danger");
          this.appComponent.load(false);
        }
      );
    } else if (this.checkbox_list) {
      localStorage.setItem("fields", JSON.stringify(filled_checks));
      localStorage.setItem("brand_ids", JSON.stringify(this.brand.brand_ids));
      localStorage.setItem("all_brands", JSON.stringify(this.allBrands));
      localStorage.setItem("customer_id", JSON.stringify(this.customer.id));
      localStorage.setItem("date_ini", this.date_ini);
      localStorage.setItem("date_end", this.date_end);
      window["$"](".modal").modal("hide");
      this.router.navigateByUrl("/backoffice/manual_posts/posted_volume_list");
    } else if (this.checkbox_download) {
      const strWindowFeatures = "location=yes,height=120,width=520,scrollbars=yes,status=yes";
      let options = {};
      options = { fields: filled_checks, brand_ids: this.brand.brand_ids, date_ini: this.date_ini, date_end: this.date_end, all_brands: this.allBrands, customer_id: this.customer.id };

      const win = window.open(`/download/send-csv?${window["$"].param(options)}`, "_blank", strWindowFeatures);
      window["$"](".modal").modal("hide");
      this.notificationService.notify("O sistema está gerando a exportação em CSV." + " Uma nova janela será aberta para completar o download.", "success");
    } else {
      this.notificationService.notify("Selecione o formato de visualizaçao: Tela, CSV, Download.", "danger");
    }
  }

  check_download(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_download = true;
      this.checkbox_csv = false;
      this.checkbox_list = false;
    }
  }

  check_csv(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_csv = true;
      this.checkbox_list = false;
      this.checkbox_download = false;
    }
  }

  check_list(evt) {
    const target = evt.target;
    if (target.checked) {
      this.checkbox_list = true;
      this.checkbox_csv = false;
      this.checkbox_download = false;
    }
  }

  toggleAllBrands() {
    this.allBrands = !this.allBrands;
    this.brand.brand_ids = [];
  }

  selectInputChange(event) {
    if (event[0]) {
      let uncheckAll = document.getElementById("all-brands-checkbox") as HTMLInputElement;
      uncheckAll.checked = false;
      this.allBrands = false;
    }
  }

  easyTesting() {
    this.date_ini = "2019-01-09";
    this.date_end = "2019-06-09";
    this.brand.brand_ids = [13];

    let array_index, field_index, inside_field;
    let map_size = Array.from(Object.keys(this.assign_map));
    for (array_index = 0; array_index < map_size.length; array_index++) {
      // console.log(this.assign_map[array_index]);
      let check_all = document.getElementById("check_all") as HTMLInputElement;
      check_all.checked = true;
      this.titles[array_index] = true;
      for (field_index = 0; field_index < this.assign_map[array_index]["fields"].length; field_index++) {
        for (inside_field = 0; inside_field < this.assign_map[array_index]["fields"][field_index].length; inside_field++) {
          this.values[this.assign_map[array_index]["fields"][field_index][inside_field]["attribute"]] = this.titles[array_index];
          // nessa linha ele marca como true ou false o atributo para aparecer (o titles foi definido no if acima)
        }
      }
    }
  }
}
