import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../../../app.component";
import { NotificationService } from "../../../core/messages/notification.service";
import { ApiService } from "../../../core/services/api.service";
import { Customer } from "../../../models/customer";

@Component({
  selector: "app-customer-report",
  templateUrl: "./customer-report.component.html",
  styleUrls: ["./customer-report.component.css"],
})
export class CustomerReportComponent implements OnInit {
  customer: Customer = new Customer();
  all_customers: Array<any> = [];

  base_path: string = "admin/customers/customers_for_select";
  date_now: Date = new Date();
  default_date = String(this.date_now.getFullYear()) + "-" + String(this.date_now.getMonth() + 1).padStart(2, "0") + "-" + String(this.date_now.getDate()).padStart(2, "0");

  date_ini: string = this.default_date;
  date_end: string = this.default_date;

  constructor(public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService, public router: Router) {}

  ngOnInit() {
    this.apiService.get("admin/customers/customers_for_select").subscribe((data: Array<any>) => {
      this.all_customers = data;
    });
  }

  convertStringToDate(date) {
    return new Date(`${date}` + "T" + "00:00");
  }

  onSubmit() {
    if (this.customer.customer_ids.length === 0) {
      this.notificationService.notify("Selecione pelo menos uma marca.", "danger");
    } else if (this.convertStringToDate(this.date_end) < this.convertStringToDate(this.date_ini)) {
      this.notificationService.notify("Data inicial maior que data final", "danger");
    } else if (this.convertStringToDate(this.date_end) >= this.date_now) {
      this.notificationService.notify("Data final não pode ser maior que dia e horário atual", "danger");
    } else {
      let strWindowFeatures = "location=yes,height=120,width=520,scrollbars=yes,status=yes";

      this.all_customers.forEach((brand) => {
        brand.splice(1, 1);
      });
      // Transfo-+rmar tudo numa array só
      let sendAllBrands = ([] as string[]).concat(...this.all_customers);
      // Compara se as marcas selecionadas são todas as marcas
      const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
      let report_params = {};

      if (equals(sendAllBrands, this.customer.customer_ids)) {
        report_params = {
          fields: this.allFields(),
          customer_ids: "",
          date_ini: this.date_ini,
          date_end: this.date_end,
          type: "report-customers",
          all_customers: true,
        };
      } else {
        report_params = {
          fields: this.allFields(),
          customer_ids: this.customer.customer_ids,
          date_ini: this.date_ini,
          date_end: this.date_end,
          type: "report-customers",
          all_customers: "",
        };
      }
      window.open(`/download/send-csv?${window["$"].param(report_params)}`, "_blank", strWindowFeatures);
      window["$"](".modal").modal("hide");
      this.notificationService.notify("O sistema está gerando a exportação em CSV." + " Uma nova janela será aberta para completar o download.", "success");
    }
  }

  allFields() {
    const fields = [
      // Fonte dos labels: pt-BR.models.yml
      "get_post_customer", // Cliente
      "last_tracking_status", // Status de Rastreio
      "client_document_number", // Número de documento no cliente (ID)
      "complementary_show_report", // Pedido Complementar?
      "complementary_parent_post", // Número Pedido principal
      "complementary_index", // Ordem Complementar/Total Complementares
      "description", // Descrição
      "parent_order_route", // Roteiro
      "tracking_code", // Código de rastreio
      "created_at_formated_sfs", // Data/Hora criação Pedido
      "num_enc_ter", // Remessa
      "dispatch_date_formated", // Data de despacho
      "carrier", // Transportadora
      "get_product_name", // Produto
      "cost_center_id", // Centro de custo
      "billing_sfs", // Origem da venda
      "carrier_contract", // Contrato na transportadora
      "origin_zipcode", // CEP de Origem
      "recipient_name", // Nome do destinatário
      "recipient_address", // Nome do logradouro do destinatário
      "recipient_address_number", // Número do destinatário
      "recipient_address_complement", // Complemento do destinatário
      "recipient_address_neighborhood", // Bairro do destinatário
      "cep_head", // Cabeça de CEP
      "recipient_address_city", // Cidade do destinatário
      "recipient_address_state", // UF do destinatário
      "recipient_address_zipcode", // CEP do destinatário
      "recipient_address_reference_point", // Ponto de referência
      "recipient_phone_1", // Telefone 1 do destinatário
      "recipient_phone_2", // Telefone 2 do destinatário
      "recipient_email", // E-mail do destinatário
      "cpf_cnpj", // CPF/CNPJ
      "ie", // IE
      "recipient_department", // Departamento do destinatário
      "package_weight_gr", // Peso da encomenda (em gr)
      "package_width_cm", // Largura da embalagem (em cm)
      "package_length_cm", // Comprimento da embalagem (em cm)
      "package_height_cm", // Altura da embalagem (em cm)
      "invoice_series", // Série da nota fiscal
      "invoice", // Nota
      "invoice_value", // Valor da nota fiscal
      "invoice_emission_date_formated", // Data de emissão da nota fiscal
      "invoice_access_key", // Chave de acesso da nota fiscal
      "products_value", // Valor dos produtos
      "observation_1", // Obs 1
      "observation_2", // Obs 2
      "observation_3", // Obs 3
      "observation_4", // Obs 4
      "cd_code", // Código do centro de distribuição
      "cd_cnpj", // CNPJ do CD
      "cd_name", // Nome do CD
      "cd_phone", // Telefone do CD
      "cd_email", // E-mail do CD
      "cd_address", // Logradouro do CD
      "cd_address_number", // Número do CD
      "cd_address_complement", // Informações adicionais do endereço do CD
      "cd_address_reference", // Referência do endereço do CD
      "cd_address_zipcode", // CEP do CD
      "cd_address_city", // Cidade do CD
      "cd_address_neighborhood", // Bairro do CD
      "cd_address_state", // UF do CD
      "delivery_deadline", // Prazo de entrega ===> Só para Focco
      "delivery_last_status", // Último status recebido
      "tracking_page", // Página de Rastreio
      "post_status_name", // Status do Roteiro
      "delivery_date_time_formated", // Data/Hora da Entrega
      "attempts_quantity", // Qtd de Tentativas
      "last_tracking_status_found_at_formated", // Data do último status recebido
      "last_carrier_tracking_status_collected_formated", //Data/hora Coleta
      "original_history", // Gerado via
      "levoo_route_created_at_formated", // Data/Hora criação roteiro
      "levoo_route_deliverer_name", // Nome do entregador
      "franchise_route_social_name", // Franquia do Atendimento
      "find_franchise_fantasy_name_by_brand", // Franquia Padrão
      "receiver_name", // Recebedor
      "due_date_formated", // Previsão de Entrega ===> Só para Focco
      "delivery_return_date_formated", // Data de Devolução
      "situation", // Situação
      "brand_name_sfs", // Loja
      "brand_cnpj", // CNPJ da Loja
      "brand_identifier", // Contato de retirada
      "failure_reason", // Motivo Insucesso
      "levoo_route_deliverer_vehicle_type", // Veículo
      "distance_from_origin", // Quilometragem
      "franchise_route_identifier", // Franquia do Atendimento - Identificador
      "courier_employment_type", // Contrato do Entregador
      "franchise_route_social_name",
      // Franquia do Atendimento
    ];
    return fields;
  }

  ngAfterViewInit() {
    const scope = this;
    window["$"]("#customer-report").on("hidden.bs.modal", function () {
      scope.date_ini = scope.default_date;
      scope.date_end = scope.default_date;
      scope.customer = new Customer();
    });
  }
}
