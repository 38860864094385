import { Injectable } from "@angular/core";
import { LoginService } from "../components/auth/login/login.service";
import { NotificationService } from "../messages/notification.service";
import { Router } from "@angular/router";
import { ApiService } from "../../core/services/api.service";
import { environment } from "../../../environments/environment";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class CoreService {
  constructor(public loginService: LoginService, public notificationService: NotificationService, public router: Router, public apiService: ApiService) {}

  public setDateTimeString(obj: any, attribute: string) {
    if (obj[attribute]) {
      let date = new Date(Date.parse(obj[attribute]));
      obj[attribute] = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
    return obj;
  }

  public setDateString(obj: any, attribute: string) {
    if (obj[attribute]) {
      let date = new Date(Date.parse(obj[attribute]));
      if (obj[attribute].length == 10) date.setDate(date.getDate() + 1);
      obj[attribute] = date.toLocaleDateString();
    }
    return obj;
  }

  hasRole(role) {
    this.loginService.refreshUserRoles();
    // if (!this.loginService.hasRole(role)){
    //   this.notificationService.notify('Você não tem permissão para acessar este recurso.', 'danger');
    //   this.router.navigate(['/backoffice/account']);
    //   return false;
    // }
    return true;
  }

  getMonthNumber(month) {
    switch (month) {
      case "Janeiro":
        return 1;
      case "Fevereiro":
        return 2;
      case "Março":
        return 3;
      case "Abril":
        return 4;
      case "Maio":
        return 5;
      case "Junho":
        return 6;
      case "Julho":
        return 7;
      case "Agosto":
        return 8;
      case "Setembro":
        return 9;
      case "Outubro":
        return 10;
      case "Novembro":
        return 11;
      case "Dezembro":
        return 12;
    }
  }

  public getEnvironment(): Observable<any> {
    return this.apiService.get("base/current_environment").pipe(catchError((err) => throwError(err.message)));
  }
}
