export class CepRange {
  public id: number;
  public attendance_type: string;
  public cep_start: string;
  public cep_end: string;
  public index_new_cep_range: number;
  public cep_rangeable_type: string;

  public errors: any = {};
  public error: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
