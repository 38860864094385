export class CarrierNobreIntegration {
    public id:number;

    public customer_id:number;
    public user:string;
    public password:string;
    public api_url:string;
    public client_id;
    public address_zipcode;
    public address;
    public address_number;
    public address_complement;
    public address_neighborhood;
    public address_city;
    public address_state;
    active:boolean;

    public created_at:string;
    public updated_at:string;
    public errors:any = {}
  }
