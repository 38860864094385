<div class="card card-table">
  <div class="card-header">
    <div class="title">Usuários {{ type == "active" ? "Ativos" : "Inativos" }}</div>
  </div>
  <div class="card-body table-responsive" *ngIf="rows.length == 0">
    <p class="p-4">Nenhum registro encontrado.</p>
  </div>
  <div class="card-body table-responsive" *ngIf="rows.length > 0">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Status</th>
          <th class="w-50">Usuário</th>
          <th>Último Login</th>
          <th class="actions"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td class="user-avatar">
            <span class="badge" [ngClass]="{ 'badge-success': type == 'active', 'badge-secondary': type == 'inactive' }">
              {{ type == "active" ? "Ativo" : "Inativo" }}
            </span>
          </td>
          <td class="user-avatar"><img [src]="'assets/img/avatar6.png'" alt="Avatar" />{{ row.first_name }} {{ row.last_name }}</td>
          <td>{{ row.last_sign_in_at }}</td>
          <td class="actions">
            <a data-tippy title="Visualizar" [routerLink]="user_url(row)" class="icon">
              <i class="mdi mdi-eye"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination *ngIf="this.rows.length > 0" (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="6" [page]="page" [perPage]="limit" [count]="total"> </app-pagination>
  </div>
</div>
