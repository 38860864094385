<div class="row be-datatable-footer">
  <div class="col-sm-4">
    <div class="dataTables_info" id="table1_info" role="status" aria-live="polite">
      Mostrando {{ getMin() }} até {{ getMax() }} de {{ count }} registros
      <div class="mt-2">
        Exibir
        <select (change)="changePerPage($event)" ([ngModel])="(perPage)" class="form-control d-inline-block pagination-box">
          <option value="10" [selected]="perPage == 10">10</option>
          <option value="25" [selected]="perPage == 25">25</option>
          <option value="50" [selected]="perPage == 50">50</option>
          <option value="100" [selected]="perPage == 100">100</option>
        </select>
        registros por página.
      </div>
    </div>
  </div>
  <div class="col-sm-8" *ngIf="count > 0">
    <div class="dataTables_paginate paging_simple_numbers float-right" id="table1_paginate">
      <ul class="pagination">
        <li [ngClass]="{ disabled: firstPage() }" (click)="goToFirstPage()" class="paginate_button page-item previous disabled" id="table1_previous">
          <button [disabled]="firstPage()" aria-controls="table1" data-dt-idx="0" tabindex="0" class="page-link">Primeira</button>
        </li>
        <li [ngClass]="{ disabled: firstPage() }" (click)="onPrev()" class="paginate_button page-item previous disabled" id="table1_previous">
          <button [disabled]="firstPage()" aria-controls="table1" data-dt-idx="0" tabindex="0" class="page-link">Anterior</button>
        </li>
        <li *ngFor="let pageNum of getPages()" (click)="onPage(pageNum)" class="paginate_button page-item" [ngClass]="{ active: pageNum === page }">
          <a aria-controls="table1" class="page-link">{{ pageNum }}</a>
        </li>
        <li [ngClass]="{ disabled: lastPage() }" (click)="onNext()" class="paginate_button page-item next" id="table1_next">
          <button [disabled]="lastPage()" aria-controls="table1" data-dt-idx="7" tabindex="0" class="page-link">Próxima</button>
        </li>
        <li [ngClass]="{ disabled: lastPage() }" (click)="goToLastPage()" class="paginate_button page-item next" id="table1_next">
          <button [disabled]="lastPage()" aria-controls="table1" data-dt-idx="7" tabindex="0" class="page-link">Última</button>
        </li>
        <!-- <span>{{ totalPages() }} pages</span> -->
      </ul>
    </div>
  </div>
</div>
