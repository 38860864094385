import { Component, Injectable, ChangeDetectorRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
@Injectable()
export class AppComponent implements OnInit {
  title = "app";
  loading = false;

  subscription: Subscription;

  constructor(private cdRef: ChangeDetectorRef, public router: Router) {}

  ngOnInit() {}

  public load(status: boolean) {
    this.loading = status;
    this.detectChanges();
  }

  public detectChanges() {
    try {
      this.cdRef.detectChanges();
    } catch (err) {}
  }

  public emptyRoute() {
    return this.router.url === "/";
  }
}
