<div id="financial-report" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title">Relatório Financeiro</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <h4>Selecione os campos do seu relatório</h4>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body pt-0">
          <div class="form-group row pt-1">
            <div class="col-12 d-flex mt-3">
              <label class="col-form-label text-sm-right label-first">Clientes</label>
              <div class="col-6 box-select">
                <select-input *ngIf="this.customers_for_select.length > 0" [object]="this.customer" [options]="this.customers_for_select" attribute="customer_ids" [multiple]="true"></select-input>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="mt-3">
              <label class="ml-3 custom-control custom-checkbox title-checkbox">
                <input class="custom-control-input" id="all-brands-checkbox-sfs" type="checkbox" name="allBrands" (change)="toggleAllBrands($event)" checked />
                <h4 class="mt-0 custom-control-label text-dark">Todas as marcas</h4>
              </label>
            </div>
            <div id="brands_select" class="col-12 mt-3 d-none">
              <label class="col-form-label text-sm-right label-first">Marcas</label>
              <div class="col-6 box-select">
                <select-input *ngIf="this.url" [object]="this.brand" [url]="url" attribute="brand_ids" [multiple]="true"></select-input>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Datas</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row">
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_ini" placeholder="Data Inicial" name="date_ini" class="form-control input-form-date" />
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_end" placeholder="Data Final" name="date_end" class="form-control input-form-date" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row pt-1">
            <div class="col-12">
              <label class="col-form-label text-sm-right label-first">Gerado via</label>
              <div class="col-12 col-sm-9 box-select">
                <select-input *ngIf="this.original_history_options" [options]="this.original_history_options" [object]="this.history" attribute="event_names" [multiple]="true"></select-input>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12">
              <label class="col-form-label text-sm-right label-first">Filtro</label>
              <div class="col-12 col-sm-9 box-select">
                <select-input *ngIf="this.filter_options" [options]="this.filter_options" [object]="this.history" attribute="filter"></select-input>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
        </div>
      </form>
    </div>
  </div>
</div>
