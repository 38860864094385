import {Customer} from './customer'

export class Group {
  public id:number;
  public customer:Customer;
  public name:string;

  public role_ids: Array<any> = [];
  public roles: Array<any>;
  public role_names: string;

  public created_at:string;
  public updated_at:string;
  public errors:any = {}

  constructor(params = {}){
    for (let key in params)
      this[key] = params[key]
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
