import { Component, OnInit } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { ApiService } from "../../../core/services/api.service";
import { CoreService } from "../../../core/services/core.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "../../../core/messages/notification.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-admin-customer-show-customer-post-report",
  templateUrl: "./customer-post-report.component.html",
  styleUrls: ["./customer-users.component.css"],
})
export class CustomerPostReportComponent implements OnInit {
  month;
  customer_id;
  customer_name;
  report = [];
  report_total;
  date = "";
  posts_url;
  back_url;
  namespace = "admin";

  constructor(
    private _location: Location,
    private notificationService: NotificationService,
    private router: Router,
    private acRoute: ActivatedRoute,
    private appComponent: AppComponent,
    private apiService: ApiService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      this.month = data.month;
      if (data.customer_id) {
        this.namespace = "admin";
        this.customer_id = data.customer_id;
        this.posts_url = `admin/customers/${this.customer_id}/posts_by_carrier?month=${this.month}`;
        this.back_url = `/admin/customers/${this.customer_id}`;
        this.apiService.get(`admin/customers/${this.customer_id}`).subscribe((data) => {
          this.customer_name = data["social_name"];
          this.getPostsByCarrier();
        });
      } else {
        this.namespace = "backoffice";
        this.posts_url = `backoffice/posts_by_carrier?month=${this.month}`;
        this.back_url = `/backoffice/manual_posts`;
        this.getPostsByCarrier();
      }
    });
  }

  getPostsByCarrier() {
    this.apiService.get(this.posts_url).subscribe((data) => {
      this.customer_name = data["social_name"];
      if (Object.keys(data).length < 2) {
        this.notificationService.notify("Não existem dados a serem exibidos.", "info");
        this.router.navigateByUrl(this.back_url);
        this.appComponent.load(false);
      } else {
        this.date = data["date"];
        this.report = data["posts_by_carrier"];
        window["App"].init();
        window["customerPostsDashboard"](this.report);
        this.report_total = Object.keys(this.report)
          .map((prop) => this.report[prop])
          .reduce((a, b) => a + b, 0);
        this.appComponent.load(false);
      }
    });
  }

  back() {
    this._location.back();
  }
}
