import { Deserializable } from "./deserializable.model";
import { CarrierTrackingStatus } from "./carrier_tracking_status";

export class History implements Deserializable {
  event_names = [];

  event_name: string;
  event_local: string;
  event_date: string;
  event_code: string;
  tracking_group_name: string;
  created_at: string;
  deliverable_type: string;
  api_situation: string;
  carrier_tracking_status = new CarrierTrackingStatus();
  carrier_tracking_status_ids: Array<any> = [];
  filter: string;

  public errors: any = {};

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.carrier_tracking_status) {
      this.carrier_tracking_status = new CarrierTrackingStatus().deserialize(input.carrier_tracking_status);
    }
    return this;
  }
}
