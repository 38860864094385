import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiService {
  constructor(public http: HttpClient) {}

  // read method
  public get(path, options = {}) {
    var endpoint = environment.apiUrl + path;
    return this.http.get(endpoint, options);
  }

  // create method
  public post(path: string, body: any) {
    var endpoint = environment.apiUrl + path;
    return this.http.post(endpoint, body);
  }

  // delete method
  public delete(path: string) {
    var endpoint = environment.apiUrl + path;
    return this.http.delete(endpoint);
  }

  // update method
  public update(path: string, body: any) {
    var endpoint = environment.apiUrl + path;
    return this.http.put(endpoint, body);
  }
}
