<div [ngClass]="{ 'has-danger': object.errors[attribute] }" class="form-group row pt-1">
  <label class="col-12 col-sm-3 col-form-label text-sm-right" [ngClass]="{ 'text-danger': object.errors[attribute] }" for="">{{ label }}</label>
  <br />
  <div class="col-12 col-sm-8 col-lg-6">
    <div class="app-select column-option-{{ attribute }}" [ngClass]="{ 'not-empty': this.showResetButton }">
      <ng-multiselect-dropdown
        [placeholder]="placeholder ? placeholder : 'Selecionar'"
        [settings]="dropdownSettings"
        [data]="dropdownList"
        [(ngModel)]="selectedItems"
        (onSelect)="_onSelect($event)"
        (onDeSelect)="_onDeSelect($event)"
        (onSelectAll)="_onSelectAll($event)"
        (onDeSelectAll)="_onDeSelectAll()"
      ></ng-multiselect-dropdown>
      <a class="clear-column-select" *ngIf="this.showResetButton" (click)="reset()"><i class="mdi mdi-close-circle"></i></a>
    </div>
    <p *ngIf="object.errors[attribute]" class="text-danger">{{ object.errors[attribute].join(", ") }}</p>
  </div>
</div>
