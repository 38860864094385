export class FranchiseUser {
  public id: number;
  public email: string;
  public password: string;
  public created_at: string;
  public updated_at: string;
  public status_update_permission: boolean;
  public franchise_id: number;
  public admin_user: boolean;

  public errors: any = {};
  public error: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
