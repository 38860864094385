<div id="file-import" class="file-import-container d-none">
  <div class="card-header card-header-divider">Selecione o seu arquivo financeiro para upload<span class="card-subtitle">Formatos aceitos: .csv e .xlsx</span></div>
  <div class="card-body">
    <form id="my-awesome-dropzone" class="dropzone dz-clickable">
      <div class="dz-message">
        <div class="icon"><span class="mdi mdi-cloud-upload"></span></div>
        <h2>Arraste e solte seu arquivo aqui</h2>
        <span class="note">{{ fileDescription }}</span>
        <div class="dropzone-mobile-trigger needsclick"></div>
        <div class="buttons-bar mt-3">
          <button type="submit" id="uploadFile" class="btn btn-space btn-primary">Selecionar arquivo</button>
          <button class="btn btn-space btn-secondary" (click)="cancelUpload()" *ngIf="this.register.file">Cancelar</button>
        </div>
        <input type="file" class="d-none" id="fileUpload" (change)="changeFile($event)" />
      </div>
    </form>
    <div class="form-group row text-right">
      <div class="col col-xs-12">
        <button type="submit" class="btn btn-space btn-primary" (click)="onSubmit()">Criar preços</button>
      </div>
    </div>
  </div>
</div>
