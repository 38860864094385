export class CarrierFlashCourierIntegration {
    public id:number;
    
    public customer_id:number;
    public user:string;
    public password:string;
    public api_url:string;

    public dna_hawb:number;
    public tipo_enc_id:number;
    public prod_flash_id:number;
    public carrier_flash_courier_product_id = null;
    public frq_rec_id:string;
    public tipo_merc_id:string;
    public id_local_rem:number;

    public flash_courier_customer_id: string;
    public flash_courier_ctt_id: string;
    public flash_courier_cost_center_id: string;
    public flash_courier_ctt_ter_id: string;
    public flash_courier_customer_number: string;
    active:boolean;

    public created_at:string;
    public updated_at:string;
    public errors:any = {}
  }
  