<section class="wrapper flex-md-row">
  <app-backoffice-main *ngIf="backofficeAudit"></app-backoffice-main>
  <div class="body-container">
    <!--Body Conteudo-->
    <div class="page-head">
      <h2 class="page-head-title">{{ brand_title }} Auditoria ({{ this.title }})</h2>
      <h2 class="page-head-button"><button (click)="back()" class="btn btn-space btn-secondary">Voltar</button></h2>
    </div>
    <nav class="container-fab" *ngIf="model == 'customer'">
      <a class="fab back" data-tippy title="Voltar" routerLink="/admin/customers"></a>
      <a class="fab calendar" data-tippy title="Auditar" routerLink="/admin/audit/customer/{{ model_id }}"></a>
      <!-- <a class="fab truck" data-tippy title="Habilitar transportadoras" routerLink="/admin/customers/{{model_id}}/carriers"></a> -->
      <a class="fab" data-tippy title="Editar" routerLink="/admin/customers/{{ model_id }}/edit"></a>
      <a class="fab" routerLink="/admin/customers/new" data-tippy title="Novo"></a>
    </nav>
    <nav class="container-fab" *ngIf="model != 'customer' && model != 'manual_post' && model != 'carrier_flash_courier_tracking_status' && model != 'carrier_correios_tracking_status'">
      <a class="fab back" data-tippy title="Voltar" (click)="back()"></a>
      <a class="fab" data-tippy title="Editar" routerLink="{{ baseUrl() }}/{{ model_id }}/edit"></a>
      <a class="fab" routerLink="{{ baseUrl() }}/new" data-tippy title="Novo"></a>
    </nav>
    <!--Fim Conteudo titulo-->
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-12">
          <p *ngIf="rows.length == 0">Sem informações.</p>
          <ul class="timeline" *ngIf="rows.length > 0">
            <li class="timeline-item" [ngClass]="row.action" *ngFor="let row of rows">
              <div class="timeline-date">
                <span>{{ row.date }}</span
                ><span class="d-block" [ngStyle]="{ color: '#ababab' }">{{ row.hour }}</span>
              </div>
              <div class="timeline-content">
                <div class="timeline-summary">{{ row.msg }}</div>
              </div>
            </li>
            <li *ngIf="this.page <= this.totalPages" class="timeline-item timeline-loadmore"><a (click)="getRegisters()" class="load-more-btn">Carregar mais</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
