import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Response } from "../../model/index.model";
import { Tracking } from "../../model/tracking.model";

@Component({
  selector: "focco-tracking-card-find-code",
  templateUrl: "./tracking-card-find-code.component.html",
  styleUrls: ["./tracking-card-find-code.component.css"],
})
export class TrackingCardFindCodeComponent implements OnInit {
  @Input() data: Response;

  @Output("submit-tracking-code") onSubmitTrackingCode = new EventEmitter<Tracking>();

  tracking_code: string;

  constructor() {}

  ngOnInit() {}

  onSubmit(_event): void {
    this.onSubmitTrackingCode.emit({ tracking_code: this.tracking_code });
  }
}
