<div id="brand-modal-desactive" tabindex="-1" class="modal fade colored-header-primary" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header modal-header-colored bg-danger text-dark d-block">
        <header class="d-flex">
          <h3 class="modal-title text-light" id="exampleModalLabel">
            Deseja inativar a marca <strong>{{ brand_name }}</strong
            >?
          </h3>
          <button type="button" class="close text-dark" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <section class="d-flex flex-column col-12 mt-4 mb-0">
        <p>Ao desativar a marca os seguintes pontos devem ser levantados:</p>
        <ul>
          <li>Não terá acesso ao sistema focco</li>
          <li>Não terá acesso a api do sistema</li>
          <li>Usuário vinculados a conta não terão acesso</li>
        </ul>
      </section>
      <div class="modal-footer d-flex justify-content-end">
        <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
        <button type="button" (click)="onSubmit()" class="btn btn-danger btn-lg md-close">Inativar Marca</button>
      </div>
    </div>
  </div>
</div>
