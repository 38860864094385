<div id="contract-form" tabindex="-1" class="modal fade colored-header-primary" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <header class="d-flex">
          <h3 class="modal-title text-white" id="exampleModalLabel">Cadastrar novo contrato</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate class="d-flex flex-column justify-content-between">
        <section class="modal-body pt-0 customer-card">
          <text-input [object]="this.register" attribute="identifier" label="Identificador do contrato *" name="identifier"></text-input>
        </section>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Enviar</button>
        </div>
      </form>
    </div>
  </div>
</div>
