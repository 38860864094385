import { CarrierProduct } from "./carrier_product";
import { CarrierTrackingStatus } from "./carrier_tracking_status";
import { Deserializable } from "./deserializable.model";

export class Carrier implements Deserializable {
  public id: number;

  public name: string;
  public slug: string;
  public user_custom_api_url: boolean;
  public posting_url: string;
  public tracking_url: string;
  public active: boolean;
  public current_customer_integration;
  public has_integration: boolean;
  public is_legacy_carrier: boolean;

  public carrier_products: Array<CarrierProduct>;
  public carrier_tracking_statuses: Array<CarrierTrackingStatus>;

  public created_at: string;
  public updated_at: string;
  public errors: any = {};

  public carrier_ids = [];

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.carrier_tracking_statuses) {
      this.carrier_tracking_statuses = input.carrier_tracking_statuses.map((status) => new CarrierTrackingStatus().deserialize(status));
    }
    return this;
  }
}
