import { Component, Input, OnInit } from "@angular/core";
import { color } from "../../models/styles/color.model";

@Component({
  selector: "focco-ui-card",
  templateUrl: "./card.component.html",
})
export class CardComponent implements OnInit {
  @Input("border-color") borderColor: color = "primary";
  @Input("background-color") backgroundColor: color = "light";

  constructor() {}

  ngOnInit() {}
}
