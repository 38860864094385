import { Route, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { LoginService } from "./login.service";

@Injectable()
export class LoginGuard  {
  constructor(private loginService: LoginService) {}

  checkAutentication(path: string, routeConfig: any): boolean {
    let loginType = routeConfig.path;
    let currentLoginType = "";

    if (loginType === "backoffice" && localStorage.getItem("backofficeUser")) {
      currentLoginType = JSON.parse(localStorage.getItem("backofficeUser")).loginType;
    }

    if (!this.loginService.isLogged()) {
      this.loginService.handleLogin(path);
      return false;
    } else if (currentLoginType !== loginType) {
      this.loginService.handleLogin(path);
      return false;
    }

    return true;
  }

  canLoad(route: Route): boolean {
    return this.checkAutentication(`/${route.path}`, route);
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    return this.checkAutentication(`/${activatedRoute.routeConfig.path}`, activatedRoute.routeConfig);
  }
}
