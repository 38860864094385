<div id="posted-volume-report-form" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <div class="d-flex">
          <h3 class="modal-title" id="exampleModalLabel">Relatório de Volume Postado</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <h4>Selecione os campos do seu relatório</h4>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate>
        <div class="modal-body pt-0">
          <div class="form-group row pt-1">
            <div class="col-12 d-flex">
              <label class="col-form-label text-sm-right label-first">Marca</label>
              <div class="col-6 col-sm-6 box-select">
                <select-input *ngIf="this.url" [object]="this.brand" [url]="url" attribute="brand_ids" (onSelect)="selectInputChange($event)"></select-input>
              </div>
              <div class="d-flex mt-3 ml-5">
                <input id="all-brands-checkbox mt-5 mr-2 mb-4" type="checkbox" name="allBrands" (change)="toggleAllBrands()" checked />
                <label class="col-form-label text-sm-right label-first">Todas as Marcas</label>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Datas</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row">
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_ini" placeholder="Data Inicial" name="date_ini" class="form-control input-form-date" />
                  <input type="date" width="170" [ngModelOptions]="{ standalone: true }" [(ngModel)]="this.date_end" placeholder="Data Final" name="date_end" class="form-control input-form-date" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row pt-1">
            <div class="col-12 col-sm-12">
              <label class="col-form-label text-sm-right label-first">Formato de<br />Visualizaçao</label>
              <div class="col-12 col-sm-10 box-select">
                <div class="row box-radios">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="checkbox_csv_list" (change)="check_list($event)" class="custom-control-input" value="1" /><span class="custom-control-label">Tela</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="checkbox_csv_list" (change)="check_csv($event)" checked="true" class="custom-control-input" value="0" /><span class="custom-control-label">E-mail</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="checkbox_csv_list" (change)="check_download($event)" class="custom-control-input" value="0" /><span class="custom-control-label">Download</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <label class="mb-0 custom-control custom-checkbox title-checkbox">
            <input type="checkbox" name="check_all" (change)="checkAll()" class="custom-control-input" id="check_all" />
            <h4 class="custom-control-label text-dark">Marcar Todos</h4>
          </label>
          <div *ngFor="let map_item of assign_map; let i = index">
            <div class="m-0 card-header card-header-divider">
              <label class="mb-0 custom-control custom-checkbox title-checkbox">
                <input type="checkbox" name="titles_{{ i }}" [ngModelOptions]="{ standalone: true }" [(ngModel)]="titles[i]" (change)="checkValuesTitle(i)" class="custom-control-input" />
                <h4 class="custom-control-label text-dark">{{ map_item["title"] }}</h4>
              </label>
            </div>
            <div class="p-0 card-body">
              <div class="row">
                <div *ngFor="let column_item of map_item['fields']" class="col-3 col-md-4 mt-2">
                  <label class="mb-0 custom-control custom-checkbox value-checkbox" *ngFor="let field_item of column_item">
                    <input type="checkbox" name="values_{{ field_item['attribute'] }}" [ngModelOptions]="{ standalone: true }" [(ngModel)]="values[field_item['attribute']]" class="custom-control-input" />
                    <h4 class="mt-1 custom-control-label">{{ field_item["label"].replace(" *", "") }}</h4>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Exportar</button>
        </div>
      </form>
    </div>
  </div>
</div>
