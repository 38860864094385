import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { IBrand } from "../../../admin/shared/models/brand/IBrand";
import { IPage } from "../../../admin/shared/models/page/Ipage";
import { Page } from "../../../admin/shared/models/page/page";

interface IResponseBrands {
  brand: Array<IBrand>;
  page: IPage;
}

interface IResponseStateBrands {
  message: string;
  brands: Array<IBrand>;
}

interface IresponseStateCustomer {
  message: string;
  customer?: any;
}

interface IResponseAudit {
  description: string;
}

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  getBrands({ customerId, page = new Page(), brandParams = [], order = "id asc" }) {
    return this.http.get<IResponseBrands>(`${environment.apiUrl}admin/customers/${customerId}/show_brands?page=${page.current_page}&per=${page.limit_value}&q[s]=${order}&${brandParams}`);
  }

  getAudit({ customerId, page = new Page() }) {
    return this.http.get(`${environment.apiUrl}admin/customers/${customerId}/show_brands?page=${page.current_page}&per=${page.limit_value}`);
  }

  putStateActiveBrands({ customerId, brandIds, stateActive }) {
    return this.http.put<IResponseStateBrands>(`${environment.apiUrl}admin/customers/${customerId}/define_state_brands`, {
      brand_ids: brandIds,
      state_active: stateActive,
    });
  }

  putStateActiveCustomer({ customerId, stateActive }) {
    return this.http.put<IresponseStateCustomer>(`${environment.apiUrl}admin/customers/${customerId}/define_state_active`, {
      state_active: stateActive,
    });
  }
}
