import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { CarrierCorreiosIntegration } from '../../../../models/carrier_correios_integration';
import { ApiService } from '../../../../core/services/api.service';
import { NotificationService } from '../../../../core/messages/notification.service';

@Component({
  selector: 'app-backoffice-correios-save',
  templateUrl: './correios-save.component.html'
})

export class CorreiosSaveComponent implements OnInit {

  carrier_correios: CarrierCorreiosIntegration = new CarrierCorreiosIntegration();
  edit_mode: boolean = false;
  url;
  namespace;
  customer_id: number;

  constructor(public acRoute: ActivatedRoute, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService) { }

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      if (data && data.customer_id){
        this.url = `admin/customers/${data.customer_id}/carrier_correios_integration`;
        this.namespace = 'admin';
        this.customer_id = data.customer_id;
      }else{
        this.url = 'backoffice/carrier_correios_integration';
        this.namespace = 'backoffice';
      }
      this.apiService.get(this.url).subscribe((data: any) => {
        if(data){
          data.errors = {};
          this.carrier_correios = data;
          this.edit_mode = true;
        }
        this.appComponent.load(false);
      });
    });
  }

  onSubmit () {
    this.appComponent.load(true);
    const data = {carrier_correios_integration: this.carrier_correios};
    if (this.edit_mode)
      this.apiService.update(this.url, data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
    else
      this.apiService.post(this.url, data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
  }

  success (r) {
    this.notificationService.notify('Correios configurado com sucesso.', 'success');
    if (this.namespace == 'admin' && this.customer_id)
      this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
    else
      this.router.navigateByUrl('backoffice/carriers');
    this.appComponent.load(false);
  }

  catch (errors) {
    let msg = '';
    if(errors.error.base)
      msg = errors.error.base.join(', ');
    else
      msg = 'Erro ao configurar os correios.';
    this.carrier_correios.errors = errors.error;
    this.notificationService.notify(msg, 'danger');
    this.appComponent.load(false);
  }

  disable () { 
    this.appComponent.load(true);
    this.apiService.delete(this.url).subscribe((r) => {
      this.notificationService.notify('Integração com os correios desabilitada com sucesso.', 'success');
      if (this.namespace == 'admin' && this.customer_id)
        this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
      else
        this.router.navigateByUrl('backoffice/carriers');
      this.appComponent.load(false);
    }, errors => {
      this.notificationService.notify('Erro ao desabilitar os correios.', 'danger');
      this.appComponent.load(false);
    });
  }

  getBackUrl(){
    if (this.namespace == 'admin' && this.customer_id)
      return `/admin/customers/${this.customer_id}/carriers`;
    else
      return '/backoffice/carriers';
  }
}
