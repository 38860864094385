import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trackingIsPresent",
})
export class TrackingIsPresentPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value === "-" ? false : true;
  }
}
