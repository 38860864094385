<div class="be-wrapper be-login">
  <div class="be-content login-container d-flex">
    <div class="main-content container-fluid d-flex login-box">
      <section>
        <div class="splash-container forgot-password">
          <div class="card form-box">
            <div class="card-header"><img src="assets/img/logo_focco_large_dark@2x.png" alt="logo" width="139" class="logo-img" /><span class="splash-description">Esqueceu sua senha?</span></div>
            <div class="card-body">
              <form (ngSubmit)="onSubmit()" novalidate>
                <p>Não se preocupe, vamos enviar um link para o seu e-mail com instruções.</p>
                <div class="form-group pt-4">
                  <input type="email" name="userEmail" required="" [(ngModel)]="this.user.email" placeholder="Seu E-mail" autocomplete="off" class="form-control" #emailInput />
                </div>
                <div class="form-group pt-1 text-center">
                  <button type="submit" class="btn btn-block btn-primary btn-xl mb-2">Resetar Senha</button>
                  <a (click)="this.toLogin()">Voltar</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="vector-container">
        <div class="vector-box">
          <img src="assets/img/forgot_password_vector.png" alt="logo" class="vector" />
        </div>
      </section>
    </div>
  </div>
</div>
