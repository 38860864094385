import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.css']
})

export class CheckboxInputComponent implements OnInit{
  @Input() object: any;
  @Input() attribute: string;
  @Input() errors: any;
  @Input() label: string;
  @Input() value_label: string;
  @Input() name: string;
  console = console;

  ngOnInit(){
  }
}
