<div *ngIf="type != 'subdomain'" class="form-group {{ type }}" [ngClass]="{ 'has-error': object.errors[attribute] }">
  <label class="col-12 px-0 col-form-label text-sm-left" [ngClass]="{ 'text-danger': object.errors[attribute] }" for="">{{ label }}</label>
  <div class="col-12 px-0" [ngClass]="{ 'input-group': type == 'ftp' }">
    <span *ngIf="type == 'ftp'" class="input-group-prepend"><span class="input-group-text">ftp://</span></span>
    <input
      #input1
      id="{{ type == 'color' ? 'colorpicker' : name }}"
      [type]="this.getType()"
      class="form-control {{ this.getClasses() }}"
      [ngClass]="{ 'form-control-error is-invalid': object.errors[attribute] }"
      [placeholder]="placeholder"
      [(ngModel)]="object[attribute]"
      name="{{ name }}"
      [maxlength]="maxlength"
    />
    <a *ngIf="type == 'color'" (click)="copyTextToClipboard()" id="copy-form-text">Copiar</a>
    <span *ngIf="object.errors[attribute]" class="mdi mdi-close"></span>
    <p *ngIf="object.errors[attribute]" class="text-danger error-msg">{{ object.errors[attribute].join(", ") }}</p>
    <p *ngIf="hint" class="text-grey">{{ hint }}</p>
    <!-- <p hidden>{{box.value}}</p> -->
    <p *ngIf="counter">{{ maxlength - input1.value.length }} caracteres restantes</p>
  </div>
</div>
<div *ngIf="type == 'subdomain'" class="form-group" [ngClass]="{ 'has-error': object.errors[attribute] }">
  <label class="col-12 col-form-label text-sm-left" [ngClass]="{ 'text-danger': object.errors[attribute] }" for="">{{ label }}</label>
  <div class="col-12 col-sm-8 col-lg-6 input-group mb-3">
    <span class="input-group-prepend"
      ><span class="input-group-text">{{ protocol }}</span></span
    >
    <input #input1 name="{{ name }}" [(ngModel)]="object[attribute]" [placeholder]="placeholder" [ngClass]="{ 'is-invalid': object.errors[attribute] }" type="text" placeholder="yourdomain" class="form-control" />
    <span class="input-group-prepend"
      ><span class="input-group-text">{{ domain }}</span></span
    >
  </div>
  <div *ngIf="object.errors[attribute]" class="offset-sm-3 col-12 col-sm-8 col-lg-6 input-group mb-3">
    <p class="text-danger">{{ object.errors[attribute].join(", ") }}</p>
  </div>
  {{ enable_form_group ? "Olá pessoas" : "Não foi" }}
</div>
