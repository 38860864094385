import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { CarrierIntegration } from '../../../../models/carrier_integration';
import { Carrier } from '../../../../models/carrier';
import { ApiService } from '../../../services/api.service';
import { NotificationService } from '../../../messages/notification.service';
import { CarrierService } from '../../../services/carrier.service';

@Component({
  selector: 'app-backoffice-integration-save',
  templateUrl: './integration-save.component.html'
})

export class IntegrationSaveComponent implements OnInit {

  carrier_integration = new CarrierIntegration();
  carrier = new Carrier();
  edit_mode: boolean = false;
  url;
  namespace;
  customer_id: number;
  carrier_id: number;

  constructor(public carrierService: CarrierService, public acRoute: ActivatedRoute, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public notificationService: NotificationService) { }

  ngOnInit() {
    this.appComponent.load(true);
    this.acRoute.params.subscribe((data: any) => {
      if (data && data.customer_id){
        this.url = `admin/customers/${data.customer_id}/carrier_integration`;
        this.namespace = 'admin';
        this.customer_id = data.customer_id;
      }else{
        this.url = `backoffice/carriers/${data.id}`;
        this.namespace = 'backoffice';
      }

      this.carrier_id = data.id
      this.mountCarrier();
    });
  }

  mountCarrier(){
    const scope = this;
    this.carrierService.getCarrier(scope);
  }

  onSubmit () {
    this.appComponent.load(true);
    const data = {carrier_integration: this.carrier_integration, carrier: this.carrier};
    if (this.carrier_integration.id)
      this.apiService.update('backoffice/carrier_integration', data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
    else
      this.apiService.post('backoffice/carrier_integration', data).subscribe((r) => {
        this.success(r);
      }, errors => { this.catch(errors); });
  }

  success (r) {
    this.notificationService.notify(`${this.carrier.name} configurada com sucesso.`, 'success');
    if (this.namespace == 'admin' && this.customer_id)
      this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
    else
      this.router.navigateByUrl('backoffice/carriers');
    this.appComponent.load(false);
  }

  catch (errors) {
    let msg = '';
    if(errors.error.base)
      msg = errors.error.base.join(', ');
    else
      msg = `Erro ao configurar a ${this.carrier.name}.`;
    this.carrier_integration.errors = errors.error;
    this.notificationService.notify(msg, 'danger');
    this.appComponent.load(false);
  }

  disable () {
    this.appComponent.load(true);
    this.apiService.delete(this.url).subscribe((r) => {
      this.notificationService.notify(`Integração com a ${this.carrier.name} desabilitada com sucesso.`, 'success');
      if (this.namespace == 'admin' && this.customer_id)
        this.router.navigateByUrl(`admin/customers/${this.customer_id}/carriers`);
      else
        this.router.navigateByUrl('backoffice/carriers');
      this.appComponent.load(false);
    }, errors => {
      this.notificationService.notify(`Erro ao desabilitar a ${this.carrier.name}.`, 'danger');
      this.appComponent.load(false);
    });
  }

  getBackUrl(){
    if (this.namespace == 'admin' && this.customer_id)
      return `/admin/customers/${this.customer_id}/carriers`;
    else
      return '/backoffice/carriers';
  }
}
