<div
  class="be-wrapper be-login backoffice tracking-background"
  [ngStyle]="{
    'background-image': config?.background_image?.url
      ? 'url(' + config.background_image.url + ')'
      : 'url(' + DEFAULT_BACKGROUND + ')'
  }"
>
  <!-- Nav bar da página -->
  <div class="be-wrapper be-nosidebar-left">
    <nav class="navbar navbar-expand fixed-top be-top-header">
      <div class="container-fluid px-2">
        <div class="be-navbar-header">
          <a
            class="navbar-brand"
            [class.logo-hidden]="!config?.is_active"
            [class.tracking-logo]="config?.logo_image?.url"
            [ngStyle]="{
              'background-image': config?.logo_image?.url
                ? 'url(' + config.logo_image.url + ')'
                : 'url(' + DAFAULT_LOGO + ')'
            }"
            (click)="onPublicPath($event)"
          ></a>
        </div>
        <!-- config?.splash_container -->
        <div *ngIf="config?.splash_container" class="col-lg-4">
          <form *ngIf="config.is_active" class="email-search" (ngSubmit)="onSubmit()">
            <div class="input-group input-search input-group-sm">
              <input
                name="tracking_code"
                [ngClass]="{ 'is-invalid': error }"
                [(ngModel)]="tracking_code"
                type="text"
                placeholder="Preencha um novo código para rastrear..."
                class="form-control tracking-input"
              />
              <span class="input-group-btn">
                <button type="submit" class="btn btn-secondary">
                  <i class="icon mdi mdi-search"></i>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </nav>
  </div>
  <!-- Conteudo da pagina -->
  <div class="be-content">
    <div class="main-content container-fluid">
      <div class="my-8" [class.splash-container]="!config?.splash_container" [class.row]="config?.splash_container">
        <div [class.col-12]="config?.splash_container" [class.col-lg-12]="config?.splash_container">
          <br /><br />
          <ng-content select="[content]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
