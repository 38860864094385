import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ApiService } from "../../../core/services/api.service";
import { NotificationService } from "../../../core/messages/notification.service";
import { AppComponent } from "../../../app.component";
import { Router } from "@angular/router";
import { CoreService } from "../../../core/services/core.service";
import { Contract } from "../../../models/contract";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
})
export class ContractComponent implements OnInit {
  public register: Contract = new Contract();
  public url = "admin/contracts";
  public customers: Array<any> = [];

  constructor(public notificationService: NotificationService, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public coreService: CoreService) {}

  ngOnInit() {
    this.appComponent.load(true);
  }

  validateAttributes() {
    let result = true;
    if (!this.register.identifier) {
      this.register.errors.identifier = ["Identificador não pode ficar em branco."];
      result = false;
    }

    return result;
  }

  public onSubmit() {
    if (this.validateAttributes()) {
      this.create();
      return;
    }
  }

  public create() {
    this.appComponent.load(true);
    this.apiService.post(this.url, this.register).subscribe(
      (resp) => {
        this.appComponent.load(false);
        this.notificationService.notify(`Contrato ${this.register.identifier} criado com sucesso.`, "success");
        window["$"]("#contract-form").modal("hide");
        this.register.identifier = "";
      },
      (errors) => {
        this.register.errors = errors.error;
        this.notificationService.notify("Erro ao criar contrato", "danger");
        this.appComponent.load(false);
        return;
      }
    );
  }
}
