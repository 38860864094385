import { Component, Output, Input, OnInit, EventEmitter, AfterViewInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { AppComponent } from "../../../app.component";

@Component({
  selector: "select-input",
  templateUrl: "./select-input.component.html",
  styleUrls: ["./select-input.component.css"],
})
export class SelectInputComponent implements OnInit {
  @Input() object: any;
  @Input() attribute: string;
  @Input() label: string;
  @Input() settings;
  @Input() url;
  @Input() options: Array<any>;
  @Input() multiple = false;
  @Input() selectAll = false;
  @Input() reloadOnChange = false;
  @Input() hexChange = null;
  @Input() placeholder: string;

  originalOptions: Array<any>;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  selectMultipleSettings = {
    singleSelection: false,
    enableCheckAll: true,
    idField: "id",
    textField: "text",
    selectAllText: "Selecionar tudo",
    unSelectAllText: "Desmarcar tudo",
    itemsShowLimit: 5,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhum dado encontrado",
  };

  selectSimpleSettings = {
    singleSelection: true,
    enableCheckAll: false,
    idField: "id",
    textField: "text",
    itemsShowLimit: 5,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "Nenhum dado encontrado",
  };

  @Output() onChange = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() deSelect = new EventEmitter<any>();

  constructor(public apiService: ApiService, public appComponent: AppComponent) {}

  async ngOnInit() {
    if (window.location.hash == "#/admin/customers") {
      const input = document.getElementById("select-input-report-div");
      input.classList.remove("col-lg-6");
      input.classList.add("col-lg-9");
    }

    if (this.multiple) {
      this.dropdownSettings = this.selectMultipleSettings;
    } else {
      this.dropdownSettings = this.selectSimpleSettings;
    }

    if (this.options) {
      this.dropdownList = this.options;
    } else {
      const result = await new Promise((resolve) => {
        this.appComponent.load(true);
        this.apiService.get(this.url).subscribe((resp: any) => {
          if (resp.data) {
            this.dropdownList = resp.data.length > 0 && resp.data.map((elem) => ({ id: elem.id, text: elem.name }));
          } else if (resp.list) {
            this.dropdownList = resp.list.map((elem) => ({ id: elem[0], text: `${elem[1]} - ${elem[2]}` }));
          } else {
            this.dropdownList = resp.map((elem) => ({ id: elem[0], text: elem[1] }));
          }
          this.appComponent.load(false);

          resolve(this.dropdownList);
        });
      });
    }

    if (this.object.id && this.selectedItems.length === 0) {
      const values = this.object[`${this.attribute}`];
      values && values.length ? (this.selectedItems = this.dropdownList.filter((item) => values.includes(item.id))) : (this.selectedItems = this.dropdownList.filter((item) => values === item.id));
    }
  }

  ngOnChanges() {
    if (this.reloadOnChange) {
      this.ngOnInit();
    }
  }

  _onSelect(value) {
    if (this.attribute) {
      this.selectedItems.push(value);
      this.object[`${this.attribute}`] = this.multiple ? this.selectedItems.map((item) => item.id) : value.id;
      return;
    }

    this.onSelect.emit(value);
  }

  _onDeSelect(value) {
    this.selectedItems.forEach((carrier, index) => {
      carrier.id === value.id && this.selectedItems.splice(index, 1);
    });
    this.object[`${this.attribute}`] = this.selectedItems;
  }

  _onSelectAll(value) {
    this.selectedItems = value;
    this.object[`${this.attribute}`] = this.selectedItems.map((item) => item.id);
  }

  _onDeSelectAll() {
    this.selectedItems = [];
    this.object[`${this.attribute}`] = this.selectedItems;
  }
}
