<section class="wrapper flex-md-row">
  <app-backoffice-main></app-backoffice-main>
  <div class="body-container">
    <div class="page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item"><a [routerLink]="getBackUrl()">Transportadoras</a></li>
          <li class="breadcrumb-item active">{{ edit_mode ? "Editar integração com a " : "Habilitar integração com a " }} Nobre</li>
        </ol>
      </nav>
      <h2 class="page-head-title">{{ edit_mode ? "Editar integração com a " : "Habilitar integração com a " }} Nobre</h2>
      <h2 class="page-head-button"><button (click)="onSubmit()" class="btn btn-space btn-primary">Habilitar</button></h2>
      <h2 class="page-head-button"><button [routerLink]="getBackUrl()" class="btn btn-space btn-secondary">Cancelar</button></h2>
      <h2 class="page-head-button">
        <button
          *ngIf="edit_mode && carrier_nobre.active"
          class="btn btn-space btn-danger"
          [swal]="{
            title: 'Tem certeza que deseja desabilitar a integração?',
            type: 'warning',
            text: 'Suas postagens em andamento serão afetadas por esta operação.',
            confirmButtonText: 'Sim, desabilitar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
          }"
          (confirm)="disable()"
        >
          Desabilitar
        </button>
      </h2>
    </div>
    <!--Fim Conteudo titulo-->
    <!--Body Charts-->
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-border-color card-border-color-primary">
            <form (ngSubmit)="onSubmit()" novalidate="">
              <div class="card-header card-header-divider">Preencha os dados abaixo<span class="card-subtitle">Campos * são obrigatórios para integração com a Nobre</span></div>
              <div class="card-body">
                <text-input [object]="carrier_nobre" attribute="user" label="Usuário *" placeholder="Usuário" name="carrierUser"></text-input>
                <text-input [object]="carrier_nobre" attribute="password" label="Senha *" placeholder="Senha" name="carrierPassword"></text-input>
                <text-input [object]="carrier_nobre" attribute="api_url" hint="Exemplo: http://179.111.202.236/moto/ws" label="Endereço da API *" placeholder="Endereço da API" name="carrierapi_url"></text-input>
                <text-input [object]="carrier_nobre" attribute="delay_days" type="number" label="Dias de delay para o último status *" name="carrierDelayDays"></text-input>
              </div>

              <div class="card-header card-header-divider">Informações do cliente e origem das entregas<span class="card-subtitle">Campos * são obrigatórios</span></div>
              <div class="card-body">
                <text-input [object]="carrier_nobre" attribute="client_id" label="ID do cliente *" placeholder="ID do cliente" name="carrier_client_id"></text-input>
                <text-input [object]="carrier_nobre" attribute="address_zipcode" type="cep" label="CEP *" placeholder="CEP" name="carrier_address_zipcode"></text-input>
                <text-input [object]="carrier_nobre" attribute="address" label="Endereço *" placeholder="Endereço" name="carrier_address"></text-input>
                <text-input [object]="carrier_nobre" attribute="address_number" label="Número *" placeholder="Número" name="carrier_address_number"></text-input>
                <text-input [object]="carrier_nobre" attribute="address_complement" label="Complemento" placeholder="Complemento" name="carrier_address_complement"></text-input>
                <text-input [object]="carrier_nobre" attribute="address_neighborhood" label="Bairro" placeholder="Bairro" name="carrier_address_neighborhood"></text-input>
                <text-input [object]="carrier_nobre" attribute="address_city" label="Cidade *" placeholder="Cidade" name="carrier_address_city"></text-input>
                <div class="form-group row pt-1" [ngClass]="{ 'has-error': carrier_nobre.errors.address_state }">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right" [ngClass]="{ 'text-danger': carrier_nobre.errors.address_state }">Estado *</label>
                  <div class="col-12 col-sm-8 col-lg-6">
                    <select [ngClass]="{ 'form-control-error is-invalid': carrier_nobre.errors.address_state }" class="form-control address_state" (change)="changeState($event)">
                      <option [selected]="carrier_nobre.address_state == undefined" value="">Selecione o estado</option>
                      <option [selected]="carrier_nobre.address_state == 'AC'" value="AC">AC</option>
                      <option [selected]="carrier_nobre.address_state == 'AL'" value="AL">AL</option>
                      <option [selected]="carrier_nobre.address_state == 'AP'" value="AP">AP</option>
                      <option [selected]="carrier_nobre.address_state == 'AM'" value="AM">AM</option>
                      <option [selected]="carrier_nobre.address_state == 'BA'" value="BA">BA</option>
                      <option [selected]="carrier_nobre.address_state == 'CE'" value="CE">CE</option>
                      <option [selected]="carrier_nobre.address_state == 'DF'" value="DF">DF</option>
                      <option [selected]="carrier_nobre.address_state == 'ES'" value="ES">ES</option>
                      <option [selected]="carrier_nobre.address_state == 'GO'" value="GO">GO</option>
                      <option [selected]="carrier_nobre.address_state == 'MA'" value="MA">MA</option>
                      <option [selected]="carrier_nobre.address_state == 'MT'" value="MT">MT</option>
                      <option [selected]="carrier_nobre.address_state == 'MS'" value="MS">MS</option>
                      <option [selected]="carrier_nobre.address_state == 'MG'" value="MG">MG</option>
                      <option [selected]="carrier_nobre.address_state == 'PA'" value="PA">PA</option>
                      <option [selected]="carrier_nobre.address_state == 'PB'" value="PB">PB</option>
                      <option [selected]="carrier_nobre.address_state == 'PR'" value="PR">PR</option>
                      <option [selected]="carrier_nobre.address_state == 'PE'" value="PE">PE</option>
                      <option [selected]="carrier_nobre.address_state == 'PI'" value="PI">PI</option>
                      <option [selected]="carrier_nobre.address_state == 'RJ'" value="RJ">RJ</option>
                      <option [selected]="carrier_nobre.address_state == 'RN'" value="RN">RN</option>
                      <option [selected]="carrier_nobre.address_state == 'RS'" value="RS">RS</option>
                      <option [selected]="carrier_nobre.address_state == 'RO'" value="RO">RO</option>
                      <option [selected]="carrier_nobre.address_state == 'RR'" value="RR">RR</option>
                      <option [selected]="carrier_nobre.address_state == 'SC'" value="SC">SC</option>
                      <option [selected]="carrier_nobre.address_state == 'SP'" value="SP">SP</option>
                      <option [selected]="carrier_nobre.address_state == 'SE'" value="SE">SE</option>
                      <option [selected]="carrier_nobre.address_state == 'TO'" value="TO">TO</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row text-right">
                  <div class="col col-sm-10 col-lg-9 offset-sm-1 offset-lg-0">
                    <button
                      *ngIf="edit_mode && carrier_nobre.active"
                      class="btn btn-space btn-danger"
                      [swal]="{
                        title: 'Tem certeza que deseja desabilitar a integração?',
                        type: 'warning',
                        text: 'Suas postagens em andamento serão afetadas por esta operação.',
                        confirmButtonText: 'Sim, desabilitar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: true
                      }"
                      (confirm)="disable()"
                    >
                      Desabilitar
                    </button>
                    <button [routerLink]="getBackUrl()" class="btn btn-space btn-secondary">Cancelar</button>
                    <button type="submit" class="btn btn-space btn-primary">Habilitar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
