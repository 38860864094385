<!-- Se a requisição foi refita com sucesso -->
<focco-ui-template-tracking
  *ngIf="response$ | async as response; else loading"
  [config]="response.config"
  (submit-tracking-code)="onSubmit($event)"
>
  <!-- Se a resposta não retornar o campo tracking, mostre a tela de tracking -->
  <main content *ngIf="!response?.tracking; else showTrackingCode">
    <focco-tracking-card-find-code
      [data]="response"
      (submit-tracking-code)="onSubmit($event)"
    ></focco-tracking-card-find-code>
  </main>
  <!-- caso ao contrário mostre a tela de tracking -->
  <ng-template #showTrackingCode>
    <focco-tracking-status-code [data]="response.tracking"></focco-tracking-status-code>
  </ng-template>
</focco-ui-template-tracking>
<!-- Quando estiver carregando dados da página -->
<ng-template #loading>
  <focco-ui-template-tracking [config]="{ is_active: false }">
    <main content>
      <focco-tracking-card-loading></focco-tracking-card-loading>
    </main>
  </focco-ui-template-tracking>
</ng-template>
